import { get, post } from "../instance/axios.instance";
import { ENDPOINTS } from "../../constants";
import { pathWithQueryString } from "../../utils/pathWithQueryString";

export const getFile = (param, extraHeaders) => {
  return new Promise((resolve, reject) => {
    const url = pathWithQueryString(ENDPOINTS.FILE, param);
    get(url, extraHeaders)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFileDocTypes = (param) => {
  return new Promise((resolve, reject) => {
    const url = pathWithQueryString(ENDPOINTS.FILE_DOC_TYPES, param);
    get(url)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFileFromTo = (param) => {
  return new Promise((resolve, reject) => {
    const url = pathWithQueryString(ENDPOINTS.FILE_FROM_TO, param);
    get(url)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postSaveAsNewDocument = async (param, extraHeaders) => {
  return new Promise((resolve, reject) => {
    post(ENDPOINTS.FILE_SAVE_AS_NEW_FILE, param, extraHeaders)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postSaveAndSync = async (param, extraHeaders) => {
  return new Promise((resolve, reject) => {
    post(ENDPOINTS.FILE_SAVE_SYNC_FILE, param, extraHeaders)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postSaveAsNewVersion = async (param, extraHeaders) => {
  return new Promise((resolve, reject) => {
    post(ENDPOINTS.FILE_SAVE_AS_NEW_VERSION, param, extraHeaders)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFileProperties = (param) => {
  return new Promise((resolve, reject) => {
    const url = pathWithQueryString(ENDPOINTS.FILE_PROPERTIES, param);
    get(url)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFileShare = (param) => {
  return new Promise((resolve, reject) => {
    const url = pathWithQueryString(ENDPOINTS.FILE_SHARE, param);
    get(url)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFileVersions = (param) => {
  return new Promise((resolve, reject) => {
    const url = pathWithQueryString(ENDPOINTS.FILE_VERSIONS, param);
    get(url)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFileActivities = (param) => {
  return new Promise((resolve, reject) => {
    const url = pathWithQueryString(ENDPOINTS.FILE_ACTIVITIES, param);
    get(url)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFileActivitiesForChart = (param) => {
  return new Promise((resolve, reject) => {
    const url = pathWithQueryString(ENDPOINTS.FILE_CHART, param);
    get(url)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postSaveAsNewDocumentPdf = async (param) => {
  return new Promise((resolve, reject) => {
    post(ENDPOINTS.FILE_SAVE_AS_NEW_FILE_PDF, param)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postSaveAsNewVersionPdf = async (param) => {
  return new Promise((resolve, reject) => {
    post(ENDPOINTS.FILE_SAVE_AS_NEW_VERSION_PDF, param)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postSaveProperties = async (param) => {
  return new Promise((resolve, reject) => {
    post(ENDPOINTS.FILE_SAVE_PROPERTIES, param)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFiles = (param) => {
  return new Promise((resolve, reject) => {
    const url = pathWithQueryString(ENDPOINTS.FILES, param);
    get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
