import React, { useEffect, useState, useContext } from "react";
import { DefaultButton, IconButton, Label, PrimaryButton } from "office-ui-fabric-react";
import { useFormik } from "formik";
import { global } from "../style/global.style";
import { getFileProperties } from "../requests/api-requests/files.request";
import { sendMessageToTaskpane } from "../utils/sendMessageToTaskpane";
import NmInput from "../components/NmInput";
import { classNames } from "../style/coAuthor.style";
import NmTextarea from "../components/NmTextarea";
import { TaskpaneHeader } from "../components/TaskpaneHeader";
import { LoadingContext } from "../context/LoadingContext";
import { saveDocument } from "../utils/saveDocument";
import { controlPagePermissions } from "../utils/controls";

export default function CoAuthor() {
  const [mailInput, setMailInput] = useState("");
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [sendingComplate, setSendingComplate] = useState(false);

  useEffect(async () => {
    await controlPagePermissions();
  }, []);


  const wordContent = localStorage.getItem("selectedDocumentFile");
  const params = JSON.parse(wordContent);
  let doi = params ? params.doi : null;

  const coAuthorForm = useFormik({
    initialValues: {
      emails: [],
      message: "",
      doc_name: "",
    },
    onSubmit: async (data) => {
      showLoading();      
      await saveDocument(data, "coAuthor", null, null);
      setSendingComplate(true);
      hideLoading();
    },
  });

  useEffect(() => {
    if (!doi) return;
    getFileProperties({ doi }).then((data) => {
      coAuthorForm.setFieldValue("doc_name", data.docName);
    });
  }, []);

  const handleAddMailToForm = (e) => {
    if (e.key !== "Enter") return;
    const { emails } = coAuthorForm.values;
    coAuthorForm.setValues({
      ...coAuthorForm.values,
      emails: [...emails, { email: e.target.value }],
    });
    setMailInput("");
  };

  const onClickDeleteMail = (email) => {
    let filteredData = coAuthorForm.values.emails.filter((item) => item.email !== email);
    coAuthorForm.setFieldValue("emails", filteredData);
  };

  const handleCloseDialog = () => {
    sendMessageToTaskpane({ messageType: "dialogClose" });
  };

  return (
    <div>
      <TaskpaneHeader title={"Co-Author Mode"} showBackButton={false} headerColor={"#0364b8"} />
      {sendingComplate ? (
        <div className="text-center mt-5 px-3">
          <p>
            "Co-Author Mode" request sent successfully.
            <br /> Sometimes it may take time for the e-mail to reach the users.
          </p>
          <DefaultButton
            text="Back to the Co-Author Mode"
            className={global.primaryButton}
            onClick={() => setSendingComplate(false)}
          />
        </div>
      ) : (
        <div className={`px-4 relative ${global.container}`}>
          <div className="row">
            <div className="col">
              <div className={`d-flex flex-column justify-content-between pt-4 ${global.pb75}`}>
                <div className="main d-flex flex-column">
                  <Label>Detail Info</Label>
                  <div className={classNames.emailContainer}>
                    {coAuthorForm.values?.emails.map((item) => {
                      return (
                        <div className={classNames.emailItem}>
                          <div>{item.email}</div>
                          <IconButton
                            iconProps={{ iconName: "Cancel" }}
                            className={classNames.iconWrapper}
                            onClick={() => onClickDeleteMail(item.email)}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <NmInput
                      classNames="mt-3"
                      iconProps={{ iconName: "People" }}
                      onChange={(e) => setMailInput(e.target.value)}
                      value={mailInput}
                      onKeyPress={handleAddMailToForm}
                      placeholder="e-mail"
                    />
                  </div>
                  <div className="mt-3">
                    <NmTextarea
                      classNames="mt-3"
                      name="message"
                      onChange={coAuthorForm.handleChange}
                      value={coAuthorForm.values.message}
                      onKeyPress={handleAddMailToForm}
                      placeholder="Message"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  Please enter your e-mail address and <strong>press</strong> enter.
                </div>
                <div className={`d-flex align-items-center justify-content-end gap-3 px-3 ${global.footerWithFixed}`}>
                  <PrimaryButton className={global.primaryButton} onClick={coAuthorForm.handleSubmit} text="OK" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
