import App from "./components/App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from '@uifabric/icons';
import * as React from "react";
import * as ReactDOM from "react-dom";
/* global document, Office, module, require */
import "../mecellem/style/bootstrap-grid.css";
import "../../src/mecellem/style/alerify.css";

initializeIcons();

let isOfficeInitialized = false;

const title = "Mecellem Add-in";

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <Component title={title} isOfficeInitialized={isOfficeInitialized} />
    </AppContainer>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render(App, { hasUpdatedApi: true });
});

/* Initial render showing a progress bar */
render(App, { hasUpdatedApi: true });

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
