import { mergeStyleSets, getTheme } from "office-ui-fabric-react";
const theme = getTheme();
const { palette, semanticColors, fonts } = theme;

export const classNames = mergeStyleSets({
  itemCell: [
    {
      borderRadius: 5,
      padding: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
      backgroundColor: "white",
      border: "1px solid transparent",
      cursor: "pointer",
      selectors: {
        "&:hover": { background: palette.neutralLighter },
      },
    },
  ],
  itemImage: {
    display: "flex",
    alignItems: "center",
    flexShrink: 0,
    fontSize: 20,
  },
  itemContent: {
    marginLeft: 10,
    overflow: "hidden",
    flexGrow: 1,
  },
  itemName: {
    fontSize: 13,
    fontWeight: 600,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  description: {
    fontSize: 11,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  privateWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  chevron: {
    alignSelf: "center",
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
  selected: {
    border: "solid 1px #2b579a",
  },
  emptyContainer: {
    width: "100%",
    height: "146px",
    backgroundColor: "white",
    border: "none",
    paddingTop:"25%"
  },
  emptyIcon: { fontSize: 54 },
  emptyTitle: { color: "#45505A", fontSize: 14, fontWeight: "bold" },
  emptySubTitle: { color: "#45505A", fontSize: 12 },
  listWrapper: {
    maxHeight: "calc(100vh - 325px)",
    overflow: "auto",
    height: "100%",
  },
  infiniteScroll: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: "10px 16px",
  },
});
