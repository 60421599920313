export const getAllSlices = async (file) => {
  let isError = false;
  return new Promise(async (resolve, reject) => {
    var documentFileData = [];
    for (var sliceIndex = 0; sliceIndex < file.sliceCount && !isError; sliceIndex++) {
      var sliceReadPromise = new Promise((sliceResolve, sliceReject) => {
        file.getSliceAsync(sliceIndex, (asyncResult) => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            documentFileData = documentFileData.concat(asyncResult.value);
            sliceResolve({ IsSuccess: true, Data: documentFileData });
          } else {
            file.closeAsync();
            sliceReject({
              IsSuccess: false,
              ErrorMessage: `Error in reading the slice: ${sliceIndex} of the document. Please Reload the add-in and try again.`,
            });
          }
        });
      });
      await sliceReadPromise.catch((error) => {
        isError = true;
      });
    }
    if (isError || !documentFileData.length) {
      resolve({ IsSuccess: false, ErrorMessage: "Error while reading document. Please try it again." });
      reject("Error while reading document. Please try it again.");
      return;
    }
    file.closeAsync();
    resolve({ IsSuccess: true, Data: documentFileData });
  });
};
