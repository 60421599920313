import React, { useEffect, useState, useContext } from "react";
import { PrimaryButton } from "office-ui-fabric-react";
import { useFormik } from "formik";
import { global } from "../style/global.style";
import { getFileProperties } from "../requests/api-requests/files.request";
import NmCheckBoxGroup from "../components/NmCheckBoxGroup";
import { sendMessageToTaskpane } from "../utils/sendMessageToTaskpane";
import { footerCheckboxSchema } from "../constants";
import { TaskpaneHeader } from "../components/TaskpaneHeader";
import { insertFooter } from "../utils/insertFooter";
import { LoadingContext } from "../context/LoadingContext";
import { MessageContext } from "../context/MessageContext";
import { controlPagePermissions } from "../utils/controls";

export default function Footer() {
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [fileProperties, setFileProperties] = useState({});
  const { openMessage } = useContext(MessageContext);

  useEffect(async () => {
    await controlPagePermissions();
  }, []);

  const wordContent = localStorage.getItem("selectedDocumentFile");
  const params = JSON.parse(wordContent);
  let doi = params ? params.doi : null;

  const footerForm = useFormik({
    initialValues: {
      doi: false,
      version: false,
      filePath: false,
      docName: false,
    },
    onSubmit: async (data) => {
      showLoading();
      const payload = {};

      for (const [key, value] of Object.entries(data)) {
        if (value !== false) {
          payload[key] = fileProperties[key] || doi;
        }
      }

      await insertFooter(payload, true);
      openMessage("success", "Footer Successfully Added.");
    },
  });

  const checkFooter = async () => {
    showLoading();
    await Word.run(async (context) => {
      let payload = {};
      const mySections = context.document.sections;
      mySections.load();
      await context.sync();
      const myFooter = mySections.items[0].getFooter(Word.HeaderFooterType.primary);
      myFooter.load();
      await context.sync();
      const object = JSON.parse(JSON.stringify(footerForm.initialValues))
      for (const [key, value] of Object.entries(object)) {
        footerForm.initialValues[key] = myFooter.text.includes(key);
      }
      hideLoading();
    });
  };

  useEffect(() => {
    if (!doi) return;
    getFileProperties({ doi }).then((data) => {
      const { version, docName, path } = data;
      let splitPath = path.split("/");
      const renderPath = splitPath.slice(0, -1).join("/");

      setFileProperties({
        version: version || "",
        docName: docName || "",
        filePath: renderPath || "",
        doi: doi || "",
      });
    });
    checkFooter();
    console.log(footerForm);
  }, []);

  const handleCloseDialog = () => {
    sendMessageToTaskpane({ messageType: "dialogClose" });
  };

  return (
    <div>
      <TaskpaneHeader title={"Footer/Header"} showBackButton={false} headerColor={"#eff4fa"} textColor={"black"} />
      <div className={`px-4 relative ${global.container}`}>
        <div className="row">
          <div className="col">
            <div className={`d-flex flex-column justify-content-between pt-4 ${global.pb75}`}>
              <div className={`d-flex flex-column gap-3 ${global.card}`}>
                <div className={global.cardLabel}>Include in Footer?</div>
                <NmCheckBoxGroup
                  schema={footerCheckboxSchema}
                  colCount="repeat(2,1fr)"
                  form={footerForm.values}
                  handleChange={footerForm.setFieldValue}
                />
              </div>
              <div className={`d-flex align-items-center justify-content-end gap-3 px-3 ${global.footerWithFixed}`}>
                {/* <DefaultButton className={global.secondaryButton} text="Cancel" onClick={() => handleCloseDialog()} /> */}
                <PrimaryButton className={global.primaryButton} onClick={footerForm.handleSubmit} text="Save" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
