import { guid } from "./uuidv4";
import { getAllSlices } from "./getAllSlices";
import { saveChunks } from "./saveChunks";

export const saveDocument = (payload, type, typeForSaveAsPdf, extraHeaders) => {
  return new Promise((resolve, reject) => {
    Office.context.document.getFileAsync(
      Office.FileType.Compressed,
      { sliceSize: 65536 /*64 KB*/ },
      async function (result) {
        
        if (result.status == Office.AsyncResultStatus.Succeeded) {
          var myFile = result.value;
          var output = [];
          getAllSlices(myFile).then(async (getSlices) => {
           
            const uuid = guid();
            if (getSlices.IsSuccess) {
              output = getSlices.Data;
            }
            output;
            for (let index = 0; index < output.length; index++) {
              const element = output[index];
              element.uuid = JSON.stringify(uuid);
            }
            const editedPayload = {
              uuid: JSON.stringify(uuid),
              count: output.length,
              ...payload,
            };
  
           await saveChunks(editedPayload, output, type, typeForSaveAsPdf, extraHeaders);
            myFile.closeAsync();
            resolve(JSON.stringify(editedPayload));
          });
        } else {
         
          console.log("Error:", result.error.message);
          reject(result.error.message);
        }
      }
    );
  });

};
