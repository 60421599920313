import React from "react";
import { PrimaryButton } from "office-ui-fabric-react";
import { global } from "../style/global.style";

import { images } from "../constants";

export default function ButtonValidation() {
  const refresh = () => {
    const wordContent = localStorage.getItem("selectedDocumentFile");
    const params = JSON.parse(wordContent);
    let doi = params ? params.doi : null;
    if (doi) {
      history.back();
    }
  };
  return (
    <div className="text-center mt-5 px-3">
      <div>
        <img width={"120"} height={"120"} src={images.ALERT_LOGO} />
      </div>
      <h5>First open a document and then "Save As New Document" or "Save As New Version"</h5>
      <hr />
      <p>
        If you have open a document and then "Save As New Document" or "Save As New Version", you can click the <strong>Refresh</strong> button.
      </p>
      <PrimaryButton className={global.primaryButton} text="Refresh" onClick={() => refresh()} />
    </div>
  );
}
