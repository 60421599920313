import { mergeStyleSets } from "office-ui-fabric-react";

export const classNames = mergeStyleSets({
  emailContainer: {
    display: "flex",
    gap: 8,
  },
  emailItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E5EEF7",
    padding: "8px 8px",
    borderRadius: 20,
    color: "#06090F",
    fontSize: 13,
    fontWeight: 600,
  },
  iconWrapper: {
    height: "auto",
    width: "auto",
    borderRadius: 5,
    cursor: "pointer",
    "&:hover,&:active": {
      backgroundColor: "transparent",
    },
    i: {
      margin: 0,
      fontSize: 11,
      color: "#000",
    },
  },
});
