import { Label, Toggle } from "office-ui-fabric-react";
import React from "react";

export default function NmToggle({ label, value, onChange, ...data }) {

  const handleChange = (e, checked) => {
    onChange(data.name, checked);
  };

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <Label>{label}</Label>
      <Toggle checked={value} onChange={handleChange} className="m-0" />
    </div>
  );
}