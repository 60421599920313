import { Pivot, PivotItem } from "office-ui-fabric-react";
import React, { useEffect, useState, useContext } from "react";
import Loader from "../components/Loader";
import { getFileProperties } from "../requests/api-requests/files.request";
import { pavPivotStyle, tabButtons } from "../style/pav.style";
import Properties from "../layouts/Properties";
import Versions from "../layouts/Versions";
import Activity from "../layouts/Activity";
import { LoadingContext } from "../context/LoadingContext";
import { TaskpaneHeader } from "../components/TaskpaneHeader";
import { controlPagePermissions } from "../utils/controls";

export default function Pav() {
  const token = localStorage.getItem("token");
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [tokenStatus, setTokenStatus] = useState(false);
  const [doiStatus, setDoiStatus] = useState(true);

  useEffect(async () => {
    await controlPagePermissions();
  }, []);

  let hash = new URL(document.location).hash.substring(1);
  const [selectedPivot, setSelectedPivot] = useState(hash);
  const [doi, setDoi] = useState("");
  const [fileProperties, setFileProperties] = useState("");

  const selectedDocumentFileLS = localStorage.getItem("selectedDocumentFile");
  const selectedDocumentFile = JSON.parse(selectedDocumentFileLS);

  useEffect(() => {
    const activeDoi = selectedDocumentFile?.doi;
    if (activeDoi) {
      setDoiStatus(true);
      setDoi(activeDoi);
      getFileProperties({ doi: activeDoi })
        .then((payload) => {
          if (!payload) {
            clearToken();
          }
          setFileProperties(payload);
        })
        .catch((error) => {
          clearToken();
          location.href = location.href;
        });
    } else {
      setDoiStatus(false);
    }
  }, []);

  const handleChangeSelectedPivotItem = (item) => {
    if (navigator.userAgent.indexOf("Trident") === -1) {
      setSelectedPivot(item.props.itemKey);
    }
  };

  

  return (
    <div>
      <TaskpaneHeader title={"Mecellem add-in"} showBackButton={false} />
      <div className="d-flex flex-row bd-highlight mb-3 w-100">
        <div
          className={`${tabButtons.customTabButtons} ${
            selectedPivot === "properties" ? tabButtons.customTabButtonsSelected : ""
          } px-2 py-3 align-items-stretch text-center w-100`}
          onClick={() => handleChangeSelectedPivotItem("properties")}
        >
          Properties
        </div>
        <div
          className={`${tabButtons.customTabButtons} ${
            selectedPivot === "activities" ? tabButtons.customTabButtonsSelected : ""
          } px-2 py-3 align-items-stretch text-center w-100`}
          onClick={() => handleChangeSelectedPivotItem("activities")}
        >
          Activities
        </div>
        <div
          className={`${tabButtons.customTabButtons} ${
            selectedPivot === "versions" ? tabButtons.customTabButtonsSelected : ""
          } px-2 py-3 align-items-stretch text-center w-100`}
          onClick={() => handleChangeSelectedPivotItem("versions")}
        >
          Versions
        </div>
      </div>
      {selectedPivot == "properties" && <Properties fileProperties={fileProperties} />}
      {selectedPivot == "activities" && <Activity doi={selectedDocumentFile?.doi} /> }
      {selectedPivot == "versions" &&  <Versions selectedListItem={fileProperties} />}
    </div>
  );
}
