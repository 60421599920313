import { post } from "../instance/axios.instance";
import { ENDPOINTS } from "../../constants";

export const postCoAuthor = async (param) => {
  return new Promise((resolve, reject) => {
    post(ENDPOINTS.CO_AUTHOR, param)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
