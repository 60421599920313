import { Stack, StackItem } from "office-ui-fabric-react";
import React from "react";
import { advancedFilter } from "../style/nmAdvancedFilter.style";
import NmDialog from "./NmDialog";
import NmSavedFilterItem from "./NmSavedFilterItem";

export default function NmSavedFilter({ data, opened, dialogToggle }) {
  return (
    <NmDialog opened={opened} dialogToggle={dialogToggle} width={250}>
      <Stack>
        <StackItem className={advancedFilter.savedFilterHeader}>Saved Filters</StackItem>
        <StackItem className={advancedFilter.savedFilterContent}>
          {data.map((item, index) => (
            <NmSavedFilterItem savedFilterItem={item} key={index} />
          ))}
        </StackItem>
      </Stack>
    </NmDialog>
  );
}
