const fullYearOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
const clockOptions = { hour: "2-digit", minute: "2-digit" };

export const formatDate = (date) => {
  return new Date(date)?.toLocaleDateString("tr-TR", fullYearOptions);
};

export const formatClock = (date) => {
  return new Date(date)?.toLocaleTimeString("tr-TR", clockOptions);
};
