import { IconButton, Stack, StackItem } from "office-ui-fabric-react";
import React from "react";
import { advancedFilter, CloseIcon } from "../style/nmAdvancedFilter.style";

export default function NmSavedFilterFormItem({ formItem }) {
  return (
    <Stack className={advancedFilter.formItem} horizontal verticalAlign="center">
      <StackItem className={advancedFilter.formItemInner}>{formItem}</StackItem>
      <StackItem>
        <IconButton iconProps={CloseIcon} className={advancedFilter.formItemIcon} />
      </StackItem>
    </Stack>
  );
}
