import React, { useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import Combine from "../pages/Combine";
import Compare from "../pages/Compare";
import CopyLink from "../pages/CopyLink";
import Login from "../pages/Login";
import Open from "../pages/Open";
import Pav from "../pages/Pav";
import SaveAndSync from "../pages/SaveAndSync";
import SaveAsNewDocument from "../pages/SaveAsNewDocument";
import SaveAsNewVersion from "../pages/SaveAsNewVersion";
import SaveAsPdf from "../pages/SaveAsPdf";
import SendLink from "../pages/SendLink";
import Taskpane from "../pages/Taskpane";
import CoAuthor from "../pages/coAuthor";
import Footer from "../pages/Footer";
import InternetConnection from "../pages/InternetConnection";
import ButtonValidation from "../pages/ButtonValidation";
import Logout from "../pages/logout";

const PageRouter = () => {
  const { page } = useContext(GlobalContext);

  switch (page) {
    case "openFile":
      return <Open />;
    case "taskpane":
      return <Taskpane />;
    case "copyLink":
      return <CopyLink />;
    case "sendLink":
      return <SendLink />;
    case "saveAsPdf":
      return <SaveAsPdf />;
    case "saveAsNewVersion":
      return <SaveAsNewVersion />;
    case "saveAsNewVersionPdf":
      return <SaveAsNewVersion />;
    case "saveAndSync":
      return <SaveAndSync />;
    case "saveAsNewDocument":
      return <SaveAsNewDocument />;
    case "saveAsNewDocumentPdf":
      return <SaveAsNewDocument />;
    case "combine":
      return <Combine />;
    case "compare":
      return <Compare />;
    case "pav":
      return <Pav />;
    case "login":
      return <Login />;
    case "coAuthor":
      return <CoAuthor />;
    case "footer":
      return <Footer />;
    case "InternetConnection":
      return <InternetConnection />;
    case "ButtonValidation":
      return <ButtonValidation />;
    case "logout":
      return <Logout />;
    default:
      return <div>Eşleşme yapılamadı. Mevcut page:{page}</div>;
  }
};

export default PageRouter;
