import { DefaultButton, Icon, Label, PrimaryButton } from "office-ui-fabric-react";
import { useFormik } from "formik";
import React, { useEffect, useState, useContext } from "react";
import NmForm from "../components/NmForm";
import { getFileProperties, getFileShare } from "../requests/api-requests/files.request";
import { copyLink } from "../style/copyLink.style";
import { global } from "../style/global.style";
import { sendMessageToTaskpane } from "../utils/sendMessageToTaskpane";
import { TaskpaneHeader } from "../components/TaskpaneHeader";
import { controlPagePermissions } from "../utils/controls";
import { LoadingContext } from "../context/LoadingContext";
import NmInput from "../components/NmInput";
import copy from "copy-to-clipboard";

const menuProps = {
  items: [
    {
      key: "readOnly",
      text: "Read Only",
    },
    {
      key: "edit",
      text: "Edit",
    },
    {
      key: "other",
      text: "Other",
    },
  ],
};

const copyLinkSchema = [
  {
    type: "date",
    name: "expirationDate",
    isWidthFull: true,
    icon: "Calendar",
    placeholder: "Set expiration date",
    name: "max_date",
    minDate: new Date(),
  },
  {
    type: "input",
    name: "password",
    placeholder: "Set Password",
    isWidthFull: true,
    icon: "Lock",
    name: "password",
  },
  {
    type: "toggle",
    name: "isDowndload",
    label: "Block Download",
    isWidthFull: true,
    icon: "Blocked",
    name: "download",
  },
];

export default function CopyLink({ docParams = null, cancelEvent }) {
  const { showLoading, hideLoading } = useContext(LoadingContext);

  useEffect(async () => {
    await controlPagePermissions();
  }, []);

  let params = localStorage.getItem("selectedDocumentFile")
    ? JSON.parse(localStorage.getItem("selectedDocumentFile"))
    : {};
  let file_path = docParams ? docParams.path : params.path;
  let service_name = docParams ? docParams.service_name: params.service_name;
  let client_name = docParams ? docParams.client : params.client;
  let doi = docParams ? docParams.doi : params.doi;

  const fileName = file_path ? file_path.split("/") : [];

  const [sendStatus, setSendStatus] = useState(false);

  const [copyUrl, setCopyUrl] = useState("");
  const [fileProperties, setFileProperties] = useState({});

  const [linkCopiedText, setLinkCopiedText] = useState("");
  const [sendButtonDisableStatus, setSendButtonDisableStatus] = useState(false);

  useEffect(() => {
    if (!doi) return;
    getFileProperties({ doi }).then((payload) => {
      setFileProperties(payload);
    });
  }, [doi]);

  const copyLinkForm = useFormik({
    initialValues: {
      min_date: new Date(),
      max_date: "",
      password: "",
      download: false,
    },
    onSubmit: async (data) => {
      let payload = {
        file_path,
        service_name,
        client_name,
        ...data,
      };
      showLoading();
      setSendButtonDisableStatus(true);
      getFileShare(payload).then(async (data) => {
        setSendStatus(true);
        setCopyUrl(data.Url);
        hideLoading();
        copy(data.Url);
      });
    },
  });

  return (
    <div>
      {docParams === null && <TaskpaneHeader title={"Copy Link"} showBackButton={false} headerColor={"#8a97a3"} />}
      {sendStatus ? (
        <div className="text-center mt-5">
          <p>The link was copied successfully</p>
          <div className="px-3 my-3">
            <div className={`d-flex ${copyLink.linkContainer}`}>
              <div className="flex-fill w-100">
                <NmInput value={copyUrl} disabled={false} className="w-100" />
              </div>
              <div className="flex-fill px-2 pt-1">
                <Icon
                  disabled={!copyUrl}
                  onClick={() => copy(copyUrl)}
                  iconName={"Copy"}
                  className={copyLink.copyIcon}
                />
              </div>
            </div>
          </div>
          <DefaultButton
            text={`Back to the ${docParams ? "Versions" : "Copy Link"}`}
            className={global.primaryButton}
            onClick={() => docParams !== null ? cancelEvent() : (location.href = location.href)}
          />
        </div>
      ) : (
        <div className={`px-4 relative ${global.container}`}>
          <div className="row">
            <div className="col">
              <div className={`d-flex flex-column justify-content-between pt-4 ${global.pb75}`}>
                <div className="main d-flex flex-column gap-4">
                  <div className={`d-flex justify-content-between align-items-center ${copyLink.linkContainer}`}>
                    <span>{fileName[fileName.length - 1]}</span>
                    <Icon
                      disabled={!copyUrl}
                      onClick={() => copy(copyUrl)}
                      iconName={"Copy"}
                      className={copyLink.copyIcon}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <Icon iconName={"Share"} className={copyLink.shareIcon} />
                    <span className={copyLink.linkBoldText}>Anyone with the link can view-only</span>
                  </div>
                  <div className={`${copyLink.formContainer} d-flex flex-column`}>
                    <Label>Settings</Label>
                    <NmForm formSchema={copyLinkSchema} formik={copyLinkForm} classNames="p-0" />
                  </div>
                  <div style={{ color: "red", textAlign: "center" }}>{linkCopiedText}</div>
                </div>
                <div className={`d-flex align-items-center justify-content-end gap-3 px-3 ${global.footerWithFixed}`}>
                  {docParams !== null && (
                    <DefaultButton
                      className={global.secondaryButton}
                      onClick={() => cancelEvent()}
                      text="Cancel"
                    />
                  )}
                  <PrimaryButton
                    className={global.primaryButton}
                    onClick={copyLinkForm.handleSubmit}
                    text="Copy"
                    disabled={sendButtonDisableStatus}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
