import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { userLogin } from "../requests/api-requests/user";
import { GlobalContext } from "./GlobalContext";
import { MessageContext } from "./MessageContext";
import { LoadingContext } from "./LoadingContext";


export const UserContext = createContext(null);

export function UserContextProvider({ children }) {
  const { showLoading, hideLoading } = useContext(LoadingContext);
  let params = new URL(document.location).searchParams;
  let oldPage = params.get("oldPage");
  const { setPage } = useContext(GlobalContext);
  const { openMessage } = useContext(MessageContext);

  const loginRequest = async (payload) => {
    userLogin(payload).then((response) => {
      hideLoading();
      console.log(response);
      if (response.error) {
        openMessage("error", response.error);
        return;
      }
      openMessage("success", "Success");
      const res = response.data;
      const userData = { name: res.user, title: res.title, photo: res.photo, clientName: res.clientName  };    
      localStorage.setItem("token", res.accesstoken);
      localStorage.setItem("userDetails", JSON.stringify(userData));
      if (res.versiyon) {
        localStorage.setItem("apiVersion", JSON.stringify(res.versiyon));
      }

      if (oldPage) {
        setPage(oldPage);
        window.location.search = `?page=${oldPage}`;
      }
    
    });
  };

  const login = useCallback(async (payload) => {
    showLoading();
    await loginRequest(payload);
  }, []);

  const context = useMemo(() => ({ login }), [login]);

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
}
