import { DirectionalHint } from "office-ui-fabric-react";
import React, { useContext, useEffect, useState } from "react";
import NmCurrentVersion from "../components/NmCurrentVersion";
import NmVersionCard from "../components/NmVersionCard";
import { dialogProperties } from "../constants";
import { useFetch } from "../hooks/use-fetch";
import { getFile, getFileVersions } from "../requests/api-requests/files.request";
import { openWordFileNewDocScreen } from "../utils/openWord";
import { LoadingContext } from "../context/LoadingContext";
import CopyLink from "../pages/CopyLink";
import SendLink from "../pages/SendLink";
import Compare from "../pages/Compare";

export default function Versions({ selectedListItem }) {
  let dialog;
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedActioDoi, setSelectedActioDoi] = useState({ action: "", docParams: {} });
  const { setLoading, showLoading, hideLoading } = useContext(LoadingContext);

  const [versionList] = useFetch(
    () => (selectedListItem.doi ? getFileVersions({ doi: selectedListItem.doi }) : () => {}),
    [],
    { setLoading, reloadDeps: [selectedListItem], deps: [selectedListItem] }
  );

  const menuProps = {
    items: [
      {
        key: "edit",
        text: "Edit",
        iconProps: { iconName: "Edit" },
      },
      {
        key: "copyLink",
        text: "Copy Link",
        iconProps: { iconName: "Link" },
      },
      {
        key: "sendLink",
        text: "Send Link",
        iconProps: { iconName: "Send" },
      },
      {
        key: "compare",
        text: "Compare",
        iconProps: { iconName: "Switch" },
      },
    ],
    onItemClick: (e, action) => {
      setSelectedAction(action.key);
    },
    directionalHint: DirectionalHint.leftTopEdge,
  };

  const handleSelectedItem = (item) => {
    const params = { path: item.path, service_name: item.service_name, client: item.client, doi: item.doi };
    switch (selectedAction) {
      case "edit":
        showLoading();
        getFile({
          service_name: item.service_name,
          path: item.path,
          version_info: item.version,
        }).then(async (data) => {
          await openWordFileNewDocScreen(data.data.base64Data);
          hideLoading();
        });
        break;
      case "copyLink":
        console.log(1);
        setSelectedActioDoi({ action: "copyLink", docParams: params });
        break;
      case "sendLink":
        console.log(2);
        setSelectedActioDoi({ action: "sendLink", docParams: params });
        break;
      case "compare":
        console.log(3);
        setSelectedActioDoi({ action: "compare", docParams: params });
        break;
      default:
    }
  };


  return (
    <div>
      {selectedActioDoi.action === "" && (
        <div>
          <NmCurrentVersion selectedListItem={selectedListItem} />
          {versionList &&
            versionList.map((item, index) => (
              <div key={index}>
                <NmVersionCard
                  index={index}
                  item={item}
                  menuProps={menuProps}
                  showMore
                  handleSelectedItem={handleSelectedItem}
                />
              </div>
            ))}
        </div>
      )}
      {selectedActioDoi.action === "copyLink" && (
        <div>
          <CopyLink docParams={selectedActioDoi.docParams} cancelEvent={() => setSelectedActioDoi({ action: "", docParams: {} })} />
        </div>
      )}
      {selectedActioDoi.action === "sendLink" && (
        <div>
          <SendLink docParams={selectedActioDoi.docParams} cancelEvent={() => setSelectedActioDoi({ action: "", docParams: {} })} />
        </div>
      )}
      {selectedActioDoi.action === "compare" && (
        <div>
          <Compare docParams={selectedActioDoi.docParams} cancelEvent={() => setSelectedActioDoi({ action: "", docParams: {} })} />
        </div>
      )}
    </div>
  );
}
