export const findIcon = (item) => {
  switch (true) {
    case iconSelector(item, "documents"):
      return "WordLogo";
    case iconSelector(item, "clients"):
      return "People";
    case iconSelector(item, "matters"):
      return "WebAppBuilderFragment";
    case iconSelector(item, "munderecat"):
      return "FabricFolder";
    case iconSelector(item, "contracts"):
      return "EditNote";
    case iconSelector(item, "cases"):
      return "EditNote";
    case iconSelector(item, "stages"):
      return "ListMirrored";
    case iconSelector(item, "muamele"):
      return "Diamond";
    default:
      return;
  }
};

const iconSelector = (item, value) => {
  return item.toLowerCase().includes(value);
};
