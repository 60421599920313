import { mergeStyleSets } from "office-ui-fabric-react";

export const versions = mergeStyleSets({
  currentVersionWrapper: {
    padding: 15,
  },
  currentVersionInner: {
    padding: 12,
    backgroundColor: "#F4F8FA",
  },
  currentVersionText: {
    color: "#53646C",
    fontWeight: 600,
    fontSize: 12,
  },
  currentVersionTitle: {
    color: "#06090F",
    fontWeight: 600,
    fontSize: 13,
  },
  currentVersionBoldProperties: {
    color: "#AEB8C1",
    fontSize: 11,
    fontWeight: 600,
  },
  currentVersionProperties: {
    color: "#AEB8C1",
    fontSize: 11,
  },
  currentVersionContext: {
    color: "#AEB8C1",
  },
  cardWrapper: {
    backgroundColor: "#F4F8FA",
    padding: 16,
    borderTop: "solid 1px rgba(0, 0, 0, 0.1)",
    cursor: "pointer"
  },
  cardWrapperSelected: {
    backgroundColor: "white",
    padding: 16,
    borderTop: "solid 1px rgba(0, 0, 0, 0.1)",
  },
  image: {
    borderRadius: "50%",
  },
  cardContext: {
    width: "100%",
  },
  leftSide: {
    minWidth: 32,
  },
  moreIcon: {
    fontSize: 20,
    color: "black",
    height: "auto",
    margin: 0,
    padding: 0,
    "&:hover": {
      backgroundColor: "none",
      color: "none",
    },
    i: { color: "black" },
  },
  version: {
    fontWeight: "bold",
    fontSize: 13,
  },
  person: {
    fontWeight: 600,
    fontSize: 13,
    color: "#53646C",
  },
  cardText: {
    fontSize: 10,
    color: "#AEB8C1",
    span: {
      fontWeight: 600,
    },
  },
  cardCommentTitle: {
    fontWeight: 600,
    fontSize: 11,
  },
  cardCommentText: {
    fontSize: 11,
  },
});
