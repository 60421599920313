import { mergeStyleSets } from "office-ui-fabric-react";

export const loginStyle = mergeStyleSets({
  container: {
    height: "100vh",
  },
  footer: {
    width:"100%",
    color: "#7e7e7e",
  },
});
