import { useCallback, useEffect, useState } from 'react';

export function useFetch(
  fetcher,
  defaultValue = null,
  { setLoading = () => {} , deps = [], reloadDeps = [] } = {}
) {
  const [data, setData] = useState(defaultValue);
  const fetch = useCallback(async () => {
    setLoading(true);
    setData(await fetcher());
    setLoading(false);
  }, deps); 

  useEffect(() => {
    fetch();
  }, reloadDeps);

  return [data, fetch, setData];
}
