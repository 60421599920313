import { async } from "regenerator-runtime";
import { footerCheckboxSchema } from "../constants";

const clearFooter = async () => {
  return await Word.run(async (context) => {
    var sections = context.document.sections;
    sections.load();
    return await context.sync().then(function () {
      sections.items.forEach(function (section) {
        // Clear any Footers.
        section.getFooter("primary").clear();
        section.getFooter("firstPage").clear();
        section.getFooter("evenPages").clear();
      });
    });
  });
};
export const insertFooter = async (data, isNewFooter) => {
  await clearFooter();

  let myString = "";
  if (isNewFooter) {
    myString = Object.keys(data)
      .map((key) => `${key}: ${data[key]}`)
      .join("\n");
  } else {
    myString = data;
  }


 return await Word.run(async (context) => {
    const mySections = context.document.sections;
    mySections.load("body/style");
    await context.sync();
    const myFooter = mySections.items[0].getFooter(Word.HeaderFooterType.primary);
    myFooter.insertText(myString, Word.InsertLocation.end);
    myFooter.insertContentControl();
    return await context.sync();
  });
};

const renderValue = (value) => {
  switch (value) {
    case "docName":
      return "doc_name";
    case "filePath":
      return "path";
    case "version":
      return "version_info";
    default:
      return "doi";
  }
};

export const checkFooter = async (newPayload) => {
  Word.run(async (context) => {
    let payload = {};
    const mySections = context.document.sections;
    mySections.load();
    await context.sync();
    const myFooter = mySections.items[0].getFooter(Word.HeaderFooterType.primary);
    myFooter.load();
    await context.sync();

    for (let index = 0; index < footerCheckboxSchema.length; index++) {
      var element = footerCheckboxSchema[index];
      let result = myFooter.text.includes(element.name);
      if (result) {
        payload = { ...payload, [element.name]: newPayload[renderValue(element.name)] };
      }
    }
   await insertFooter(payload, true);
  });
};
