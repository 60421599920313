const ENDPOINTS = {
  DEVELOPMENT_ASSETS_URL: "https://localhost:3000",
  DEVELOPMENT_API_URL: "https://addtest5000.mecellem.com/api",
  PROD_ASSETS_URL: "https://mecellemaddin.mecellem.com/",
  PROD_API_URL: "https://addportprodback.mecellem.com/api",
  TEST_ASSETS_URL: "https://mecellemtest.mehmetmuratbozkir.com",
  TEST_API_URL: "https://addtest5000.mecellem.com/api",
};
const VERSION = {
  VERSION: "1.0.0.5",
  BUILD_DATE: "28.08.2023"
};
module.exports = { ENDPOINTS, VERSION };
