import React, { useEffect, useState } from "react";
import NmBreadcrumb from "../components/NmBreadcrumb";
import { global } from "../style/global.style";
import { saveAndSync } from "../style/saveAndSync.style";
import { sendMessageToTaskpane } from "../utils/sendMessageToTaskpane";
import ContentCheck from "../components/ContentCheck";
import { useDialogState } from "../hooks/use-dialog-state";
import { saveDocument } from "../utils/saveDocument";
import { TaskpaneHeader } from "../components/TaskpaneHeader";
import { DefaultButton } from "office-ui-fabric-react";
import { controlPagePermissions } from "../utils/controls";

export default function SaveAndSync() {
  const [openedContentDialog, dialogToggleContentDialog] = useDialogState();
  const [breadcrumb, setBreadcrumb] = useState([]);
  const queries = ["path", "service_name", "file_name", "file_path"];
  const [saveComplateStatus, setSaveComplateStatus] = useState(false);

  useEffect(async () => {
    await controlPagePermissions();
  }, []);


  const wordContent = localStorage.getItem("selectedDocumentFile");
  const params = JSON.parse(wordContent);
  let doi = params ? params.doi : null;

  const savedData = localStorage.getItem("payloadFromSaveAs");

  useEffect(() => {
    if (doi && savedData) return;
    dialogToggleContentDialog();
  }, []);

  const save = async () => {
    var output = {
      client_name: params.client,
      file_name: localStorage.getItem("selectedDocumentName"),
      path: params.path,
      service_name: params.service_name,
    };
    let splitPath = output?.path?.split("/").slice(1);
    let breadcrumbData = [];
    let breadcrumbItem = {};
    if (splitPath) {
      for (let index = 0; index < splitPath.length; index++) {
        const element = splitPath[index];
        breadcrumbItem = { text: element, key: element, onClick: () => {} };
        breadcrumbData.push(breadcrumbItem);
      }
    }
    setBreadcrumb(breadcrumbData);
    if (output.path) {
      let formatedPath = "";
      for (let index = 0; index < splitPath.length - 1; index++) {
        const element = splitPath[index];
        formatedPath += element + "/";
      }
      output.path = formatedPath.slice(0, -1);
      const extraHeaders = { entity: "save_and_sync", module: "save_and_sync", action: "save_and_sync" };
      setSaveComplateStatus(false);
      await saveDocument(output, "saveAndSync", null, extraHeaders);
      setSaveComplateStatus(true);
    }
  };

  useEffect(async () => {
    save();
  }, []);

  const handleCloseDialog = () => {
    sendMessageToTaskpane({ messageType: "dialogClose" });
  };

  return (
    <div>
      <TaskpaneHeader title={"Save and Sync"} showBackButton={false} headerColor={"#e4e7ea"} textColor={"black"} />
      {!saveComplateStatus ? (
        <div className="text-center pt-5">
          <img alt="" src="../../../assets/loading-sync.svg" style={{ width: "80px" }} />
        </div>
      ) : (
        <div className="text-center mt-5">
          <p> Do you want save again?</p>
          <DefaultButton text="Save Sync Again" className={global.primaryButton} onClick={() => save()} />
        </div>
      )}

      <ContentCheck
        doi={doi}
        handleCloseDialog={handleCloseDialog}
        dialogToggleContentDialog={dialogToggleContentDialog}
        openedContentDialog={openedContentDialog}
      />
    </div>   
  );
}
