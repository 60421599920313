import * as React from "react";
import { ChoiceGroup } from "office-ui-fabric-react";
import { choiceGroupStyles } from "../style/global.style";

export const NmChoiceGroup = ({ options, label, value, handleChange, name }) => {
  const _onChange = (e, option) => {
    handleChange(name, option.key);
  };

  return (
    <div>
      <ChoiceGroup
        selectedKey={value}
        options={options}
        onChange={_onChange}
        label={label}
        className={choiceGroupStyles}
        styles={choiceGroupStyles}
      />
    </div>
  );
};
