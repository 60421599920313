import React, { useContext } from "react";
import { Icon } from "office-ui-fabric-react";
import { GlobalContext } from "../context/GlobalContext";
import { taskpaneHeaderWithTheme } from "../style/taskpaneHeader.style";
import { themeColors } from "../constants";

export const TaskpaneHeader = ({ title, handleBackClick, showBackButton = true, headerColor = null, textColor = null }) => {
  const { theme } = useContext(GlobalContext);
  return (
    <div
      className={
        headerColor
          ? taskpaneHeaderWithTheme(null, headerColor).taskPaneHeaderCustomColor
          : taskpaneHeaderWithTheme(theme).taskPaneHeader
      }
    >
      <div className="d-flex justify-content-between">
        <div>
          <Icon
            className={`${taskpaneHeaderWithTheme(theme).taskPaneHeaderBackIcon} ${showBackButton ? "" : "d-none"}`}
            onClick={() => handleBackClick()}
            iconName="ChevronLeft"
          />
        </div>
        <div className={taskpaneHeaderWithTheme(null, headerColor, textColor).titleStyle}>{title}</div>
        <div>
          {/* <Icon iconName="More" />{" "} */}
        </div>
      </div>
    </div>
  );
};
