import { DefaultButton, Icon, PrimaryButton } from "office-ui-fabric-react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import NmForm from "../components/NmForm";
import { global } from "../style/global.style";
import { saveAsNewVersion } from "../style/nmSaveAsNewVersion.style";
import { sendMessageToTaskpane } from "../utils/sendMessageToTaskpane";
import { getFileDocTypes, getFileFromTo, getFileProperties } from "../requests/api-requests/files.request";
import { useFetch } from "../hooks/use-fetch";
import NmInput from "../components/NmInput";
import { GlobalContext } from "../context/GlobalContext";
import ContentCheck from "../components/ContentCheck";
import { useDialogState } from "../hooks/use-dialog-state";
import * as Yup from "yup";
import { LoadingContext } from "../context/LoadingContext";
import { saveDocument } from "../utils/saveDocument";
import { TaskpaneHeader } from "../components/TaskpaneHeader";
import { checkFooter } from "../utils/insertFooter";
import { MessageContext } from "../context/MessageContext";
import { controlPagePermissions } from "../utils/controls";

export default function SaveAsNewVersion() {
  const { setPage, page } = useContext(GlobalContext);

  const [openedContentDialog, dialogToggleContentDialog] = useDialogState();
  const { showLoading, hideLoading, setLoading } = useContext(LoadingContext);
  const { openMessage } = useContext(MessageContext);
  const [saveComplateStatus, setSaveComplateStatus] = useState(false);
  
  useEffect(async () => {
    await controlPagePermissions();
  }, [])



  if (localStorage.getItem("saveStatus")) {
    setSaveComplateStatus(true);
    localStorage.removeItem("saveStatus");
  }

  const wordContent = localStorage.getItem("selectedDocumentFile");
  const params = JSON.parse(wordContent);
  let doi = params ? params.doi : null;

  useEffect(() => {
    if (doi) return;
    dialogToggleContentDialog();
  }, []);

  const [selectedDocument] = useFetch(
    () => getFileProperties({ doi }),
    {},
    { setLoading, reloadDeps: [doi], deps: [doi] }
  );

  const newVersionSchema = [
    {
      type: "version",
      label: "Version",
      name: "version_info",
    },
    {
      type: "textarea",
      isWidthFull: true,
      icon: "Comment",
      placeholder: "Uygulama Sözleşmesi’ne İlişkin Sözleşme Taslağı",
      label: "Comments",
      name: "version_comment",
    },
    {
      label: "Doc Date",
      type: "date",
      name: "doc_date",
    },
    {
      type: "searchableDropdown",
      label: "Doc Type",
      placeholder: "Doc Type",
      name: "doc_type",
      selectedProp: "label",
      handleGetData: (payload) => getFileDocTypes(payload),
    },
    {
      type: "searchableDropdown",
      label: "From",
      placeholder: "Choose",
      selectedProp: "label",
      name: "from",
      handleGetData: (payload) => getFileFromTo(payload),
    },
    {
      type: "searchableDropdown",
      label: "To",
      placeholder: "Choose",
      selectedProp: "label",
      name: "to",
      handleGetData: (payload) => getFileFromTo(payload),
    },
    {
      type: "textarea",
      isWidthFull: true,
      placeholder: "Text",
      label: "Subject",
      name: "doc_subject",
    },
  ];

  const validationSchema = Yup.object({
    doc_name: Yup.string().required("Required Field"),
    doc_date: Yup.date().required("Required Field"),
    doc_type: Yup.string().required("Required Field"),
    doc_subject: Yup.string().required("Required Field"),
    from: Yup.string().required("Required Field"),
    to: Yup.string().required("Required Field"),
    version_comment: Yup.string().required("Required Field"),
  });

  const saveAsNewVersionForm = useFormik({
    initialValues: {
      version_info: "",
      version_comment: "",
      doc_subject: "",
      doc_type: "",
      from: "",
      to: "",
      doc_date: "",
      doc_name: "",
      service_name: "",
      path: "",
    },
    validationSchema,
    onSubmit: async (data) => {
      const docName = `${data.doc_name.replace(/.docx/gi, "")}.docx`;
      showLoading();
      if (page === "saveAsNewVersionPdf") {

        showLoading();
        const payload = {
          ...data,
          file_path: data.path,
          file_name: docName,
        };       
        await saveDocument(payload, "saveAsPdf", "saveAsNewDocument");
        openMessage("success", "Document Successfully Saved.");
        setTimeout(() => {
          window.location = `${window.location.origin}/index.html?page=saveAsPdf`;
        }, 4000);
      } else {
        const payload = {
          ...data,
          file_path: data.path,
          file_name: docName,
        };
        localStorage.setItem("selectedDocumentName", data.doc_name);
        localStorage.setItem("payloadFromSaveAs", JSON.stringify(payload));
        const extraHeaders = { entity: "save_as_new_version", module: "document", action: "add" };
        checkFooter(payload);
        showLoading();
        await saveDocument(payload, "saveAsNewVersion", null, extraHeaders);
        openMessage("success", "Document Successfully Saved.");
        setTimeout(() => {
          setSaveComplateStatus(true);
          hideLoading();
        }, 4000);
      }
    },
  });

  useEffect(() => {
    const { version, versionNote, from, to, docSubject, docType, docName, client, service_name, path } =
      selectedDocument;

    saveAsNewVersionForm.setValues({
      client_name: client || "",
      version_info: version || "",
      version_comment: versionNote || "",
      doc_subject: docSubject || "",
      doc_type: docType || "",
      from: from || "",
      to: to || "",
      doc_date: new Date() || "",
      doc_name: docName || "",
      service_name: service_name || "",
      path: path || "",
    });
  }, [selectedDocument]);

  const redirectOpenPage = () => {
    setPage("openFile");
  };

  const handleCloseDialog = () => {
    sendMessageToTaskpane({ messageType: "dialogClose" });
  };

  return (
    <div>
      <TaskpaneHeader title={"Save As New Version"} showBackButton={false} headerColor={"#8a97a3"} />

      {!saveComplateStatus ? (
        <div className={`relative ${global.container}`}>
          <div className="d-flex flex-column justify-content-between pt-4">
            <div className={`d-flex flex-column gap-3 ${saveAsNewVersion.formWrapper}`}>
              <div className="d-flex align-items-end gap-3">
                <Icon className={saveAsNewVersion.icon} iconName={"WordDocument"} />
                <div className="w-100">
                  <NmInput
                    name={"doc_name"}
                    value={saveAsNewVersionForm.values["doc_name"]}
                    label={"label"}
                    errorMessage={saveAsNewVersionForm.values["doc_name"] == "" ? "Required Field" : ""}
                    onChange={saveAsNewVersionForm.handleChange}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className={saveAsNewVersion.blank} />
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className={`d-flex align-items-center gap-2 ${saveAsNewVersion.text}`}>
                    <span>Current Version: {selectedDocument.version}</span>
                  </div>
                  <div className={saveAsNewVersion.link} onClick={redirectOpenPage}>
                    Select Different File
                  </div>
                </div>
              </div>
              <hr className="hr" />
              <NmForm formSchema={newVersionSchema} formik={saveAsNewVersionForm} />
            </div>
            <div className={global.footerWithFixed}>
              <div className="d-flex align-items-center justify-content-end gap-3 px-4">
                {/* <DefaultButton text="Cancel" className={global.secondaryButton} onClick={() => handleCloseDialog()} /> */}
                <PrimaryButton
                  className={global.primaryButton}
                  text="Send"
                  onClick={() => saveAsNewVersionForm.handleSubmit()}
                />
              </div>
            </div>
          </div>
          <ContentCheck
            doi={doi}
            handleCloseDialog={handleCloseDialog}
            dialogToggleContentDialog={dialogToggleContentDialog}
            openedContentDialog={openedContentDialog}
          />
        </div>
      ) : (
        <div className="text-center mt-5">
          <p>New Version Saved Successfully</p>
          <DefaultButton
            text="Back to the Save New Version"
            className={global.primaryButton}
            onClick={() => setSaveComplateStatus(false)}
          />
        </div>
      )}
    </div>
  );
}
