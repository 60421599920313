import { Icon } from "office-ui-fabric-react";
import React from "react";
import { form, formWithWidth } from "../style/nmForm.style";
import NmDate from "./NmDate";
import NmDropdown from "./NmDropdown";
import NmInput from "./NmInput";
import NmTargetProperties from "./NmTargetProperties";
import NmTextarea from "./NmTextarea";
import NmToggle from "./NmToggle";
import NmVersion from "./NmVersion";
import NmSearchableDropdown from "./NmSearchableDropdown";

export default function NmForm({ formSchema, formik, classNames }) {
  return (
    <div className={`${form.formWrapper} ${classNames}`}>
      {formSchema &&
        formSchema.map((item, index) => {
          return (
            <div key={index} className={formWithWidth(item.isWidthFull).formItem}>
              <div className="d-flex align-items-center pb-3">
                {item.icon && <Icon className={"me-2"} iconName={item.icon} />}
                <div className="w-100">{renderForm(item, index, formik)}</div>
              </div>
              {item.underline && <div className={form.underline} />}
            </div>
          );
        })}
    </div>
  );
}
const renderForm = (item, index, formik) => {
  let { values } = formik;

  const isInvalidField = (item) => {
    if (formik.errors[item.name] && formik.touched[item.name]) {
      return formik.errors[item.name];
    }
  };

  switch (item.type) {
    case "dropdown":
      return <NmDropdown value={values[item.name]} isFullWidth={item.isWidthFull} onChange={formik.setFieldValue} {...item} isForm />;
    case "searchableDropdown":
      return (
        <NmSearchableDropdown
          value={values[item.name]}
          onChange={formik.setFieldValue}
          errorMessage={isInvalidField(item)}
          {...item}
          isForm
        />
      );
    case "date":
      return <NmDate value={values[item.name]} onChange={formik.setFieldValue} {...item} />;
    case "input":
      return (
        <NmInput
          value={values[item.name]}
          onChange={formik.handleChange}
          errorMessage={isInvalidField(item)}
          isPassword={item.isPassword}          
          {...item}
        />
      );
    case "textarea":
      return (
        <NmTextarea
          value={values[item.name]}
          onChange={formik.handleChange}
          errorMessage={isInvalidField(item)}
          {...item}
        />
      );
    case "version":
      return <NmVersion value={values[item.name] || "1.0"} onChange={formik.setFieldValue} {...item} />;
    case "targetProperties":
      return <NmTargetProperties {...item} />;
    case "toggle":
      return <NmToggle value={values[item.name]} onChange={formik.setFieldValue} {...item} />;
    default:
  }
};
