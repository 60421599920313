import { mergeStyleSets } from "office-ui-fabric-react";

export const saveAndSync = mergeStyleSets({
  breadcrumbIcon: {
    fontSize: 25,
  },
  emptyIcon: {
    fontSize: 40,
    color: "#0086E7",
  },
  emptyText: {
    color: "#8E9A9D",
    fontSize: 13,
    fontWeight: 600,
  },
  emptyContainer: {
    height: "100%",
  },
  breadcrumbContainer: {
    backgroundColor: "rgb(223, 229, 240, 0.35)",
    borderRadius: 6,
    padding: 6,
  },
});
