import React, { useEffect } from "react";
import { versions } from "../style/versions.style";
import { Image, ImageFit, Icon, Stack, StackItem, IconButton  } from "office-ui-fabric-react";

const emojiIcon = { iconName: "More" };

export default function NmVersionCard({
  item,
  menuProps,
  showMore,
  handleSelectedItem,
  selectVersiyon,
  selectedVersiyon,
}) {
  const setSelectedVersion = (version) => {
    selectVersiyon(version);
  };

  return (
    <Stack
      horizontal
      className={selectedVersiyon == item.version ? versions.cardWrapperSelected : versions.cardWrapper}
      onClick={() => setSelectedVersion(item.version)}
    >
      <Stack className={versions.cardContext}>
        <Stack horizontal>
          <StackItem>
            <Image className={versions.image} src={item.image} width={32} height={32} imageFit={ImageFit.cover} />
          </StackItem>
          <Stack className={versions.cardContext}>
            <Stack horizontal horizontalAlign="space-between" className={versions.cardContext}>
              <StackItem className={versions.version}>
                V{item.version} {selectedVersiyon}
              </StackItem>
              <div>
                {showMore && (
                  <IconButton
                    split={false}
                    menuProps={menuProps}
                    menuIconProps={emojiIcon}
                    title="Emoji"
                    ariaLabel="Emoji"
                    className={versions.moreIcon}
                    onAfterMenuDismiss={() => handleSelectedItem(item)}
                  />
                )}
              </div>
            </Stack>
            <StackItem className={versions.person}>{item.name}</StackItem>
            <StackItem className={versions.cardText}>
              <span> Last Updated By:</span> {item.owner}
            </StackItem>
            <StackItem className={versions.cardText}>
              <span>Last Updated Date:</span> {item.date}
              {/* {formatDate(item.updatedDate)} {formatClock(item.updatedDate)} */}
            </StackItem>
            <Stack horizontal horizontalAlign="end">
              <StackItem className={versions.cardText}>{item.size}</StackItem>
            </Stack>
          </Stack>
        </Stack>
        <Stack horizontal gap={12} verticalAlign="center">
          <Stack horizontalAlign="center" className={versions.leftSide}>
            <Icon iconName={"Comment"} />
          </Stack>
          <Stack>
            <StackItem className={versions.cardCommentTitle}>Comment:</StackItem>
            <StackItem className={versions.cardCommentText}>{item.note}</StackItem>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
