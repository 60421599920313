import React, { useContext, useState } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { classNames, classNamesWithTheme } from "../style/nmFilterTabs.style";
import { Icon } from "office-ui-fabric-react";

export default function NmFilterTabs({ data, selectedFilter, handleSelect, MWA, handleGetDocuments }) {
  const { theme } = useContext(GlobalContext);

  return (
    <div className={`${classNames.filterTabContainer} d-flex justify-content-start`}>
      <div className="d-flex justify-content-start w-75">
        {data?.map((item, index) => (
          <div
            className={`${classNamesWithTheme(theme).root} ${
              item === selectedFilter ? classNamesWithTheme(theme).selected : ""
            } py-2 px-1 me-2`}
            key={index}
            onClick={() => (MWA ? handleSelect(item) : true)}
          >
            {item}
          </div>
        ))}
      </div>
      <div className={`py-2 w-25 text-end`}>
        <Icon
          className={classNamesWithTheme(theme).refreshBtn}
          onClick={() => (MWA ? handleGetDocuments() : true)}
          iconName="Refresh"
        />
      </div>
    </div>
  );
}
