import { Nav, Stack, TextField, DefaultButton, IconButton } from "office-ui-fabric-react";
import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../context/GlobalContext";
import NmDropdown from "../components/NmDropdown";
import NmFilterTabs from "../components/NmFilterTabs";
import NmList from "../components/NmList";
import {
  iconName,
  navStyles,
  propertiesButtonIcon,
  searchBoxIcon,
  searchBoxStyle,
  openWithProperties,
  open,
  openWithTheme,
  openWithMWA,
} from "../style/open.style";
import { useDialogState } from "../hooks/use-dialog-state";
import NmAdvancedFilter from "../components/NmAdvancedFilter";
import { global, serviceDropdownStyles, searchServiceDropdownStyles } from "../style/global.style";
import DocumentDetail from "../layouts/DocumentDetail";
import { getService } from "../requests/api-requests/services.request";
import { getDocuments, getRecentDocuments } from "../requests/api-requests/documents.request";
import { getCheckOut } from "../requests/api-requests/checkout.request";
import { getClients, getRecentClients } from "../requests/api-requests/clients.request";
import { getMatters, getRecentMatters } from "../requests/api-requests/matters.request";
import { getMunderecats, getRecentMunderecats } from "../requests/api-requests/munderecats.request";
import { getContracts, getFavoriteContracts, getRecentContracts } from "../requests/api-requests/contracts.request";
import { getCases, getFavoriteCases, getRecentCases } from "../requests/api-requests/cases.request";
import { findIcon } from "../utils/findIcon";
import { filterTabList, MWAList, sideBarList, sortList } from "../constants";
import NmBreadcrumb from "../components/NmBreadcrumb";
import { LoadingContext } from "../context/LoadingContext";
import { sendMessageToTaskpane } from "../utils/sendMessageToTaskpane";
import { getMuameles, getRecentMuameles } from "../requests/api-requests/muameles.request";
import { useFormik } from "formik";
import { getFileDocTypes, getFileFromTo, getFileProperties } from "../requests/api-requests/files.request";
import { useFetch } from "../hooks/use-fetch";
import { getSearchResults } from "../requests/api-requests/search.request";
import { getRecentStages, getStages } from "../requests/api-requests/stages.request";
import NmSort from "../components/NmSort";
import NmNavigationButtons from "../components/NmNavigationButtons";
import { TaskpaneHeader } from "../components/TaskpaneHeader";
import { getFile } from "../requests/api-requests/files.request";
import { openWordFile } from "../utils/openWord";
import { checkFooter } from "../utils/insertFooter";
import { MessageContext } from "../context/MessageContext";
import { saveDocument } from "../utils/saveDocument";
import OpenCheck from "../components/OpenCheck";
import { checkDocumentBody } from "../utils/controls";

export default function Open() {
  const { theme, setTheme, setPage, setSelectedDocumentFromContext, isOriginal } = useContext(GlobalContext);
  const [opened, dialogToggle] = useDialogState();
  const { showLoading, hideLoading, setLoading } = useContext(LoadingContext);
  const { openMessage } = useContext(MessageContext);

  const [MWA, setMWA] = useState("");
  const [properties, setProperties] = useState(false);
  const [selectedLeftPanelItem, setSelectedLeftPanelItem] = useState(null);
  const [menuList, setMenuList] = useState([]);
  const [selectedDocumentFile, setSelectedDocumentFile] = useState("");
  const [selectButtonDisableStatus, setSelectButtonDisableStatus] = useState(true);
  const [selectedFilterTab, setSelectedFilterTab] = useState("");
  const [filterTabs, setFilterTabs] = useState([]);
  const [leftPanel, setLeftPanel] = useState([]);
  const [listData, setListData] = useState([]);
  const [payload, setPayload] = useState({
    page: 1,
    limit: 20,
    sort_type: "",
    sort_by: "",
  });
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [fileFromTo, setFileFromTo] = useState([]);
  const [docType, setDocType] = useState([]);
  const [isOpenList, setIsOpenList] = useState(true);
  const [isWordOnlineComplate, setIsWordOnlineComplate] = useState(false);
  const [dialogIsOpen,  setDialogIsOpen] = useState(false);

  let params = new URL(document.location).searchParams;
  let page = params.get("page");

  const [selectedDocumentFileProperties] = useFetch(
    () => (selectedDocumentFile?.doi ? getFileProperties({ doi: selectedDocumentFile.doi }) : () => {}),
    {},
    { setLoading, reloadDeps: [selectedDocumentFile], deps: [selectedDocumentFile] }
  );

  const handleGetServices = async (service) => {
    showLoading();
    await getService(
      { service_name: service.text?.toLowerCase(), save_as: false },
      { entity: page, module: "services", action: "view" }
    ).then((data) => {
      setLeftPanel(data);
      const output = data.map((item, index) => {
        return { name: item.title, key: index, icon: findIcon(item.title.toLowerCase()) };
      });
      setMenuList([
        {
          links: output,
        },
      ]);
      setSelectedLeftPanelItem(output.find((item) => item.name.toLowerCase().includes(sideBarList.documents)));
    });
    hideLoading();
  };

  const handleGetDocuments = async () => {
    showLoading();
    setProperties(false);
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentDocuments(payload, { entity: page, module: "document", action: "view" }).then((data) => {
        setListData(data);
      });
    } else if (selectedFilterTab.toLocaleLowerCase() === filterTabList.checkedOut.toLocaleLowerCase()) {
      await getCheckOut(payload, { entity: page, module: "document", action: "view" }).then((data) => {
        setListData(data);
      });
    } else {
      await getDocuments(payload, { entity: page, module: "document", action: "view" }).then((data) => {
        setListData(data);
      });
    }
    hideLoading();
  };

  const handleGetClients = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentClients(payload, { entity: page, module: "clients", action: "view" }).then((data) => {
        setListData(data);
      });
    } else {
      await getClients(payload, { entity: page, module: "clients", action: "view" }).then((data) => {
        setListData(data);
      });
    }
    hideLoading();
  };

  const handleGetMatters = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentMatters(payload, { entity: page, module: "matters", action: "view" }).then((data) => {
        setListData(data);
      });
    } else {
      await getMatters(payload, { entity: page, module: "matters", action: "view" }).then((data) => {
        setListData(data);
      });
    }
    hideLoading();
  };

  const handleGetMunderecats = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentMunderecats(payload, { entity: page, module: "munderecats", action: "view" }).then((data) => {
        setListData(data);
      });
    } else {
      await getMunderecats(payload, { entity: page, module: "munderecats", action: "view" }).then((data) => {
        setListData(data);
      });
    }
    hideLoading();
  };

  const handleGetContracts = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentContracts(payload, { entity: page, module: "contracts", action: "view" }).then((data) => {
        setListData(data);
      });
    } else if (selectedFilterTab === filterTabList.myFavorites) {
      await getFavoriteContracts(payload, { entity: page, module: "contracts", action: "view" }).then((data) => {
        setListData(data);
      });
    } else {
      await getContracts(payload, { entity: page, module: "contracts", action: "view" }).then((data) => {
        setListData(data);
      });
    }
    hideLoading();
  };

  const handleGetCases = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentCases(payload, { entity: page, module: "cases", action: "view" }).then((data) => {
        setListData(data);
      });
    } else if (selectedFilterTab === filterTabList.myFavorites) {
      await getFavoriteCases(payload, { entity: page, module: "cases", action: "view" }).then((data) => {
        setListData(data);
      });
    } else {
      await getCases(payload, { entity: page, module: "cases", action: "view" }).then((data) => {
        setListData(data);
      });
    }
    hideLoading();
  };

  const handleGetMuameles = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentMuameles(payload, { entity: page, module: "muameles", action: "view" }).then((data) => {
        setListData(data);
      });
    } else {
      await getMuameles(payload, { entity: page, module: "muameles", action: "view" }).then((data) => {
        setListData(data);
      });
    }
    hideLoading();
  };

  const handleGetStages = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentStages(payload, { entity: page, module: "stages", action: "view" }).then((data) => {
        setListData(data);
      });
    } else {
      await getStages(payload, { entity: page, module: "stages", action: "view" }).then((data) => {
        setListData(data);
      });
    }
    hideLoading();
  };

  const handleSelectFilterItem = (item) => {
    setSelectedFilterTab(item);
  };

  const toggleProperties = () => {
    if (!selectedDocumentFile) return;
    setProperties(!properties);
    if (fileFromTo === []) {
      let outputForFileFromTo = [];
      getFileFromTo().then((response) => {
        response.forEach((element) => {
          outputForFileFromTo.push({ key: element.id, text: element.name.trim() });
        });
        setFileFromTo(outputForFileFromTo);
      });
      let outputForDocTypes = [];
      getFileDocTypes().then((response) => {
        response.forEach((element) => {
          outputForDocTypes.push({ key: element.id, text: element.name.trim() });
        });
        setDocType(outputForDocTypes);
      });
    }
  };

  const handleSelectMenuItem = (e, item) => {
    setSelectedLeftPanelItem(item);
    setSelectedFilterTab(filterTabs[0]);
    setBreadcrumb([]);
    setListData(null);
    setDefaultPayload();
  };

  const handleChangeMWA = (e, item) => {
    setListData(null);
    setMWA(item);
    setTheme(item.key - 1);
    setSelectedFilterTab(filterTabList.recent);
    setBreadcrumb([]);
    setPayload((prevState) => ({
      ...prevState,
      service_name: item.text?.toLowerCase(),
    }));
    handleGetServices(item);
  };

  const handleChangeSortBy = (e, item) => {
    setPayload((prevState) => ({
      ...prevState,
      sort_by: item.key,
    }));
  };

  const handleChangeSortType = () => {
    if (payload.sort_type === "desc") {
      setPayload((prevState) => ({
        ...prevState,
        sort_type: "asc",
      }));
      return;
    }
    setPayload((prevState) => ({
      ...prevState,
      sort_type: "desc",
    }));
  };

  const setDefaultPayload = () => {
    setPayload({
      page: 1,
      limit: 20,
      service_name: MWA.text?.toLowerCase(),
      sort_type: "",
      sort_by: "",
    });
  };

  const checkIsLock = (lockStatus, owner, extension) => {
    if (extension.toLowerCase() != "docx") {
      return true;
    }
    const user = localStorage.getItem("user") ? localStorage.getItem("user") : "";
    if (lockStatus === "true" && owner.toLowerCase() == user.toLowerCase()) {
      return false;
    } else {
      return lockStatus === "true" ? true : false;
    }
  };

  const handleSelectListItem = (item) => {
    if (item.check_status === "false") {
      setIsOpenList(false);
    }
    const isSelectedItem = (leftPanelItem) => selectedLeftPanelItem.name.toLowerCase().includes(leftPanelItem);
    if (!isSelectedItem(sideBarList.documents)) {
      setSelectedLeftPanelItem(menuList[0]?.links[selectedLeftPanelItem.key + 1]);
      setSelectedFilterTab(filterTabList.all);
      setListData(null);
      setBreadcrumb((prevState) => [
        ...prevState,
        {
          ...selectedLeftPanelItem,
          text: item.name || item.title,
          key: selectedLeftPanelItem.key,
          onClick: handleSelectMenuItem,
        },
      ]);
    }
    if (
      (page === "saveAsNewVersion" || page === "combine" || page === "compare") &&
      isSelectedItem(sideBarList.documents)
    ) {
      setProperties(true);
      setSelectedDocumentFile(item);
      setSelectButtonDisableStatus(false);
      setIsOpenList(false);
    }
    switch (true) {
      case isSelectedItem(sideBarList.documents):
        if (!checkIsLock(item.check_status, item.checked_user, item.extension)) {
          setSelectedDocumentFile(item);
          setSelectButtonDisableStatus(false);
          setProperties(true);
        }
        break;
      case isSelectedItem(sideBarList.clients):
        setPayload((prevState) => ({
          ...prevState,
          limit: 20,
          client_name: item.name,
        }));
        break;
      case isSelectedItem(sideBarList.matters):
        setPayload((prevState) => ({
          ...prevState,
          limit: 20,
          matter_name: item.name,
        }));
      case isSelectedItem(sideBarList.contracts):
        setPayload((prevState) => ({
          ...prevState,
          limit: 20,
          contract: item?.contract || item?.title,
        }));
      case isSelectedItem(sideBarList.munderecat):
        setPayload((prevState) => ({
          ...prevState,
          limit: 20,
          munderacat: item.title,
        }));
        break;

      default:
        break;
    }
  };

  const openDocument = async () => {
    setDialogIsOpen(false);
    showLoading();
    const payload = {
      service_name: MWA.text,
      path: selectedDocumentFile.path,
      version_info: selectedDocumentFile.version,
    };
    const extraHeaders = { entity: "open_document", module: "document", action: "open" };
    localStorage.setItem("selectedDocumentName", selectedDocumentFile.title);
    localStorage.setItem("selectedDocumentFile", JSON.stringify(selectedDocumentFile));
    getFile(payload, extraHeaders).then(async (data) => {
      if (Office.context.requirements.isSetSupported("WordApiOnline", "1.1")) {
        setIsWordOnlineComplate(true);
      }
      await openWordFile(data.data);
      localStorage.removeItem("payloadFromSaveAs");
      if (!Office.context.requirements.isSetSupported("WordApiOnline", "1.1")) {
        location.href = location.href;
      }
    });
  };

  useEffect(() => {
    if (!selectedLeftPanelItem) return;
    const filterTabsOfSelectedItem = leftPanel[selectedLeftPanelItem.key]?.subItem;
    setFilterTabs(filterTabsOfSelectedItem);

    const isSelectedItem = (leftPanelItem) => selectedLeftPanelItem.name.toLowerCase().includes(leftPanelItem);
    switch (true) {
      case isSelectedItem(sideBarList.documents):
        handleGetDocuments();
        break;
      case isSelectedItem(sideBarList.clients):
        handleGetClients();
        break;
      case isSelectedItem(sideBarList.matters):
        handleGetMatters();
        break;
      case isSelectedItem(sideBarList.munderecat):
        handleGetMunderecats();
        break;
      case isSelectedItem(sideBarList.contracts):
        handleGetContracts();
        break;
      case isSelectedItem(sideBarList.cases):
        handleGetCases();
        break;
      case isSelectedItem(sideBarList.muamele):
        handleGetMuameles();
        break;
      case isSelectedItem(sideBarList.stages):
        handleGetStages();
        break;
      default:
        setListData(null);
        break;
    }
  }, [selectedLeftPanelItem, selectedFilterTab, payload]);

  const openFileForm = useFormik({
    initialValues: {
      version_info: "",
      version_comment: "",
      doc_subject: "",
      doc_type: "",
      from: "",
      to: "",
      doc_date: "",
      doc_name: "",
    },
    onSubmit: async (data) => {
      if (page == "saveAsNewVersion") {
        const { doi } = selectedDocumentFile;
        setSelectButtonDisableStatus(true);
        showLoading();
        const docDetails = await getFileProperties({ doi });
        const payload = {
          client_name: docDetails.client,
          version_info: docDetails.version,
          version_comment: docDetails.versionNote,
          doc_subject: docDetails.docSubject,
          doc_type: docDetails.docType,
          from: docDetails.from,
          to: docDetails.to,
          doc_date: docDetails.docDate,
          doc_name: docDetails.docName,
          service_name: docDetails.service_name,
          path: docDetails.path,
          file_path: docDetails.path,
          file_name: docDetails.docName,
        };

        localStorage.setItem("selectedDocumentName", docDetails.docName);
        localStorage.setItem("payloadFromSaveAs", JSON.stringify(payload));
        const extraHeaders = { entity: "save_as_new_version", module: "document", action: "add" };
        await saveDocument(payload, "saveAsNewVersion", null, extraHeaders);
        openMessage("success", "Document Successfully Saved.");
        localStorage.setItem("saveStatus", "true");
        location.href = location.href;
      }
    },
  });

  const advancedFilterForm = useFormik({
    initialValues: {
      client_name: "",
      matter_name: "",
      fcc_name: "",
      page: 1,
      limit: 9999,
      search_category: "",
      search_text: "",
      service_name: "",
    },
    onSubmit: async (data) => {
      showLoading();
      let payload = {};
      switch (MWA.text) {
        case "Mahfuz":
          let splitItem = selectedLeftPanelItem.name.split(" ");
          payload = {
            ...data,
            service_name: MWA.text,
            search_category: splitItem[0],
            folder_name: data.fcc_name || "",
          };
          break;
        case "Mukavele":
          payload = {
            ...data,
            service_name: MWA.text,
            search_category: selectedLeftPanelItem.name,
            contract_name: data.fcc_name || "",
          };
          break;
        case "Muhakemat":
          payload = {
            ...data,
            service_name: MWA.text,
            search_category: selectedLeftPanelItem.name,
            case_name: data.fcc_name || "",
          };
          break;
        case "Muamelat":
          payload = { ...data, service_name: MWA.text, search_category: selectedLeftPanelItem.name };
          break;
        default:
          payload = data;
          break;
      }
      delete payload.fcc_name;
      await getSearchResults(payload, { entity: page, module: "search_box", action: "search" }).then((data) =>
        setListData(data)
      );
      advancedFilterForm.handleReset();
      hideLoading();
    },
  });

  useEffect(() => {
    if (!selectedDocumentFileProperties) return;
    const { version, versionNote, from, to, docSubject, docType, docName } = selectedDocumentFileProperties;
    openFileForm.setValues({
      version_info: version || "",
      version_comment: versionNote || "",
      doc_subject: docSubject || "",
      doc_type: docType || "",
      from: from || "",
      to: to || "",
      doc_date: new Date() || "",
      doc_name: docName || "",
    });
  }, [selectedDocumentFileProperties]);

  const documentPropertiesSchema = [
    {
      type: "version",
      label: "Version",
      isWidthFull: true,
      name: "version_info",
      underline: true,
      disabled: true,
    },
    {
      type: "targetProperties",
      label: "Target Properties",
      isWidthFull: true,
      data: selectedDocumentFileProperties,
      underline: true,
    },
    {
      type: "input",
      label: "Doc Name",
      disabled: true,
      isWidthFull: true,
      name: "doc_name",
      underline: true,
      disabled: true,
    },
    {
      label: "Doc Date",
      type: "date",
      value: new Date(),
      name: "doc_date",
      isWidthFull: true,
      disabled: true,
    },
    {
      type: "searchableDropdown",
      label: "Doc Type",
      isWidthFull: true,
      placeholder: "Select",
      name: "doc_type",
      disabled: true,
      handleGetData: (payload) => getFileDocTypes(payload),
    },
    {
      type: "textarea",
      label: "Doc Subject",
      name: "doc_subject",
      disabled: page && "openFile",
      isWidthFull: true,
    },
    {
      type: "searchableDropdown",
      label: "From",
      isWidthFull: true,
      placeholder: "Select",
      name: "from",
      disabled: true,
      handleGetData: (payload) => getFileFromTo(payload),
    },
    {
      type: "searchableDropdown",
      label: "To",
      isWidthFull: true,
      placeholder: "Select",
      name: "to",
      disabled: true,
      handleGetData: (payload) => getFileFromTo(payload),
    },
    {
      type: "textarea",
      label: "Version Comment",
      name: "version_comment",
      isWidthFull: true,
      disabled: true,
    },
  ];

  const handleSelectDocumentForCompareCombine = (page) => {
    setPage(page);
    setSelectedDocumentFromContext({ selectedDocumentFile, isOriginal });
  };

  const handleSelect = () => {
    if (page === "openFile") {
      checkDocumentBody().then((doc) => {
        const docIsSaved = localStorage.getItem("payloadFromSaveAs");
        if (docIsSaved === null && doc.items.length > 0) {
          setDialogIsOpen(true);
        } else {          
          openDocument();
        }
      });
    }
    if (page === "compare") {
      return handleSelectDocumentForCompareCombine("compare");
    }
    if (page === "combine") {
      return handleSelectDocumentForCompareCombine("combine");
    }
    openFileForm.handleSubmit();
  };

  const handleSubmitForSearch = (e) => {
    if (e.key !== "Enter") return;
    advancedFilterForm.handleSubmit();
  };

  const selectedVersiyon = (versiyon) => {
    const payloadOpenDoc = selectedDocumentFile;
    payloadOpenDoc.version_info = versiyon;
    setSelectedDocumentFile(payloadOpenDoc);
  };

  const handleBackClick = () => {
    setIsOpenList(true);
  };
  const pageTitle = {
    saveAsNewVersion: "Save As New Version",
    openFile: "Open Document",
    combine: "Combine",
    compare: "Compare",
  };

  return (
    <div>
      <OpenCheck isOpen={dialogIsOpen} onCancel={() => setDialogIsOpen(false)} onContinue={() => openDocument()}/>
      <TaskpaneHeader title={pageTitle[page]} handleBackClick={handleBackClick} showBackButton={!isOpenList} />
      {isWordOnlineComplate ? (
        <div>
          <div className="text-center mt-5 mx-3">
            <p>
              Please refresh the page after the loading is complete. <br />
              <strong>If you do not refresh the page, the document will not be displayed.</strong>
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ display: isOpenList ? "" : "none" }}>
            <div className={open.openDocCategoriesContainer}>
              <NmDropdown
                data={MWAList}
                expandIcon="ChevronDownSmall"
                placeholder="None Selected"
                placeholderIcon="Waffle"
                handleChange={handleChangeMWA}
                selectedKey={MWA.key}
                styles={serviceDropdownStyles}
              />
            </div>
            <div>
              <div className={`${open.topMenuStyles} d-flex justify-content-start`}>
                <div className={`${open.searchDdlContainer} w-auto`}>
                  <NmDropdown
                    data={menuList[0]?.links?.map((item) => {
                      return { ...item, text: item.name };
                    })}
                    dropdownWidth={40}
                    expandIcon="none"
                    placeholderIcon="ViewAll2"
                    placeholder="all"
                    selectedKey={selectedLeftPanelItem?.key}
                    handleChange={handleSelectMenuItem}
                    styles={searchServiceDropdownStyles}
                    disabled={!MWA}
                  />
                </div>
                <div className="w-75">
                  <TextField
                    styles={searchBoxStyle(properties, theme, MWA)}
                    placeholder="search with in all documents, folders, matters, clients"
                    iconProps={searchBoxIcon}
                    disabled={!MWA}
                    onChange={advancedFilterForm.handleChange}
                    name={"search_text"}
                    value={advancedFilterForm.values.search_text}
                    onKeyPress={handleSubmitForSearch}
                  />
                </div>
                <div className="ms-2">
                  <IconButton
                    iconProps={iconName}
                    className={openWithMWA(theme, MWA).searchButtonStyles}
                    onClick={() => dialogToggle()}
                    disabled={!MWA}
                  />
                </div>
              </div>
            </div>
            <div className={open.openDocCategoriesContainer}>
              <NmDropdown
                data={menuList[0]?.links?.map((item) => {
                  return { ...item, text: item.name };
                })}
                dropdownWidth={40}
                expandIcon="ChevronDownSmall"
                placeholderIcon="TextDocument"
                placeholder="all"
                selectedKey={selectedLeftPanelItem?.key}
                handleChange={handleSelectMenuItem}
                styles={searchServiceDropdownStyles}
                disabled={!MWA}
              />
            </div>
            <div>
              <NmFilterTabs
                data={filterTabs}
                selectedFilter={selectedFilterTab}
                handleSelect={handleSelectFilterItem}
                handleGetDocuments={handleGetDocuments}
                MWA={MWA}
              />
            </div>
            <div>
              <div className={`${open.sortingContainer} d-flex justify-content-between pb-2`}>
                <div className="pt-2 ps-2">
                  <span className={open.listCount}>{listData?.data?.length}</span> Records
                </div>
                <div>
                  <NmSort
                    sortList={sortList}
                    handleChangeSortBy={handleChangeSortBy}
                    payload={payload}
                    handleChangeSortType={handleChangeSortType}
                    disabled={!MWA}
                  />
                </div>
              </div>
            </div>
            <div className="pb-5">
              <Stack className={open.tableListStyles}>
                <NmList
                  data={listData}
                  selectedLeftPanelItem={selectedLeftPanelItem?.name?.toLowerCase()}
                  handleSelect={handleSelectListItem}
                  selectedItem={selectedDocumentFile}
                  setPayload={setPayload}
                  page={page}
                />
              </Stack>
            </div>
          </div>
          <div className={`${open.selectButtonContainer} w-100 text-end py-2 px-4`}>
            <DefaultButton
              text="Select"
              className={openWithMWA(theme, MWA).buttonStyles}
              onClick={() => handleSelect()}
              disabled={selectButtonDisableStatus}
            />
          </div>
          <div>
            <div
              className={`${global.p0} ${(properties ? "ms-sm4" : "", openWithProperties(properties).propertiesStyle)}`}
            >
              <DocumentDetail
                isOpenDocument={page === "openFile" || page == "saveAsNewVersion" || page == "compare" || page == "combine"}
                schema={documentPropertiesSchema}
                form={openFileForm}
                selectedListItem={selectedDocumentFileProperties}
                selectedVersiyon={selectedVersiyon}
              />
            </div>
          </div>
          <div>
            <NmAdvancedFilter
              opened={opened}
              formik={advancedFilterForm}
              dialogToggle={dialogToggle}
              theme={theme}
              MWA={MWA}
              handleSubmit={advancedFilterForm.handleSubmit}
              payload={payload}
            />
          </div>
        </div>
      )}
    </div>   
  );
}
