import { Stack, StackItem, Image, ImageFit } from "office-ui-fabric-react";
import React from "react";
import { targetProperties } from "../style/nmTargetProperties.style";

export default function NmTargetProperties({ data, label, ...props }) {
  return (
    <Stack>
      <StackItem className={targetProperties.label}>{label}</StackItem>
      <Stack horizontal gap={8}>
        <Image
          src="https://cdn.icon-icons.com/icons2/2397/PNG/512/microsoft_office_word_logo_icon_145724.png"
          width={15}
          height={18}
          imageFit={ImageFit.cover}
        />
        <StackItem className={targetProperties.title}>{data?.name}</StackItem>
      </Stack>
      <Stack className={targetProperties.subProperties}>
        <StackItem className={targetProperties.item}>
          {data?.fileid} {data?.size} {data?.version}
        </StackItem>
        <StackItem className={targetProperties.item}>
          <span>Modified By:</span> {data?.modifiedBy}
        </StackItem>
        <StackItem className={targetProperties.item}>
          <span>Date Modified:</span> {data?.modifiedDate}
        </StackItem>
        <StackItem className={targetProperties.item}>
          <span>Create By:</span> {data?.owner}
        </StackItem>
        <StackItem className={targetProperties.item}>
          <span>Created Date:</span> {data?.date}
        </StackItem>
      </Stack>
    </Stack>
  );
}
