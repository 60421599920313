import { PrimaryButton } from "office-ui-fabric-react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { NmChoiceGroup } from "../components/NmChoiceGroup";
import NmInfoTable from "../components/NmInfoTable";
import { getFileProperties } from "../requests/api-requests/files.request";
import { global } from "../style/global.style";
import { sendMessageToTaskpane } from "../utils/sendMessageToTaskpane";
import { LoadingContext } from "../context/LoadingContext";
import { GlobalContext } from "../context/GlobalContext";
import { TaskpaneHeader } from "../components/TaskpaneHeader";
import { controlPagePermissions } from "../utils/controls";

const documentOptions = [
  { key: "saveAsNewVersionPdf", text: "New Version" },
  { key: "saveAsNewDocumentPdf", text: "New Document" },
];

export default function SaveAsPdf() {
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [documentOption, setDocumentOption] = useState(null);
  const { setPage } = useContext(GlobalContext);

  useEffect(async () => {
    await controlPagePermissions();
  }, []);
  

  const wordContent = localStorage.getItem("selectedDocumentFile");
  const params = JSON.parse(wordContent);
  let doi = params ? params.doi : null;

  const saveAsPdfForm = useFormik({
    initialValues: {
      version_info: "",
      version_note: "",
      doc_subject: "",
      doc_type: "",
      from: "",
      to: "",
      doc_date: "",
      doc_name: "",
      file_path: "",
      file_name: "",
      version_note: "",
      client_name: "",
    },
    onSubmit: async (data) => {
      setPage(documentOption);
    },
  });

  useEffect(() => {
    showLoading();
    getFileProperties({ doi }).then((data) => {
      const { version, client, versionNote, from, to, docSubject, docType, docName, service, path } = data;

      saveAsPdfForm.setValues({
        version_info: version || "",
        version_note: versionNote || "",
        doc_subject: docSubject || "",
        doc_type: docType || "",
        from: from || "",
        to: to || "",
        doc_date: new Date() || "",
        doc_name: docName || "",
        service_name: service || "",
        file_path: path || "",
        file_name: path || "",
        path: path || "",
        client_name: client || "",
      });
    });
    hideLoading();
  }, []);

  const infoTableData = [
    {
      name: "DOI",
      value: doi,
    },
    {
      name: "Version",
      value: saveAsPdfForm.values.version_info,
    },
    {
      name: "Description",
      value: saveAsPdfForm.values.doc_subject,
    },
  ];

  const handleChangeDocumentOption = (name, value) => {
    setDocumentOption(value);
  };

  const handleCloseDialog = () => {
    sendMessageToTaskpane({ messageType: "dialogClose" });
  };
  return (
    <div>
      <TaskpaneHeader
        title={"Save As PDF"}
        showBackButton={false}
        headerColor={"#8a97a3"}
      />
      <div className={`px-4 relative ${global.container}`}>
        <div className="row">
          <div className="col">
            <div className={`pt-4 ${global.pb75}`}>
              <div>
                <div className="col">
                  <div className={global.card}>
                    <div className={global.cardLabel}>Document Options</div>
                    <NmChoiceGroup
                      name={"documentOption"}
                      value={documentOption}
                      handleChange={handleChangeDocumentOption}
                      options={documentOptions}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className={global.card}>
                    <div className={global.cardLabel}>Document Information</div>
                    <NmInfoTable data={infoTableData} />
                  </div>
                </div>
              </div>
              <div className={`d-flex align-items-center justify-content-end gap-3 px-3 ${global.footerWithFixed}`}>
                {/* <DefaultButton className={global.secondaryButton} text="Cancel" onClick={() => handleCloseDialog()} /> */}
                <PrimaryButton className={global.primaryButton} text="Ok" onClick={saveAsPdfForm.handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
