import React from "react";
import { global } from "../style/global.style";
import NmDialog from "./NmDialog";
import { DefaultButton } from "office-ui-fabric-react";

export default function ContentCheck({ doi, handleCloseDialog, dialogToggleContentDialog, openedContentDialog, closeCancelButtonStatus=true }) {
  return (
    <NmDialog opened={openedContentDialog} dialogToggle={dialogToggleContentDialog}>
      <div className={global.contentDialog}>
        <div>The content of this document with a DOI of {doi} appears blank, would you like to save it anyway?</div>
        <div className="d-flex gap-3 justify-content-center mt-3">
          <DefaultButton text="Cancel" className={global.secondaryButton} onClick={() => handleCloseDialog()} style={{display: closeCancelButtonStatus ? "none" : "block"}} />
          <DefaultButton text="Okey" className={global.primaryButton} onClick={() => dialogToggleContentDialog()} />
        </div>
      </div>
    </NmDialog>
  );
}
