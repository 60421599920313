import { buttonPermissionList } from "../constants";

export const checkDocumentBody = () => {
  return new Promise(async (resolve, reject) => {
    await Word.run(async (context) => {
      const paragraphs = context.document.body.paragraphs;
      context.load(paragraphs, "text");
      await context.sync();
      resolve(paragraphs);
    });
  });
};

export const controlPagePermissions = () => {
  return new Promise(async (resolve, reject) => {
    const docBody = await checkDocumentBody();
    console.log("ccc", docBody.items.length);
    await contentCheck(docBody);

    let urlParams = new URL(document.location).searchParams;
    let page = urlParams.get("page");
    const popupNameCheck = buttonPermissionList.filter((x) => x.page == page);
    const wordContent = localStorage.getItem("payloadFromSaveAs");
    const wordContentParams = JSON.parse(wordContent);
    if (!wordContentParams && popupNameCheck.length > 0) {
      window.location = `${window.location.origin}/index.html?page=ButtonValidation`;
    }
    if (page == "saveAsNewVersion" && paragraphs.items.length == 0) {
      window.location = `${window.location.origin}/index.html?page=ButtonValidation`;
    }
    resolve("ok");
  });
};

const contentCheck = (paragraphs) => {
  return new Promise((resolve, reject) => {
    let timeOutSecond = 0;
    if (Office.context.requirements.isSetSupported("WordApiOnline", "1.1")) {
      timeOutSecond = 3000;
    }
    setTimeout(() => {
      if (paragraphs.items.length == 0) {
        localStorage.removeItem("selectedDocumentName");
        localStorage.removeItem("selectedDocumentFile");
        localStorage.removeItem("payloadFromSaveAs");
      }
      resolve("ok");
    }, timeOutSecond);
  });
};
