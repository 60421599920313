import { Stack, StackItem } from "office-ui-fabric-react";
import React from "react";
import { advancedFilter } from "../style/nmAdvancedFilter.style";
import NmSavedFilterFormItem from "./NmSavedFilterFormItem";

export default function NmSavedFilterItem({ savedFilterItem }) {
  return (
    <Stack className={advancedFilter.filterItemWrapper}>
      <Stack horizontal horizontalAlign="space-between">
        <StackItem className={advancedFilter.savedFilterItemHeaderTitle}>{savedFilterItem.title}</StackItem>
        <StackItem className={advancedFilter.savedFilterItemHeaderButton}>Apply</StackItem>
      </Stack>
      <Stack className={advancedFilter.filterFormItemWrapper}>
        {savedFilterItem.formItemList.map((item, index) => (
          <NmSavedFilterFormItem formItem={item} key={index} />
        ))}
      </Stack>
    </Stack>
  );
}
