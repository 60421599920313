import { PrimaryButton } from "office-ui-fabric-react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import NmCheckBoxGroup from "../components/NmCheckBoxGroup";
import { NmChoiceGroup } from "../components/NmChoiceGroup";
import NmDocumentSelect from "../components/NmDocumentSelect";
import { GlobalContext } from "../context/GlobalContext";
import { getFileProperties } from "../requests/api-requests/files.request";
import { global } from "../style/global.style";
import { DocumentSelect } from "../style/nmDocumentSelect.style";
import { sendMessageToTaskpane } from "../utils/sendMessageToTaskpane";
import { LoadingContext } from "../context/LoadingContext";
import { TaskpaneHeader } from "../components/TaskpaneHeader";
import { postCombine } from "../requests/api-requests/combineCompare.request";
import { openWordFileNewDocScreen } from "../utils/openWord";
import { controlPagePermissions } from "../utils/controls";

const granularityData = [
  { key: 0, text: "Character level" },
  { key: 1, text: "Word level" },
];

const compareDestinationData = [
  { key: 0, text: "Original document" },
  { key: 1, text: "Revised document" },
  { key: 2, text: "New document" },
];

const checkBoxSchema = [
  {
    label: "Formatting",
    name: "formatting",
  },
  {
    label: "Case changes",
    name: "caseChange",
  },
  {
    label: "Moves",
    name: "moves",
  },
  {
    label: "Comment",
    name: "comment",
  },
  {
    label: "Fields",
    name: "field",
  },
  {
    label: "Textboxes",
    name: "textBox",
  },
  {
    label: "Headers and footers",
    name: "headers",
  },
  {
    label: "Footnotes and endnotes",
    name: "footNotes",
  },
  {
    label: "White space",
    name: "whiteSpace",
  },
  {
    label: "Tables",
    name: "tables",
  },
];

export default function Combine() {
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [disableSelectButtonStatus, setDisableSelectButtonStatus] = useState(false);

  useEffect(async () => {
    await controlPagePermissions();
  }, [])


  const wordContent = localStorage.getItem("selectedDocumentFile");
  const params = JSON.parse(wordContent);
  let doi = params ? params.doi : null;

  const [selectedDocument, setSelectedDocument] = useState(null);

  const {
    setPage,
    selectedDocumentFromContext,
    setIsOriginal,
    combineFormFromContext,
    setCombineFormFromContext,
    isFirst,
    setIsFirst,
  } = useContext(GlobalContext);

  const handleCloseDialog = () => {
    sendMessageToTaskpane({ messageType: "dialogClose" });
  };

  const combineForm = useFormik({
    initialValues: {
      first_file_service_name: "",
      first_file_name: "",
      first_file: "",
      first_file_version_info: "",
      first_client_name: "",
      originalAuthor: "",
      second_file_service_name: "",
      second_file_name: "",
      second_file: "",
      second_file_version_info: "",
      second_client_name: "",
      revisedAuthor: "",
      granularity: 0,
      compareDestination: 0,
      formatting: false,
      caseChange: false,
      whiteSpace: false,
      tables: false,
      headers: false,
      footNotes: false,
      textBox: false,
      field: false,
      comment: false,
      moves: false,
    },

    onSubmit: async (data) => {
      delete data.first_file_name;
      delete data.second_file_name;
      if (data.second_file && data.first_file) {
        showLoading();
        setDisableSelectButtonStatus(true);
        const extraHeaders = { entity: "compare_and_combine", module: "combine", action: "combine" };
        postCombine(data, extraHeaders).then(async (doc) => {  
          await openWordFileNewDocScreen(doc.data.data);
          location.href = location.href;
        });
      }
    },
  });

  useEffect(() => {
    if (isFirst === true && doi) {
      getFileProperties({ doi }).then((data) => {
        setSelectedDocument(data);
        setIsFirst(false);
      });
    }

    if (!selectedDocumentFromContext.selectedDocumentFile) return;

    const { version, path, service_name, title, owner, client } = selectedDocumentFromContext.selectedDocumentFile;

    if (selectedDocumentFromContext.isOriginal === true) {
      combineForm.setValues({
        ...combineFormFromContext,
        first_file_version_info: version || "",
        first_file: path || "",
        first_file_service_name: service_name || "",
        first_file_name: title || "",
        first_client_name: client || "",
        originalAuthor: owner || "",
      });
    } else {
      combineForm.setValues({
        ...combineFormFromContext,
        second_file_version_info: version || "",
        second_file: path || "",
        second_file_service_name: service_name || "",
        second_file_name: title || "",
        second_client_name: client || "",
        revisedAuthor: owner || "",
      });
    }
  }, []);

  useEffect(() => {
    if (!selectedDocument) return;
    const { client, path, version, docName, service_name, owner } = selectedDocument;

    combineForm.setValues({
      ...combineForm.values,
      first_file_version_info: version || "",
      first_file_service_name: service_name || "",
      first_file: path || "",
      first_file_name: docName || "",
      originalAuthor: owner || "",
      first_client_name: client || "",
    });
  }, [selectedDocument]);

  const redirectOpenPage = (isOriginal) => {
    setIsOriginal(isOriginal);
    setCombineFormFromContext(combineForm.values);
    setPage("openFile");
  };

  const changesDocuments = () => {
    const {
      first_file_version_info,
      first_file_service_name,
      first_file,
      first_file_name,
      first_client_name,
      originalAuthor,
      second_file,
      second_file_name,
      second_client_name,
      revisedAuthor,
      second_file_service_name,
      second_file_version_info,
    } = combineForm.values;

    const copyOriginalDocument = {
      first_file_version_info,
      first_file_service_name,
      first_file,
      first_file_name,
      first_client_name,
      originalAuthor,
    };

    combineForm.setValues({
      ...combineForm.values,
      first_file_version_info: second_file_version_info || "",
      first_file_service_name: second_file_service_name || "",
      first_file: second_file || "",
      first_client_name: second_client_name || "",
      first_file_name: second_file_name || "",
      originalAuthor: revisedAuthor || "",
      second_file_version_info: copyOriginalDocument.first_file_version_info || "",
      second_file_service_name: copyOriginalDocument.first_file_service_name || "",
      second_file: copyOriginalDocument.first_file || "",
      second_file_name: copyOriginalDocument.first_file_name || "",
      revisedAuthor: copyOriginalDocument.originalAuthor || "",
    });
  };

  return (
    <div style={{ paddingBottom: "70px" }}>
      <TaskpaneHeader title={"Combine"} showBackButton={false} headerColor={"#8a97a3"} />
      <div className="px-3">
        <div className="mt-3">
          <NmDocumentSelect
            value={combineForm.values.first_file_name}
            owner={combineForm.values.originalAuthor}
            onClickButton={() => redirectOpenPage(true)}
            name={"originalAuthor"}
            onChangeForOwner={combineForm.handleChange}
            label="Original Document"
          />
        </div>
        <div>
          <NmDocumentSelect
            value={combineForm.values.second_file_name}
            owner={combineForm.values.revisedAuthor}
            onClickButton={() => redirectOpenPage(false)}
            name={"revisedAuthor"}
            onChangeForOwner={combineForm.handleChange}
            label="Revised Document"
          />
        </div>
        <hr className="hr" />
        <div className={`d-flex flex-column gap-3 ${global.card}`}>
          <div className={global.cardLabel}>Comparison Settings</div>
          <NmCheckBoxGroup schema={checkBoxSchema} form={combineForm.values} handleChange={combineForm.setFieldValue} />
        </div>
        <div className="mt-2">
          <NmChoiceGroup
            options={granularityData}
            name={"granularity"}
            value={combineForm.values.granularity}
            handleChange={combineForm.setFieldValue}
            label="Show Changes At:"
          />
        </div>
        <div className="mt-2">
          <NmChoiceGroup
            options={compareDestinationData}
            name={"compareDestination"}
            value={combineForm.values.compareDestination}
            handleChange={combineForm.setFieldValue}
            label="Show Changes In:"
          />
        </div>
      </div>
      <div className={`d-flex align-items-center justify-content-end gap-3 px-3 ${global.footerWithFixed}`}>
        {/* <DefaultButton className={global.secondaryButton} text="Cancel" onClick={() => handleCloseDialog()} /> */}
        <PrimaryButton
          className={global.primaryButton}
          text="Select"
          onClick={combineForm.handleSubmit}
          disabled={disableSelectButtonStatus}
        />
      </div>
    </div>
  );
}
