import React from "react";
import NmDropdown from "./NmDropdown";
import { IconButton } from "office-ui-fabric-react";
import { iconSort } from "../style/open.style";
import { global, sortingDropdownStyles } from "../style/global.style";

export default function NmSort({ sortList, handleChangeSortBy, payload, handleChangeSortType, disabled }) {
  return (
    <div className="d-flex align-items-center">
      Sort By:
      <NmDropdown
        data={sortList}
        dropdownWidth={80}
        expandIcon="ChevronDownSmall"
        placeholder="Default"
        handleChange={handleChangeSortBy}
        selectedKey={payload.sort_by}
        styles={sortingDropdownStyles}
        disabled={disabled}
      />
      <IconButton
        iconProps={iconSort}
        className={global.sortingTypeButtonStyles}
        onClick={() => handleChangeSortType()}
        disabled={disabled}
      />
    </div>
  );
}
