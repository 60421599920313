import { get } from "../instance/axios.instance";
import { ENDPOINTS } from "../../constants";
import { pathWithQueryString } from "../../utils/pathWithQueryString";

export const getClients = (param, extraHeaders) => {
  return new Promise((resolve, reject) => {
    const url = pathWithQueryString(ENDPOINTS.CLIENTS, param);
    get(url, extraHeaders)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getRecentClients = (param, extraHeaders) => {
  return new Promise((resolve, reject) => {
    const url = pathWithQueryString(ENDPOINTS.RECENT_CLIENTS, param);
    get(url, extraHeaders)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
