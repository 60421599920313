import React from "react";
import { infoTable } from "../style/nmInfoTable.style";

function NmInfoTable({ label, data = "" }) {
  return (
    <div className="card">
      {label && <label className={infoTable.label}>{label}</label>}
      <table className={infoTable.root}>
        <tbody>
          {data.map((item, idx) => {
            return (
              <tr key={idx}>
                <td className={infoTable.name}>{item.name}:</td>
                <td className={infoTable.value}>
                  {item.type === "html" ? <div dangerouslySetInnerHTML={{ __html: item.value }} /> : item.value}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default NmInfoTable;
