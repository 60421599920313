import React from "react";
import PropTypes from "prop-types";
import Progress from "./Progress";
import { GlobalContextProvider } from "../context/GlobalContext";
import PageRouter from "./PageRouter";
import { LoadingContextProvider } from "../context/LoadingContext";
import { UserContextProvider } from "../context/UserContext";
import { MessageContextProvider } from "../context/MessageContext";

const App = ({ title, isOfficeInitialized }) => {
  if (!isOfficeInitialized) {
    return (
      <Progress
        title={title}
        logo={require("./../../../assets/logo-filled.png")}
        message="Please sideload your addin to see app body."
      />
    );
  }
  return (
    <LoadingContextProvider>
      <GlobalContextProvider>
        <MessageContextProvider>
          <UserContextProvider>
            <PageRouter />
          </UserContextProvider>
        </MessageContextProvider>
      </GlobalContextProvider>
    </LoadingContextProvider>
  );
};

export default App;

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};
