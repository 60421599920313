import React from "react";
import { Dropdown } from "office-ui-fabric-react";
import { Stack, ResponsiveMode  } from "office-ui-fabric-react";
import { Icon } from "office-ui-fabric-react";
import { global } from "../style/global.style";
import { dropdownStyles } from "../style/nmForm.style";

const iconStyles = { marginRight: "8px" };

const onRenderOption = (option) => {
  return (
    <div className="d-flex ps-1">
      {option.icon && (
        <div className={`${global.dropdownIconWrapper}`}>
          {option.icon && <Icon style={iconStyles} iconName={option.icon} aria-hidden="true" title={option.icon} />}
        </div>
      )}
      {option.image && (
        <div className={`${global.dropdownIconWrapper}`}>
          {option.image && <img src={option.image} alt={option.text} title={option.text} />}
        </div>
      )}
      <span className="ps-1">{option.text}</span>
    </div>
  );
};



export default function NmDropdown({
  styles = dropdownStyles,
  data,
  dropdownWidth = 150,
  expandIcon = "ChevronDownSmall",
  placeholder,
  placeholderIcon,
  onChange,
  selectedKey,
  label,
  name,
  value,
  selectedProp,
  isForm,
  handleChange,
  disabled,
}) {
  const onRenderPlaceholder = () => {
    return (
      <Stack className="dropdownExample-placeholder" horizontal>
        {placeholderIcon && <Icon style={iconStyles} iconName={placeholderIcon} aria-hidden="true" />}
        <span style={{padding: "0px 5px"}}>{placeholder}</span>
      </Stack>
    );
  };

  const onRenderTitle = (options) => {
    const option = options[0];
    return (
      <div className="d-flex ps-1">
        {option.icon && (
          <div className={global.dropdownIconWrapper}>
            {option.icon && <Icon style={iconStyles} iconName={option.icon} aria-hidden="true" title={option.icon} />}
          </div>
        )}
        {option.image && (
          <div className={global.dropdownIconWrapper}>
            {option.image && <img src={option.image} alt={option.text} title={option.text} />}
          </div>
        )}
        <span className="ps-1">{option.text}</span>
      </div>
    );
  };

  const onRenderCaretDown = () => {
    return <i className={`ms-Icon ms-Icon--${expandIcon}`} aria-hidden="true"></i>;
  };

  const _handleChange = (e, newValue) => {
    if (isForm) {
      if (name === "fcc_name" || name === "matter_name") {
        handleChange(newValue);
      } else {
        onChange(name, newValue["key"]);
      }
    } else {
      handleChange(e, newValue);
    }
  };

  return (
    <Dropdown
      onRenderPlaceholder={onRenderPlaceholder}
      onRenderOption={onRenderOption}
      onRenderCaretDown={onRenderCaretDown}
      onRenderTitle={onRenderTitle}
      styles={styles}
      options={data}
      onChange={_handleChange}
      selectedKey={isForm ? value : selectedKey}
      label={label}
      name={name}
      defaultSelectedKey={value}
      style={{ width: "100%", minWidth: dropdownWidth }}
      disabled={disabled}
      responsiveMode={ResponsiveMode.large}
    />
  );
}
