import { mergeStyleSets } from "office-ui-fabric-react";

export const logoutStyle = mergeStyleSets({
  container: {
    height: "100vh",
  },
  userDetails: {
     fontWeight: "bold",
     textAlign: "center",
     span: {
      fontSize: "12px",
      fontWeight: "normal",
     }
  },
  footer: {
    display: "flex",
    color: "#7e7e7e",
    div: {
      fontWeight: "bold",
      color: "#212529",
      paddingLeft:8
    },
  },
  version: {
    color:"#D3D3D3",
    textAlign: "center",
    position: "absolute",
    left: 0,
    bottom: "15px",
    width:"100%",
    fontSize:"10px"
  }
});
