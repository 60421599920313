import { DefaultButton, PrimaryButton } from "office-ui-fabric-react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import NmCheckBoxGroup from "../components/NmCheckBoxGroup";
import { NmChoiceGroup } from "../components/NmChoiceGroup";
import NmDocumentSelect from "../components/NmDocumentSelect";
import { GlobalContext } from "../context/GlobalContext";
import { getFileProperties } from "../requests/api-requests/files.request";
import { global } from "../style/global.style";
import { LoadingContext } from "../context/LoadingContext";
import { TaskpaneHeader } from "../components/TaskpaneHeader";
import { postCompare } from "../requests/api-requests/combineCompare.request";
import { openWordFileNewDocScreen } from "../utils/openWord";
import { controlPagePermissions } from "../utils/controls";

const granularityData = [
  { key: 0, text: "Character level" },
  { key: 1, text: "Word level" },
];

const compareDestinationData = [
  { key: 0, text: "Original document" },
  { key: 1, text: "Revised document" },
  { key: 2, text: "New document" },
];

const checkBoxSchema = [
  {
    label: "Formatting",
    name: "formatting",
  },
  {
    label: "Case changes",
    name: "caseChange",
  },
  {
    label: "Moves",
    name: "moves",
  },
  {
    label: "Comment",
    name: "comment",
  },
  {
    label: "Fields",
    name: "field",
  },
  {
    label: "Textboxes",
    name: "textBox",
  },
  {
    label: "Headers and footers",
    name: "headers",
  },
  {
    label: "Footnotes and endnotes",
    name: "footNotes",
  },
  {
    label: "White space",
    name: "whiteSpace",
  },
  {
    label: "Tables",
    name: "tables",
  },
];

export default function Compare({ docParams = null, cancelEvent }) {
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [disableSelectButtonStatus, setDisableSelectButtonStatus] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedSecondDocument, setSelectedSecondDocument] = useState(null);

  const {
    setPage,
    selectedDocumentFromContext,
    setIsOriginal,
    compareFormFromContext,
    setCompareFormFromContext,
    isFirst,
    setIsFirst,
  } = useContext(GlobalContext);

  useEffect(async () => {
    await controlPagePermissions();
  }, []);

  const wordContent = localStorage.getItem("selectedDocumentFile");
  const params = JSON.parse(wordContent);
  let doi = null;
  if (docParams) {
    setIsFirst(true);
  }
  doi = params ? params.doi : null;

  const compareForm = useFormik({
    initialValues: {
      first_file_service_name: "",
      first_file_name: "",
      first_file: "",
      first_file_version_info: "",
      first_file_owner: "",
      first_client_name: "",
      second_client_name: "",
      second_file_service_name: "",
      second_file_name: "",
      second_file: "",
      second_file_version_info: "",
      revisedAuthor: "",
      granularity: 0,
      compareDestination: 0,
      formatting: false,
      caseChange: false,
      whiteSpace: false,
      tables: false,
      headers: false,
      footNotes: false,
      textBox: false,
      field: false,
      comment: false,
      moves: false,
    },

    onSubmit: async (data) => {
      delete data.first_file_owner;
      delete data.first_file_name;
      delete data.second_file_name;

      if (data.second_file && data.first_file) {
        showLoading();
        setDisableSelectButtonStatus(true);
        const extraHeaders = { entity: "compare_and_combine", module: "compare", action: "compare" };
        postCompare(data, extraHeaders).then(async (doc) => {
          await openWordFileNewDocScreen(doc.data.data);
          if(docParams){
            hideLoading();
            cancelEvent();
          } else {
            location.href = location.href;
          }
        });
      }
    },
  });

  useEffect(() => {
    if (isFirst === true && doi) {
      showLoading();
      getFileProperties({ doi }).then((data) => {
        setSelectedDocument(data);
        setIsFirst(false);
        hideLoading();
      });
      if (docParams) {
        showLoading();
        getFileProperties({ doi }).then((data) => {
          setSelectedSecondDocument(data);
          setIsFirst(false);
          hideLoading();
        });
      }
    }

    if (!selectedDocumentFromContext.selectedDocumentFile) return;

    const { version, path, service_name, client, title, owner } = selectedDocumentFromContext.selectedDocumentFile;
    if (selectedDocumentFromContext.isOriginal === true) {
      compareForm.setValues({
        ...compareFormFromContext,
        first_file_version_info: version || "",
        first_file: path || "",
        first_file_service_name: service_name || "",
        first_file_name: title || "",
        first_file_owner: owner || "",
        first_client_name: client || "",
      });
    } else {
      compareForm.setValues({
        ...compareFormFromContext,
        second_file_version_info: version || "",
        second_file: path || "",
        second_file_service_name: service_name || "",
        second_file_name: title || "",
        second_client_name: client || "",
        revisedAuthor: owner || "",
      });
    }
  }, []);

  useEffect(() => {
    if (!selectedDocument) return;
    const { service, path, version, docName, client, service_name, owner } = selectedDocument;

    compareForm.setValues({
      ...compareForm.values,
      first_file_version_info: version || "",
      first_file_service_name: service_name || "",
      first_file: path || "",
      first_file_name: docName || "",
      first_file_owner: owner || "",
      first_client_name: client || "",
    });
  }, [selectedDocument]);

  useEffect(() => {
    if (!selectedDocument) return;
    const { service, path, version, docName, client, service_name, owner } = selectedSecondDocument;

    compareForm.setValues({
      ...compareForm.values,
      second_file_version_info: version || "",
      second_file_service_name: service_name || "",
      second_file: path || "",
      second_file_name: docName || "",
      revisedAuthor: owner || "",
      second_client_name: client || "",
    });
  }, [selectedSecondDocument]);

  const redirectOpenPage = (isOriginal) => {
    setIsOriginal(isOriginal);
    setCompareFormFromContext(compareForm.values);
    setPage("openFile");
  };

  const changesDocuments = () => {
    const {
      first_file_version_info,
      first_file_service_name,
      first_file,
      first_file_name,
      first_file_owner,
      second_file,
      second_file_name,
      revisedAuthor,
      second_file_service_name,
      second_file_version_info,
    } = compareForm.values;

    const copyOriginalDocument = {
      first_file_version_info,
      first_file_service_name,
      first_file,
      first_file_name,
      first_file_owner,
    };

    compareForm.setValues({
      ...compareForm.values,
      first_file_version_info: second_file_version_info || "",
      first_file_service_name: second_file_service_name || "",
      first_file: second_file || "",
      first_file_name: second_file_name || "",
      first_file_owner: revisedAuthor || "",
      second_file_version_info: copyOriginalDocument.first_file_version_info || "",
      second_file_service_name: copyOriginalDocument.first_file_service_name || "",
      second_file: copyOriginalDocument.first_file || "",
      second_file_name: copyOriginalDocument.first_file_name || "",
      revisedAuthor: copyOriginalDocument.first_file_owner || "",
    });
  };

  return (
    <div style={{ paddingBottom: "70px" }}>
      {docParams === null && <TaskpaneHeader title={"Compare"} showBackButton={false} headerColor={"#8a97a3"} />}
      <div className="px-3">
        <div className="mt-3">
          <NmDocumentSelect
            value={compareForm.values.first_file_name}
            owner={compareForm.values.first_file_owner}
            onClickButton={() => redirectOpenPage(true)}
            name={"first_file_owner"}
            onChangeForOwner={compareForm.handleChange}
            label="Original Document"
            disabled={docParams ? true : false}
          />
        </div>
        <div>
          <NmDocumentSelect
            value={compareForm.values.second_file_name}
            owner={compareForm.values.revisedAuthor}
            onClickButton={() => redirectOpenPage(false)}
            name={"revisedAuthor"}
            onChangeForOwner={compareForm.handleChange}
            label="Revised Document"
            disabled={docParams ? true : false}
          />
        </div>
        <hr className="hr" />
        <div className={`d-flex flex-column gap-3 ${global.card}`}>
          <div className={`${global.cardLabel}`}>Comparison Settings</div>
          <NmCheckBoxGroup
            schema={checkBoxSchema}
            form={compareForm.values}
            handleChange={compareForm.setFieldValue}
            colCount={1}
          />
        </div>
        <div className="mt-2">
          <NmChoiceGroup
            options={granularityData}
            name={"granularity"}
            value={compareForm.values.granularity}
            handleChange={compareForm.setFieldValue}
            label="Show Changes At:"
          />
        </div>
        <div className="mt-2">
          <NmChoiceGroup
            options={compareDestinationData}
            name={"compareDestination"}
            value={compareForm.values.compareDestination}
            handleChange={compareForm.setFieldValue}
            label="Show Changes In:"
          />
        </div>
      </div>
      <div className={`d-flex align-items-center justify-content-end gap-3 px-3 ${global.footerWithFixed}`}>
        {docParams !== null && (
          <DefaultButton className={global.secondaryButton} onClick={() => cancelEvent()} text="Cancel" />
        )}
        <PrimaryButton
          className={global.primaryButton}
          text="Select"
          onClick={compareForm.handleSubmit}
          disabled={disableSelectButtonStatus}
        />
      </div>
    </div>
  );
}
