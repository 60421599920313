import { get } from "../instance/axios.instance";
import { ENDPOINTS } from "../../constants";
import { pathWithQueryString } from "../../utils/pathWithQueryString";

export const getStages = (param, extraHeaders) => {
  return new Promise((resolve, reject) => {
    const url = pathWithQueryString(ENDPOINTS.STAGES, param);
    get(url, extraHeaders)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getRecentStages = (param, extraHeaders) => {
  return new Promise((resolve, reject) => {
    const url = pathWithQueryString(ENDPOINTS.RECENT_STAGES, param);
    get(url, extraHeaders)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
