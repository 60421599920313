import { mergeStyleSets } from "office-ui-fabric-react";

export const targetProperties = mergeStyleSets({
  label: {
    fontSize: 14,
    fontWeight: 600,
    color: "#53646C",
    paddingBottom: 5,
    fontSize: 12,
    fontWeight: 600
  },
  title: {
    color: "#06090F",
    fontWeight: 600,
    fontSize: 13,
  },
  subProperties: {
    borderLeft: "solid 2px #ADB8C0",
    paddingLeft: 8,
    marginLeft: 15,
    marginTop: 8,
  },
  item: {
    color: "#AEB8C1",
    fontSize: 11,
    span: {
      fontWeight: 600,
    },
  },
});
