import { TextField } from "office-ui-fabric-react";
import React from "react";
import { input } from "../style/nmForm.style";

export default function NmTextarea({ label, value, placeholder, onChange, name, errorMessage, disabled }) {
  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      name={name}
      multiline
      autoAdjustHeight
      errorMessage={errorMessage}
      placeholder={placeholder}
      styles={input}
      disabled={disabled}
    />
  );
}
