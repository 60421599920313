import { themeColors } from "../constants";
import { mergeStyleSets } from "office-ui-fabric-react";

export const searchBoxIcon = { iconName: "Search", icon: "pink" };
export const iconName = { iconName: "Filter" };
export const iconSort = { iconName: "Sort" };
export const propertiesButtonIcon = { iconName: "FolderOpen" };
export const prevButtonIcon = { iconName: "Back" };
export const nextButtonIcon = { iconName: "Forward" };
export const refreshButtonIcon = { iconName: "Refresh" };
export const listIcon = { iconName: "Refresh" };

export const open = mergeStyleSets({
  sortingWrapper: {
    padding: "8px 16px",
    borderBottom: "1px solid #ECEDEF",
    backgroundColor: "#F4F5F7",
  },
  listCount: {
    fontWeight: "bold",
  },
  tableWrapperStyles: {
    width: "calc(100% - 185px)",
    position: "relative",
  },
  tableListStyles: {
    height: "100%",
    backgroundColor: "#F4F5F7",
    // borderBottom: "1px solid #ECEDEF",
  },
  topMenuStyles: {
    padding: 16,
    backgroundColor: "rgba(211, 216, 221,0.25)",
    borderBottom: "1px solid #ECEDEF",
  },
  searchDdlContainer: {
    borderRight: "1px solid rgba(211, 216, 221, 0.25)",
  },
  navigationContainerStyles: {
    padding: "0 16px",
    borderBottom: "1px solid #ECEDEF",
  },
  navigationStyles: { width: 185 },
  leftMenuNavStyles: {
    width: 185,
    height: "calc(100vh - 96px)",
    borderRight: "1px solid #ECEDEF",
  },
  actionButtonsStyles: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    borderTop: "1px solid #ECEDEF",
    padding: 15,
    gap: 15,
    backgroundColor: "rgba(211, 216, 221,0.25)",
  },
  containerStyles: { padding: "0px!important" },
  navigateButtonsStyles: {
    backgroundColor: "transparent",
    color: "black",
    "&:hover": { backgroundColor: "none", color: "none" },
    "&:active": { backgroundColor: "none", color: "none", opacity: "0.9" },
  },
  openDocCategoriesContainer: {
    borderBottom: "1px solid rgba(0,0,0,0.20)",
    padding: "5px 0",
  },
  sortingContainer: {
    fontSize: "12px",
    backgroundColor: "rgb(244, 245, 247)",
  },
  selectButtonContainer: {
    borderTop: "1px solid #ECEDEF",
    backgroundColor: "rgb(244, 245, 247)",
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex:1
  },
});

export const openWithTheme = (theme, selectedListItem) => {
  return mergeStyleSets({
    propertiesButtonStyles: {
      backgroundColor: selectedListItem ? themeColors[theme].secondary : "rgba(211, 216, 221,0.2)",
      color: selectedListItem ? "white" : "black",
      borderRadius: 5,
      "&:hover": { backgroundColor: "none", color: "none" },
      "&:active": { backgroundColor: "none", color: "none", opacity: "0.9" },
    },
  });
};

export const openWithMWA = (theme, MWA) => {
  return mergeStyleSets({
    buttonStyles: {
      border: 0,
      borderRadius: 6,
      backgroundColor: MWA ? themeColors[theme].secondary : "#33363C",
      color: "white",
      "&:hover": { backgroundColor: "none", color: "none" },
      "&:active": { backgroundColor: "none", color: "none", opacity: "0.9" },
    },
    searchButtonStyles: {
      borderRadius: 5,    
      backgroundColor: MWA ? themeColors[theme].secondary : "#33363C",
      color: "white",
      "&:hover": { backgroundColor: "none", color: "none" },
      "&:active": { backgroundColor: "none", color: "none", opacity: "0.9" },
    },
  });
};

export const openWithProperties = (properties) => {
  return mergeStyleSets({
    propertiesStyle: {
      display: !properties && "none",
    },
  });
};

export const navStyles = (theme, MWA) => {
  return {
    navItems: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      padding: "16px",
    },
    compositeLink: {
      backgroundColor: "#F4F5F7",
      color: "black",
      "& button:hover, &:not(.is-selected) button:hover .ms-Button-icon": { color: "black" },
      "&.is-selected button": { backgroundColor: MWA ? themeColors[theme].primary : "pink", color: "#fff" },
      "&.is-selected button:hover": {
        backgroundColor: MWA ? themeColors[theme].primary : "#004448",
        color: "#fff!important",
      },
      "&.is-selected:hover .ms-button-icon": { color: "#fff!important" },
      "& .ms-Button-icon": { color: "black" },
      "&.is-selected .ms-Button-icon , &.is-selected:hover .ms-Button-icon": { color: "#fff" },
      "& button:after": { display: "none" },
    },
    link: {
      height: 35,
      borderRadius: 5,
      fontSize: 13,
      fontWeight: 600,
    },
  };
};

export const searchBoxStyle = (properties, theme, MWA) => {
  return {
    root: {
      // width: properties ? 180 : 340,   
    },
    fieldGroup: {
      border: 0,
      marginLeft: 0,
      fontSize: "12px",
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
    },
    icon: { color: MWA ? themeColors[theme].secondary : "#33363C" },
    field:{
      fontSize: "12px",
    },
    title: {
      padding: "0px 7px 0px 8px"
    }

  };
};
