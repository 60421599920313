import { mergeStyleSets } from "office-ui-fabric-react";

export const global = mergeStyleSets({
  container: {
    height: "calc(100vh - 16px)",
  },
  p0: {
    padding: "0px!important",
  },
  pb75: {
    paddingBottom: 75,
  },
  card: {
    borderRadius: 8,
    border: "1px solid #DFE5F0",
    padding: 15,
    position: "relative",
  },
  cardLabel: {
    position: "relative",
    position: "absolute",
    top: "-10px",
    background: "white",
    padding: "0px 8px",
    fontSize: 13,
    fontWeight: "bold",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    right: 24,
  },
  footerWithFixed: {
    backgroundColor: "#D3D8DD",
    padding: "16px 0",
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "100%",
  },
  dropdownIconWrapper: {
    height: 20,
    width: 14,
    "*": {
      width: "100%",
    },
  },
  spinnerOverlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
    left: 0,
    zIndex: 9999,
    "&::before": {
      content: "",
      background: "rgba(0, 0, 0, 0.6)",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999,
    },
  },
  secondaryButton: {
    border: "2px solid #EBEDF0",
    borderRadius: 6,
    "&:hover,&:active": {
      backgroundColor: "none",
    },
  },
  primaryButton: {
    border: 0,
    borderRadius: 6,
    backgroundColor: "#33363C",
    color: "white",
    "&:hover": { backgroundColor: "none", color: "none" },
    "&:active": { backgroundColor: "none", color: "none", opacity: "0.9" },
  },
  versionInputLabel: {
    fontWeight: 600,
    fontSize: 12,
    color: "#53646C",
  },
  documentSelectLabel: {
    color: "#06090F",
    fontSize: 13,
  },
  checkBoxGroup: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
  },
  contentDialog: {
    padding: 16,
  },
  sortingTypeButtonStyles: {
    borderRadius: 5,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "transparent",
    color: "black",
    "&:hover": { backgroundColor: "none", color: "none" },
    "&:active": { backgroundColor: "none", color: "none", opacity: "0.9" },
  }, 
});

export const serviceDropdownStyles = {
  title: {
    border: 0,
  },
  dropdown: { width: "100%", minWidth: 150 },
};

export const sortingDropdownStyles = {
  title: {
    border: 0,
    backgroundColor: "transparent",
    height: 28,
    fontSize: "12px",
    fontWeight: "600"
  },
  dropdown: { width: "100%", minWidth: 150 },
};

export const searchServiceDropdownStyles = {
  title: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    border: 0,
    fontSize: "12px",
  },
  dropdown: { width: "100%", minWidth: 150 },
};

export const choiceGroupStyles = {
  root: {
    // "div[role]": {
    //   display: "grid",
    //   gridTemplateColumns: "1fr 3fr",
    // },
  },
  flexContainer: {
    // display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gap: "16px",
  },
  label: {
    color: "#06090F",
    fontSize: 13,
    fontWeight: 600,
  },
  ".ms-ChoiceField-field": {
    fontSize: 13,
    fontWeight: "normal",
    color: "#06090F",
    marginBottom: "5px"
  },
  ".ms-ChoiceField": {
    margin: 0,
  },
};
