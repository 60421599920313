import { mergeStyleSets } from "office-ui-fabric-react";

export const formWithWidth = (isWidthFull) => {
  return mergeStyleSets({
    formItem: {
      // width: isWidthFull ? "100%" : "calc(50% - 8px)",
      width:"100%"
    },
  });
};

export const form = mergeStyleSets({
  formWrapper: {
    // display: "flex",
    // flexWrap: "wrap",
    // gap: "16px",
    width:"100%",
    padding: "0 15px",
  },
  underline: {
    marginTop: 16,
    height: 1,
    width: "100%",
    backgroundColor: "#ECEDEF",
  },
});

export const input = {
  fieldGroup: {
    border: "1px solid #CDD4D9",
    borderRadius: 5,
  },
  subComponentStyles: {
    label: {
      root: {
        fontWeight: 600,
        fontSize: 12,
        color: "#53646C",
      },
    },
  },
};

export const datePicker = {
  ".ms-TextField-fieldGroup": {
    border: "1px solid #CDD4D9",
    borderRadius: 5,
  },
};

export const dropdownStyles = {
  title: {
    border: "1px solid #CDD4D9",
    borderRadius: 5,
  },
  label: {
    fontWeight: 600,
    fontSize: 12,
    color: "#53646C",
  },
  dropdown: { width: "100%", minWidth: 150 },
};
