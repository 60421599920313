import { IconButton, Image, ImageFit, Stack, StackItem } from "office-ui-fabric-react";
import React from "react";
import { versions } from "../style/versions.style";
import { useState } from 'react';

export default function NmCurrentVersion({ selectedListItem }) {
  return (
    <Stack className={versions.currentVersionContext}>
      <Stack className={versions.currentVersionWrapper}>
        <Stack className={versions.currentVersionInner}>
          <Stack horizontal verticalAlign="end" gap={10}>
            <Image
              src="https://cdn.icon-icons.com/icons2/2397/PNG/512/microsoft_office_word_logo_icon_145724.png"
              width="22px"
              height="100%"
              imageFit={ImageFit.cover}
            />
            <Stack>
              <StackItem className={versions.currentVersionText}>Current Version</StackItem>
              <StackItem className={versions.currentVersionTitle}>{selectedListItem.name}</StackItem>
            </Stack>
          </Stack>
          <Stack horizontal horizontalAlign="space-between">
            
            <StackItem className={versions.currentVersionProperties}>#{selectedListItem.doi}</StackItem>
            <Stack horizontal gap={10}>
              <StackItem className={versions.currentVersionProperties}>{selectedListItem.size}</StackItem>
              <StackItem className={versions.currentVersionProperties}>{selectedListItem.version}</StackItem>
            </Stack>
          </Stack>
          <Stack horizontal>
            <StackItem className={versions.currentVersionProperties}>
              <span className={versions.currentVersionBoldProperties}>Data Modified: </span>
              {selectedListItem.modifiedDate}
            </StackItem>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
