import { insertFooter } from "./insertFooter";
const clearDocument = async () => {
  return await Word.run(async function (context) {
    var sections = context.document.sections;
    sections.load();
    await context.sync();
    // Clear document contents (body, header, footer).
    sections.items.forEach(function (section) {
      // Clear the Body.
      section.body.clear();

      // Clear any Headers.
      section.getHeader("primary").clear();
      section.getHeader("firstPage").clear();
      section.getHeader("evenPages").clear();

      // Clear any Footers.
      section.getFooter("primary").clear();
      section.getFooter("firstPage").clear();
      section.getFooter("evenPages").clear();
    });
  }).catch(function (error) {
    if (error instanceof window.OfficeExtension.Error) {
       console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
  });
};

export const openWordFile = async (documentData) => {
  await clearDocument();  
  return await Word.run(async (context) => {
    const body = context.document.body;
    body.insertFileFromBase64(documentData.base64Data, Word.InsertLocation.start);
    await context.sync();
    await insertFooter(documentData.footer, false);
  });
};

export const openWordFileNewDocScreen = async (documentData) => {
  await Word.run(async (context) => {
    var myNewDoc = context.application.createDocument(documentData);
    try {
      await context.sync();
      //await insertFooter(documentData.footer, false);
      myNewDoc.open(); 
    } catch (myError) {
      console.log("Error", myError.message);
    }
  }).catch(function (myError) {
    console.log("Error", myError.message);
  });
};
