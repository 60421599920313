import React, { useContext, useEffect } from "react";
import { Image, ImageFit, PrimaryButton } from "office-ui-fabric-react";
import { pavStyle } from "../style/pav.style";
import { form } from "../style/nmForm.style";
import { targetProperties } from "../style/nmTargetProperties.style";
import NmForm from "../components/NmForm";
import { useFormik } from "formik";
import { global } from "../style/global.style";
import { getFileDocTypes, getFileFromTo, postSaveProperties } from "../requests/api-requests/files.request";
import { MessageContext } from "../context/MessageContext";
import { checkFooter } from "../utils/insertFooter";
import * as Yup from "yup";
import { LoadingContext } from "../context/LoadingContext";

export default function Properties({ fileProperties }) {
  const { openMessage } = useContext(MessageContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);

  const documentPropertiesSchema = [
    {
      type: "version",
      label: "Version",
      isWidthFull: true,
      name: "version_info",
      underline: true,
    },
    {
      type: "input",
      label: "Doc Name",
      isWidthFull: true,
      name: "doc_name",
      underline: true,
    },
    {
      label: "Doc Date",
      type: "date",
      value: new Date(),
      name: "doc_date",
      isWidthFull: true,
    },
    {
      type: "searchableDropdown",
      label: "Doc Type",
      isWidthFull: true,
      placeholder: "Select",
      name: "doc_type",
      selectedProp: "label",
      handleGetData: (payload) => getFileDocTypes(payload),
    },
    {
      type: "textarea",
      label: "Doc Subject",
      name: "doc_subject",
      isWidthFull: true,
    },
    {
      type: "searchableDropdown",
      label: "From",
      isWidthFull: true,
      placeholder: "Select",
      name: "from",
      selectedProp: "label",
      handleGetData: (payload) => getFileFromTo(payload),
    },
    {
      type: "searchableDropdown",
      label: "To",
      isWidthFull: true,
      placeholder: "Select",
      name: "to",
      selectedProp: "label",
      handleGetData: (payload) => getFileFromTo(payload),
    },
    {
      type: "textarea",
      label: "Version Comment",
      name: "version_note",
      isWidthFull: true,
    },
  ];

  const validationSchema = Yup.object({
    doc_name: Yup.string().required("Required Field"),
    doc_date: Yup.date().required("Required Field"),
    doc_type: Yup.string().required("Required Field"),
    doc_subject: Yup.string().required("Required Field"),
    from: Yup.string().required("Required Field"),
    to: Yup.string().required("Required Field"),
    version_note: Yup.string().required("Required Field"),
  });

  const propertiesForm = useFormik({
    initialValues: {
      version_info: "",
      version_note: "",
      doc_subject: "",
      doc_type: "",
      from: "",
      to: "",
      doc_date: "",
      doc_name: "",
      file_name: "",
      doi: "",
    },
    validationSchema,
    onSubmit: async (data) => {
      showLoading();
      checkFooter(data);
      let payload = { ...data, from: data.from.toString(), to: data.to.toString(), doc_type: data.doc_type.toString() };
      postSaveProperties(payload).then((response) => {
        if (response.error) {
          openMessage("error", response.error);
        } else {
          openMessage("success", response.data);
        }
        hideLoading();
      });
    },
  });

  useEffect(() => {
    if (!fileProperties) return;
    const { version, versionNote, from, to, docSubject, docType, docName, doi, path } = fileProperties;
    propertiesForm.setValues({
      version_info: version || "",
      version_note: versionNote || "",
      doc_subject: docSubject || "",
      doc_type: docType || "",
      from: from || "",
      to: to || "",
      doc_date: new Date() || "",
      doc_name: docName || "",
      file_name: docName || "",
      doi: doi || "",
      path: path,
    });
  }, [fileProperties]);

  return (
    <div className={`relative ${global.pb75}`}>
      <div className="p-3">
        <div className={pavStyle.propertiesDocNameContainer}>
          <div className={pavStyle.propertiesLabel}>Doc Name</div>
          <div className="d-flex gap-2 align-items-center">
            <Image
              src="https://cdn.icon-icons.com/icons2/2397/PNG/512/microsoft_office_word_logo_icon_145724.png"
              className={pavStyle.propertiesDocImage}
              imageFit={ImageFit.contain}
            />
            <div>{fileProperties.docName}</div>
          </div>
        </div>
        <div className={form.underline} />
        <div className="mt-3">
          <div className={pavStyle.propertiesLabel}>Target Properties</div>
          <div className="d-flex align-items-center justify-content-between gap-2 w-100 mt-2">
            <div className={pavStyle.propertiesBox}>#{fileProperties.doi}</div>
            <div className={pavStyle.line} />
            <div className={pavStyle.propertiesBox}>V.{fileProperties.version}</div>
            <div className={pavStyle.line} />
            <div className={pavStyle.propertiesBox}>{fileProperties.size}</div>
          </div>
          <div className={targetProperties.subProperties}>
            <div className={targetProperties.item}>
              <span>Modified By:</span> {fileProperties?.modifiedBy}
            </div>
            <div className={targetProperties.item}>
              <span>Date Modified:</span> {fileProperties?.modifiedDate}
            </div>
            <div className={targetProperties.item}>
              <span>Create By:</span> {fileProperties?.owner}
            </div>
            <div className={targetProperties.item}>
              <span>Created Date:</span> {fileProperties?.date}
            </div>
          </div>
          <div className={`mb-2 ${form.underline}`} />
          <NmForm classNames="px-0" formSchema={documentPropertiesSchema} formik={propertiesForm} />
        </div>
      </div>
      <div className={`d-flex align-items-center justify-content-end gap-3 px-3 ${global.footerWithFixed}`}>
        <PrimaryButton className={global.primaryButton} onClick={propertiesForm.handleSubmit} text="Save" />
      </div>
    </div>
  );
}
