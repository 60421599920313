import { mergeStyleSets } from "office-ui-fabric-react";

export const infoTable = mergeStyleSets({
  root: {
    borderSpacing: "1rem",
  },
  label: {
    fontWeight: "bold",
    fontSize: 13,
  },
  value: {
    wordBreak: "break-all",
    fontWeight: "bold",
    fontSize: 12,
  },
  name: {
    fontWeight: 600,
    fontSize: 13,
  },
});
