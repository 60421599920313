import { Stack, TextField, DefaultButton, IconButton } from "office-ui-fabric-react";
import React, { useContext, useState, useEffect, useRef } from "react";
import { GlobalContext } from "../context/GlobalContext";
import NmDropdown from "../components/NmDropdown";
import NmFilterTabs from "../components/NmFilterTabs";
import NmList from "../components/NmList";
import {
  iconName,
  navStyles,
  propertiesButtonIcon,
  searchBoxIcon,
  searchBoxStyle,
  openWithProperties,
  open,
  openWithTheme,
  openWithMWA,
} from "../style/open.style";
import { useDialogState } from "../hooks/use-dialog-state";
import NmAdvancedFilter from "../components/NmAdvancedFilter";
import { global, serviceDropdownStyles, searchServiceDropdownStyles } from "../style/global.style";
import DocumentDetail from "../layouts/DocumentDetail";
import { getService } from "../requests/api-requests/services.request";
import { getClients, getRecentClients } from "../requests/api-requests/clients.request";
import { getMatters, getRecentMatters } from "../requests/api-requests/matters.request";
import { getMunderecats, getRecentMunderecats } from "../requests/api-requests/munderecats.request";
import { getContracts, getFavoriteContracts, getRecentContracts } from "../requests/api-requests/contracts.request";
import { getCases, getFavoriteCases, getRecentCases } from "../requests/api-requests/cases.request";
import { findIcon } from "../utils/findIcon";
import { filterTabList, MWAList, sideBarList, sortList } from "../constants";
import NmBreadcrumb from "../components/NmBreadcrumb";
import { LoadingContext } from "../context/LoadingContext";
import { getMuameles, getRecentMuameles } from "../requests/api-requests/muameles.request";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getFileDocTypes, getFileFromTo, getFileProperties, getFiles } from "../requests/api-requests/files.request";
import { useFetch } from "../hooks/use-fetch";
import { getSearchResults } from "../requests/api-requests/search.request";
import { sendMessageToTaskpane } from "../utils/sendMessageToTaskpane";
import ContentCheck from "../components/ContentCheck";
import { getFolders } from "../requests/api-requests/folders.request";
import { getRecentStages, getStages } from "../requests/api-requests/stages.request";
import NmSort from "../components/NmSort";
import { TaskpaneHeader } from "../components/TaskpaneHeader";
import { checkFooter } from "../utils/insertFooter";
import { saveDocument } from "../utils/saveDocument";
import { MessageContext } from "../context/MessageContext";
import { controlPagePermissions } from "../utils/controls";


export default function SaveAsNewDocument() {
  const { openMessage } = useContext(MessageContext);
  const { theme, setTheme, page } = useContext(GlobalContext);
  const [opened, dialogToggle] = useDialogState();
  const [openedContentDialog, dialogToggleContentDialog] = useDialogState();
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const prevLeftPanelItem = useRef();
  const isLastLeftPanelItem = useRef();

  const [MWA, setMWA] = useState("");
  const [properties, setProperties] = useState(false);
  const [selectedLeftPanelItem, setSelectedLeftPanelItem] = useState(null);
  const [menuList, setMenuList] = useState([]);
  const [selectedDocumentFile, setSelectedDocumentFile] = useState("");
  const [selectedFilterTab, setSelectedFilterTab] = useState("");
  const [filterTabs, setFilterTabs] = useState([]);
  const [leftPanel, setLeftPanel] = useState([]);
  const [listData, setListData] = useState([]);
  const [payload, setPayload] = useState({
    page: 1,
    limit: 20,
    sort_type: "",
    sort_by: "",
  });
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [path, setPath] = useState("");
  const [isOpenList, setIsOpenList] = useState(true);
  const [showHeaderBackButton, setShowHeaderBackButton] = useState(false);
  const [historyList, setHistoryList] = useState([]);

  useEffect(async () => {
    await controlPagePermissions();
  }, []);

  const wordContent = localStorage.getItem("selectedDocumentFile");
  const params = JSON.parse(wordContent);
  let doi = params ? params.doi : null;
  useEffect(() => {
    if (doi) return;
    dialogToggleContentDialog();
  }, []);

  const [selectedDocumentFileProperties] = useFetch(
    () => getFileProperties({ doi }),
    {},
    { reloadDeps: [doi], deps: [doi] }
  );

  const handleGetServices = async (service) => {
    showLoading();
    await getService(
      { service_name: service.text?.toLowerCase(), save_as: true },
      { entity: "save_as_new_document", module: "services", action: "view" }
    ).then((data) => {
      setLeftPanel(data);
      const output = data.map((item, index) => {
        return { name: item.title, key: index, icon: findIcon(item.title.toLowerCase()) };
      });
      setMenuList([
        {
          links: output,
        },
      ]);
      setSelectedLeftPanelItem(output[0]);
    });
    hideLoading();
  };

  const handleGetClients = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentClients(payload, { entity: "save_as_new_document", module: "clients", action: "view" }).then(
        (data) => {
          setListData(data);
        }
      );
    } else {
      await getClients(payload, { entity: "save_as_new_document", module: "clients", action: "view" }).then((data) => {
        setListData(data);
      });
    }
    hideLoading();
  };

  const handleGetMatters = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentMatters(payload, { entity: "save_as_new_document", module: "matters", action: "view" }).then(
        (data) => {
          setListData(data);
        }
      );
    } else {
      await getMatters(payload, { entity: "save_as_new_document", module: "matters", action: "view" }).then((data) => {
        setListData(data);
      });
    }
    hideLoading();
  };

  const handleGetMunderecats = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentMunderecats(payload, {
        entity: "save_as_new_document",
        module: "munderecats",
        action: "view",
      }).then((data) => {
        setListData(data);
      });
    } else {
      await getMunderecats(payload, { entity: "save_as_new_document", module: "munderecats", action: "view" }).then(
        (data) => {
          setListData(data);
        }
      );
    }
    hideLoading();
  };

  const handleGetContracts = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentContracts(payload, { entity: "save_as_new_document", module: "contracts", action: "view" }).then(
        (data) => {
          setListData(data);
        }
      );
    } else if (selectedFilterTab === filterTabList.myFavorites) {
      await getFavoriteContracts(payload, { entity: "save_as_new_document", module: "contracts", action: "view" }).then(
        (data) => {
          setListData(data);
        }
      );
    } else {
      await getContracts(payload, { entity: "save_as_new_document", module: "contracts", action: "view" }).then(
        (data) => {
          setListData(data);
        }
      );
    }
    hideLoading();
  };

  const handleGetCases = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentCases(payload, { entity: "save_as_new_document", module: "cases", action: "view" }).then(
        (data) => {
          setListData(data);
        }
      );
    } else if (selectedFilterTab === filterTabList.myFavorites) {
      await getFavoriteCases(payload, { entity: "save_as_new_document", module: "cases", action: "view" }).then(
        (data) => {
          setListData(data);
        }
      );
    } else {
      getCases(payload, { entity: "save_as_new_document", module: "cases", action: "view" }).then((data) => {
        setListData(data);
      });
    }
    hideLoading();
  };

  const handleGetMuameles = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentMuameles(payload, { entity: "save_as_new_document", module: "muameles", action: "view" }).then(
        (data) => {
          setListData(data);
        }
      );
    } else {
      await getMuameles(payload, { entity: "save_as_new_document", module: "muameles", action: "view" }).then(
        (data) => {
          setListData(data);
        }
      );
    }
    hideLoading();
  };

  const handleGetFolders = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getFolders(payload, { entity: "save_as_new_document", module: "folders", action: "view" }).then((data) => {
        setListData(data);
      });
    } else {
      if (breadcrumb.length > 0) {
        await getFiles(payload, { entity: "save_as_new_document", module: "folders", action: "view" }).then((data) => {
          setListData(data);
        });
      } else {
        await getFolders(payload, { entity: "save_as_new_document", module: "folders", action: "view" }).then(
          (data) => {
            setListData(data);
          }
        );
      }
    }
    hideLoading();
  };

  const handleGetStages = async () => {
    showLoading();
    if (selectedFilterTab === filterTabList.recent) {
      await getRecentStages(payload, { entity: "save_as_new_document", module: "stages", action: "view" }).then(
        (data) => {
          setListData(data);
        }
      );
    } else {
      await getStages(payload, { entity: "save_as_new_document", module: "stages", action: "view" }).then((data) => {
        setListData(data);
      });
    }
    hideLoading();
  };

  const handleSelectFilterItem = (item) => {
    setSelectedFilterTab(item);
  };

  const toggleProperties = () => {
    if (!selectedDocumentFile) return;
    setProperties(!properties);
  };

  const handleSelectMenuItem = (e, item) => {
    const selectedItemIndex = menuList[0]?.links.findIndex((x) => x.name == item.name);
    let categories = JSON.parse(JSON.stringify(menuList[0]?.links));
    categories.length = selectedItemIndex;
    setHistoryList(categories);

    setShowHeaderBackButton(historyList.length === 1 ? false : true);

    setSelectedLeftPanelItem(item);
    setSelectedFilterTab(filterTabs[0]);
    setBreadcrumb([]);
    setListData(null);
    setDefaultPayload();
  };

  const handleChangeMWA = (e, item) => {
    setListData(null);
    setMWA(item);
    setTheme(item.key - 1);
    setSelectedFilterTab(filterTabList.recent);
    setBreadcrumb([]);
    setPayload((prevState) => ({
      ...prevState,
      service_name: item.text?.toLowerCase(),
    }));
    handleGetServices(item);
  };

  const setDefaultPayload = () => {
    setPayload({
      page: 1,
      limit: 20,
      service_name: MWA.text?.toLowerCase(),
      sort_type: "",
      sort_by: "",
    });
  };

  const handleChangeSortBy = (e, item) => {
    setPayload((prevState) => ({
      ...prevState,
      sort_by: item.key,
      sort_type: "asc",
    }));
  };

  const handleChangeSortType = () => {
    if (payload.sort_type === "desc") {
      setPayload((prevState) => ({
        ...prevState,
        sort_type: "asc",
      }));
      return;
    }
    setPayload((prevState) => ({
      ...prevState,
      sort_type: "desc",
    }));
  };

  const handleSelectListItem = (item) => {
    setShowHeaderBackButton(true);

    const isSelectedItem = (leftPanelItem) => selectedLeftPanelItem.name.toLowerCase().includes(leftPanelItem);
    if (menuList[0]?.links.length !== historyList.length + 1) {
      setHistoryList([...historyList, selectedLeftPanelItem]);
    }

    if (menuList[0]?.links.length !== selectedLeftPanelItem.key + 1) {
      setSelectedLeftPanelItem(menuList[0]?.links[selectedLeftPanelItem.key + 1]);
      setSelectedFilterTab(filterTabList.all);
      setListData(null);
    }

    setBreadcrumb((prevState) => [
      ...prevState,
      {
        ...selectedLeftPanelItem,
        text: item.name || item.title,
        key: selectedLeftPanelItem.key,
        onClick: handleSelectMenuItem,
      },
    ]);

    switch (true) {
      case isSelectedItem(sideBarList.clients):
        setPayload((prevState) => ({
          ...prevState,
          limit: 20,
          client_name: item.name,
        }));
        setPath(`/${item.name}`);
        break;
      case isSelectedItem(sideBarList.matters):
        let output = {};
        if (MWA.text === "Mahfuz") {
          output = {
            path: `/${item.name}`,
            client_name: item.client,
          };
        } else {
          output = {
            matter_name: item.name,
          };
        }
        setPayload((prevState) => ({
          ...prevState,
          ...output,
          limit: 20,
        }));
        setPath((prevState) => `${prevState}/${item.name}`);
        break;
      case isSelectedItem(sideBarList.contracts):
        setPayload((prevState) => ({
          ...prevState,
          limit: 20,
          contract: item?.contract || item?.title,
        }));
        setPath((prevState) => `${prevState}/${item.title}`);
        break;
      case isSelectedItem(sideBarList.cases):
        setPayload((prevState) => ({
          ...prevState,
          limit: 20,
          case: item?.case || item?.title,
        }));
        setPath((prevState) => `${prevState}/${item.title}`);
        break;
      default:
        if (MWA.text === "Muhakemat") {
          setPath(`/${item.matter}/${item.case_name}/${item.title}`);
        }
        if (MWA.text === "Mukavele") {
          setPath(`/${item.matter}/${item.contract_name}/${item.title}`);
        }
        if (MWA.text === "Mahfuz") {
          setPath(item.path);
        }
        if (MWA.text === "Muamelat") {
          setPath(`/${item.matter}/${item.name}`);
        }
        setProperties(true);
        if (menuList[0]?.links.length === historyList.length + 1) {
          setIsOpenList(false);
        }
        setSelectedDocumentFile(item);
        isLastLeftPanelItem.current = true;
        break;
    }
  };

  const handleCloseDialog = () => {
    sendMessageToTaskpane({ messageType: "dialogClose" });
  };

  

  useEffect(() => {
    if (!selectedLeftPanelItem) return;
    const filterTabsOfSelectedItem = leftPanel[selectedLeftPanelItem.key]?.subItem;
    setFilterTabs(filterTabsOfSelectedItem);

    const isSelectedItem = (leftPanelItem) => selectedLeftPanelItem.name.toLowerCase().includes(leftPanelItem);
    switch (true) {
      case isSelectedItem(sideBarList.clients):
        handleGetClients();
        break;
      case isSelectedItem(sideBarList.matters):
        handleGetMatters();
        break;
      case isSelectedItem(sideBarList.munderecat):
        handleGetMunderecats();
        break;
      case isSelectedItem(sideBarList.contracts):
        handleGetContracts();
        break;
      case isSelectedItem(sideBarList.cases):
        handleGetCases();
        break;
      case isSelectedItem(sideBarList.muamele):
        handleGetMuameles();
        break;
      case isSelectedItem(sideBarList.folders):
        handleGetFolders();
        break;
      case isSelectedItem(sideBarList.stages):
        handleGetStages();
        break;
      default:
        setListData(null);
        break;
    }
  }, [selectedLeftPanelItem, selectedFilterTab, payload]);

  const validationSchema = Yup.object({
    doc_name: Yup.string().required("Required Field"),
    doc_date: Yup.date().required("Required Field"),
    doc_type: Yup.string().required("Required Field"),
    doc_subject: Yup.string().required("Required Field"),
    from: Yup.string().required("Required Field"),
    to: Yup.string().required("Required Field"),
    version_note: Yup.string().required("Required Field"),
  });
  const saveAsNewDocumentForm = useFormik({
    initialValues: {
      version_info: "",
      version_note: "",
      doc_subject: "",
      doc_type: "",
      from: "",
      to: "",
      doc_date: "",
      doc_name: "",
      path: "",
      client_name: "",
    },
    validationSchema,
    onSubmit: async (data) => {
      const docName = `${data.doc_name.replace(/.docx/gi, "")}.docx`;
      const pathAddress = `${path}`;
      showLoading();
      if (page === "saveAsNewDocumentPdf") {
        const payload = {
          ...data,
          file_path: pathAddress,
          file_name: docName,
        };
        await saveDocument(payload, "saveAsPdf", "saveAsNewDocument");
        openMessage("success", "Document Successfully Saved.");
        setTimeout(() => {
          window.location = `${window.location.origin}/index.html?page=saveAsPdf`;
        }, 4000);
      } else {
        const payload = {
          ...data,
          path: pathAddress,
          service_name: MWA.text,
          file_name: docName,
        };

        const payloadForSaveAndSync = {
          service_name: data.service_name,
          path: pathAddress,
          file_path: docName,
          file_name: docName,
        };

        localStorage.setItem("payloadFromSaveAs", JSON.stringify(payloadForSaveAndSync));

        checkFooter(payload);

        await saveDocument(payload, "saveAsNewDocument", null, {
          entity: "save_as_new_document",
          module: "document",
          action: "add",
        });
        openMessage("success", "Document Successfully Saved.");
        setTimeout(() => {
          location.href = location.href;
        }, 4000);
      }
    },
  });

  const advancedFilterForm = useFormik({
    initialValues: {
      client_name: "",
      matter_name: "",
      fcc_name: "",
      page: 1,
      limit: 9999,
      search_category: "",
      search_text: "",
    },
    onSubmit: async (data) => {
      showLoading();
      let payload = {};
      switch (MWA.text) {
        case "Mahfuz":
          payload = {
            ...data,
            service_name: MWA.text,
            search_category: selectedLeftPanelItem.name,
            folder_name: data.fcc_name || "",
          };
          break;
        case "Mukavele":
          payload = {
            ...data,
            service_name: MWA.text,
            search_category: selectedLeftPanelItem.name,
            contract_name: data.fcc_name || "",
          };
          break;
        case "Muhakemat":
          payload = {
            ...data,
            service_name: MWA.text,
            search_category: selectedLeftPanelItem.name,
            case_name: data.fcc_name || "",
          };
          break;
        case "Muamelat":
          payload = { ...data, service_name: MWA.text, search_category: selectedLeftPanelItem.name };
          break;
        default:
          payload = data;
          break;
      }
      delete payload.fcc_name;
      getSearchResults(payload, { entity: "open_document", module: "search_box", action: "search" }).then((data) =>
        setListData(data)
      );
      advancedFilterForm.handleReset();
      hideLoading();
    },
  });

  const saveAsNewDocumentSchema = [
    {
      type: "version",
      label: "Version",
      isWidthFull: true,
      name: "version_info",
    },
    {
      type: "targetProperties",
      label: "Target Properties",
      isWidthFull: true,
      data: selectedDocumentFileProperties,
    },
    {
      type: "input",
      label: "Doc Name",
      isWidthFull: true,
      name: "doc_name",
    },
    {
      label: "Doc Date",
      type: "date",
      name: "doc_date",
      isWidthFull: true,
    },
    {
      type: "searchableDropdown",
      label: "Doc Type",
      isWidthFull: true,
      placeholder: "Select",
      name: "doc_type",
      selectedProp: "label",
      handleGetData: (payload) => getFileDocTypes(payload),
    },
    {
      type: "textarea",
      label: "Doc Subject",
      isWidthFull: true,
      name: "doc_subject",
    },
    {
      type: "textarea",
      label: "Version Comment",
      isWidthFull: true,
      name: "version_note",
    },
    {
      type: "searchableDropdown",
      label: "From",
      isWidthFull: true,
      placeholder: "Select",
      selectedProp: "label",
      name: "from",
      handleGetData: (payload) => getFileFromTo(payload),
    },
    {
      type: "searchableDropdown",
      label: "To",
      isWidthFull: true,
      placeholder: "Select",
      selectedProp: "label",
      name: "to",
      handleGetData: (payload) => getFileFromTo(payload),
    },
  ];

  useEffect(() => {
    const { version, client, versionNote, from, to, docSubject, docType, docName, path, service_name } =
      selectedDocumentFileProperties;
    saveAsNewDocumentForm.setValues({
      version_info: version || "",
      version_note: versionNote || "",
      doc_subject: docSubject || "",
      doc_type: docType || "",
      from: from || "",
      to: to || "",
      doc_date: new Date() || "",
      doc_name: docName || "",
      client_name: client || "",
      path: path || "",
      service_name: service_name || "",
    });
  }, [selectedDocumentFileProperties]);

  const handleSubmitForSearch = (e) => {
    if (e.key !== "Enter") return;
    advancedFilterForm.handleSubmit();
  };

  const handleBackClick = () => {
    if (!isOpenList) {
      setIsOpenList(true);
      return;
    }
    const history = historyList;
    const historyCount = history.length;
    const selectedOrder = historyCount - 1;
    const selectedItem = history[selectedOrder];
    handleSelectMenuItem("historyback", selectedItem);
  };

  return (
    <div>
      <TaskpaneHeader
        title={page == "saveAsNewDocumentPdf" ? "Save As PDF" : "Save As New Document"}
        handleBackClick={handleBackClick}
        showBackButton={showHeaderBackButton}
      />
      <div style={{ display: isOpenList ? "" : "none" }}>
        <div className={open.openDocCategoriesContainer}>
          <NmDropdown
            data={MWAList}
            expandIcon="ChevronDownSmall"
            placeholder="None Selected"
            placeholderIcon="Waffle"
            handleChange={handleChangeMWA}
            selectedKey={MWA.key}
            styles={serviceDropdownStyles}
          />
        </div>
        <div>
          <div className={`${open.topMenuStyles} d-flex justify-content-start`}>
            <div className={`${open.searchDdlContainer} w-auto`}>
              <NmDropdown
                data={menuList[0]?.links?.map((item) => {
                  return { ...item, text: item.name };
                })}
                dropdownWidth={40}
                expandIcon="none"
                placeholderIcon="ViewAll2"
                placeholder="all"
                selectedKey={selectedLeftPanelItem?.key}
                handleChange={handleSelectMenuItem}
                styles={searchServiceDropdownStyles}
                disabled={!MWA}
              />
            </div>
            <div className="w-75">
              <TextField
                styles={searchBoxStyle(properties, theme, MWA)}
                placeholder="search with in all documents, folders, matters, clients"
                iconProps={searchBoxIcon}
                disabled={!MWA}
                onChange={advancedFilterForm.handleChange}
                name={"search_text"}
                value={advancedFilterForm.values.search_text}
                onKeyPress={handleSubmitForSearch}
              />
            </div>
            <div className="ms-2">
              <IconButton
                iconProps={iconName}
                className={openWithMWA(theme, MWA).searchButtonStyles}
                onClick={() => dialogToggle()}
                disabled={!MWA}
              />
            </div>
          </div>
        </div>
        <div className={open.openDocCategoriesContainer}>
          <NmDropdown
            data={menuList[0]?.links?.map((item) => {
              return { ...item, text: item.name };
            })}
            dropdownWidth={40}
            expandIcon="ChevronDownSmall"
            placeholderIcon="TextDocument"
            placeholder="all"
            selectedKey={selectedLeftPanelItem?.key}
            handleChange={handleSelectMenuItem}
            styles={searchServiceDropdownStyles}
            disabled={!MWA}
          />
        </div>
        <div>
          <NmFilterTabs
            data={filterTabs}
            selectedFilter={selectedFilterTab}
            handleSelect={handleSelectFilterItem}
            MWA={MWA}
          />
        </div>
        <div>
          <div className={`${open.sortingContainer} d-flex justify-content-between pb-2`}>
            <div className="pt-2 ps-2">
              <span className={open.listCount}>{listData?.data?.length}</span> Records
            </div>
            <div>
              <NmSort
                sortList={sortList}
                handleChangeSortBy={handleChangeSortBy}
                payload={payload}
                handleChangeSortType={handleChangeSortType}
                disabled={!MWA}
              />
            </div>
          </div>
        </div>
        <div className="pb-5">
          <Stack className={open.tableListStyles}>
            <NmList
              data={listData}
              selectedLeftPanelItem={selectedLeftPanelItem?.name?.toLowerCase()}
              handleSelect={handleSelectListItem}
              selectedItem={selectedDocumentFile}
              setPayload={setPayload}
            />
          </Stack>
        </div>
      </div>
      <div
        className={`${open.selectButtonContainer} w-100 text-end py-2 px-4`}
        style={{ display: !isOpenList ? "" : "none" }}
      >
        <DefaultButton
          text="Save As"
          className={openWithMWA(theme, MWA).buttonStyles}
          onClick={() => saveAsNewDocumentForm.handleSubmit()}
        />
      </div>
      <div>
        <div
          className={`${global.p0} ${(properties ? "ms-sm4" : "", openWithProperties(properties).propertiesStyle)}`}
          style={{ display: isOpenList ? "none" : "" }}
        >
          <DocumentDetail form={saveAsNewDocumentForm} schema={saveAsNewDocumentSchema} />
        </div>
      </div>
      <ContentCheck
        doi={doi}
        handleCloseDialog={handleCloseDialog}
        dialogToggleContentDialog={dialogToggleContentDialog}
        openedContentDialog={openedContentDialog}
        haveCancel={false}
      />
    </div>
  );
}
