import React from "react";
import { pavStyle } from "../style/pav.style";
import { formatClock, formatDate } from "../utils/formatDate";

export default function NmActivityList({ title, data }) {
  const renderUsername = (name) => {
    const splitName = name.split(" ");
    return splitName[0].charAt(0).toUpperCase() + (splitName[1] ? splitName[1].charAt(0).toUpperCase() : " ");
  };

  return (
    <div className={pavStyle.activityListContainer}>
      <div className={pavStyle.activityListTitle}>{title}</div>
      {data.map((item, index) => (
        <div key={index} className={pavStyle.activityItemContainer}>
          <div className="d-flex align-items-center justify-content-center flex-column me-1">
            {data.length > 1 ? (
              <>
                <div className={`${pavStyle.itemLineTop} ${index === 0 ? "hidden" : " "}`} />
                <div className={pavStyle.itemCircle} />
                <div className={`${pavStyle.itemLineBottom} ${data.length - 1 === index ? "hidden" : " "}`} />
              </>
            ) : (
              <div className={pavStyle.emptyArea} />
            )}
          </div>
          <div className="d-flex align-item-center justify-content-center gap-3">
            <div className="d-flex flex-column">
              <div className={pavStyle.activityListDateName}>{formatDate(item.date)}</div>
              <div className={pavStyle.activityListClockComment}>{formatClock(item.date)}</div>
            </div>
            <div className={pavStyle.activityListUserCircle}>{renderUsername(item.username)}</div>
          </div>
          <div className={pavStyle.activityListLine} />
          <div className="d-flex flex-column">
            <div className={pavStyle.activityListDateName}>{item.username}</div>
            <div className={pavStyle.activityListClockComment}>Yorum alanı</div>
          </div>
        </div>
      ))}
    </div>
  );
}
