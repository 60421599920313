import baseURL from "../../../../api-url.json";
import $ from "jquery";

const getToken = () => {
  return localStorage.getItem("token");
};

const checkControl = (response) => {
  if (response.data.error) {
    if (response.data.errorCode === 401 && response.data.error !== "Invalid user credentials") {
      let params = new URL(document.location).searchParams;
      let oldPage = params.get("page");
      if (oldPage !== "login" && oldPage) {
        window.location.search = `?page=login&oldPage=${oldPage}`;
      }
    } else {
      return response;
    }
  }
  return response;
};

const get = (endpoint, extraHeaders = {}) => {
  return new Promise((resolve, reject) => {
    console.log("get");
    const token = getToken();
    const settings = {
      url: `${baseURL.apiUrl}/${endpoint}`,
      method: "GET",
      timeout: 0,
      headers: {
        "Content-Type": "application/json",
        token: token ? `${token}` : null,
        ...extraHeaders,
      },
    };
    $.ajax(settings).done(function (response) {
      resolve(checkControl({ data: response }));
    });
  });
};

const post = (endpoint, params, extraHeaders = {}) => {
  return new Promise((resolve, reject) => {
    const token = getToken();
    var settings = {
      url: `${baseURL.apiUrl}/${endpoint}`,
      method: "POST",
      timeout: 0,
      headers: {
        "Content-Type": "application/json",
        token: token ? `${token}` : null,
        ...extraHeaders,
      },
      data: JSON.stringify(params),
    };

    $.ajax(settings).done(function (response) {
      resolve({data: response});
    });
  });
};

const remove = (endpoint, data = {}, extraHeaders = {}) => {
  const token = getToken();
  return "ok";
};

const put = (endpoint, data, extraHeaders = {}) => {
  const token = getToken();
  return "ok";
};

export { get, post, remove, put };
