import React, { useState } from "react";
import { global } from "../style/global.style";
import NmDialog from "./NmDialog";
import { DefaultButton } from "office-ui-fabric-react";

export default function OpenCheck({ isOpen, onCancel, onContinue }) {
  return (
    <NmDialog opened={isOpen}>
      <div className={global.contentDialog}>
        <div className="text-center">
          The document was not saved. If you open a new document without saving, your changes will be lost.<br/>
          <strong>Are you sure?</strong>
        </div>
        <div className="d-flex gap-3 justify-content-center mt-3">
          <DefaultButton text="Cancel" className={global.secondaryButton} onClick={() => onCancel()} />
          <DefaultButton text="Open" className={global.primaryButton} onClick={() => onContinue()} />
        </div>
      </div>
    </NmDialog>
  );
}
