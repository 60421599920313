import { DefaultButton } from "office-ui-fabric-react";
import React, { useContext, useEffect, useState } from "react";
import { images } from "../constants";
import { global } from "../style/global.style";
import { logoutStyle } from "../style/logout.style";
import { sendMessageToTaskpane } from "../utils/sendMessageToTaskpane";
import { userLogout } from "../requests/api-requests/user";
import { APP_CONFIG } from "../constants";
import { LoadingContext } from "../context/LoadingContext";
import { VERSION } from "../../../constant";

export default function Logout() {
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [userData, setUserData] = useState(null);
  let apiVersion = {};
  if (localStorage.getItem("apiVersion")) {
    apiVersion = JSON.parse(localStorage.getItem("apiVersion"));
  }
  useEffect(() => {
    const user = localStorage.getItem("userDetails");
    if (user) {
      setUserData(JSON.parse(user));
    }
  }, []);

  const logout = async () => {
    const token = localStorage.getItem("token");
    const refreshtoken = localStorage.getItem("refreshtoken");
    const logoutParams = { kcClient: APP_CONFIG.KC_CLIENT, accesstoken: token, refreshtoken: refreshtoken };
    showLoading();
    //await userLogout(logoutParams);
    localStorage.clear();
    window.location.search  = "/index.html?page=logout";
  };

  return (
    <div className={`px-4 relative ${global.container}`}>
      {userData !== null && (
        <div className="row">
          <div className="col">
            <div
              className={`d-flex flex-column align-items-center justify-content-center ${logoutStyle.container}`}
            >
              <img width={"80"} src={images.USER_ICON} className="mb-3" />
              <div className={`${logoutStyle.userDetails} mb-3`}>
                {userData.name} <br /> <span>{userData.title}</span>
              </div>
              <DefaultButton className={`${global.primaryButton} mb-3`} text="Logout" onClick={logout} />
              <div className={logoutStyle.footer}>
                Powered by <div> NewMind</div>.ai
              </div>
              <div className={logoutStyle.version}>
                App Ver. {VERSION.VERSION} | {VERSION.BUILD_DATE}
                <br />
                Api Ver. {apiVersion?.versiyon} | {apiVersion?.deployDate}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
