import { mergeStyleSets } from "office-ui-fabric-react";

export const copyLink = mergeStyleSets({
  linkContainer: {
    backgroundColor: "#F4F5F7",
    borderRadius: 5,
    padding: 8,
    span: {
      color: "#0082FA",
      textDecoration: "underline",
      fontSize: 13,
    },
  },
  copyIcon: {
    color: "#0082FA",
    fontSize: 15,
    cursor: "pointer",
  },
  shareIcon: {
    color: "#0082FA",
    fontSize: 33,
  },
  linkBoldText: {
    fontWeight: "bold",
    fontSize: 14,
  },
  formContainer: {
    gap: 12,
    label: {
      fontWeight: "bold",
      fontSize: 14,
    },
  },
});
