import { themeColors } from "../constants";
import { FontSizes, mergeStyleSets } from "office-ui-fabric-react";

export const pivotStyle = (theme) => {
  return {
    root: {
      // display: "flex",
    },
    itemContainer: {
      // height: "calc(100vh - 44px )",
      // overflow: "scroll",
    },
    link: {
      backgroundColor: "#F4F5F7",
      // width: "100%",
      marginRight: 0,
      color: "#AEB8C1",
      fontSize: 13,
      fontWeight: 600,
      "&:hover, &:active": {
        color: "none",
      },
      boxSizing: "border-box",
    },
    linkIsSelected: {
      color: themeColors[theme]?.secondary,

      "&:before": {
        backgroundColor: themeColors[theme]?.secondary,
      },
    },
  };
};

export const propertiesDetails = mergeStyleSets({
  menuContainer: {
    backgroundColor: "#F4F5F7",
    fontWeight: 600,
    color: "rgb(44, 193, 246)",
  },
  menuItem: {
    cursor: "pointer",
  },
  menuItemIsSelected: {
    borderBottom: "1px solid rgb(44, 193, 246)",
  },
});
