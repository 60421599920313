import * as React from "react";
import { Layer, Overlay, Popup } from "office-ui-fabric-react";
import { dialog } from "../style/nmDialog.style";

const NmDialog = ({ children, opened, dialogToggle, width = "auto" }) => {
  return (
    <>
      {opened && (
        <Layer>
          <Popup
            className={dialog.root}
            role="dialog"
            aria-modal="true"
            onDismiss={dialogToggle}
            enableAriaHiddenSiblings={true}
          >
            <Overlay onClick={dialogToggle} />
            <div role="document" className={dialog.content} style={{ width: width }}>
              {children}
            </div>
          </Popup>
        </Layer>
      )}
    </>
  );
};

export default NmDialog;