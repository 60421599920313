import { TextField } from "office-ui-fabric-react";
import React from "react";
import { input } from "../style/nmForm.style";

export default function NmInput({
  label,
  value,
  disabled,
  placeholder,
  type,
  classNames,
  onChange,
  name,
  iconProps,
  errorMessage,
  isPassword,
  onKeyPress,
  ...props
}) {
  return (
    <TextField
      className={classNames}
      label={label}
      value={value}
      placeholder={placeholder}
      type={isPassword ? "password" : type}
      onChange={onChange}
      name={name}
      styles={input}
      disabled={disabled}
      iconProps={iconProps}
      errorMessage={errorMessage}
      canRevealPassword={true}
      revealPasswordAriaLabel="Show password"
      onKeyPress={onKeyPress}
    />
  );
}
