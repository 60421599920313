import { PrimaryButton } from "office-ui-fabric-react";
import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import NmForm from "../components/NmForm";
import { APP_CONFIG, images } from "../constants";
import { UserContext } from "../context/UserContext";
import { global } from "../style/global.style";
import { loginStyle } from "../style/login.style";
import * as Yup from "yup";

const loginSchema = [
  {
    type: "input",
    isWidthFull: true,
    icon: "Contact",
    name: "username",
    placeholder: "Username",
  },
  {
    type: "input",
    isWidthFull: true,
    icon: "Lock",
    name: "password",
    placeholder: "Password",
    isPassword: true,
  },
];

export default function Login() {
  const { login } = useContext(UserContext);
  useEffect(() => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("refreshtoken");
    localStorage.removeItem("token");
  }, []);

  const validationSchema = Yup.object({
    username: Yup.string().required("Required Field"),
    password: Yup.string().required("Required Field"),
  });

  const loginForm = useFormik({
    initialValues: {
      kcClient: APP_CONFIG.KC_CLIENT,
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (data) => {
      localStorage.setItem("user", data.username);
      login(data);
    },
  });

  return (
    <div className={`px-4 relative ${global.container}`}>
      <div className="row">
        <div className="col">
          <div className={`d-flex flex-column align-items-center justify-content-center gap-3 ${loginStyle.container}`}>
            <img width={"80"} src={images.MAIN_LOGO} className="pb-3"/>
            <NmForm formSchema={loginSchema} formik={loginForm} classNames="p-0" />
            <PrimaryButton className={global.primaryButton} text="Login" onClick={loginForm.handleSubmit} />
            <div className={`pt-3 text-center`}>
              Powered by <strong> NewMind</strong>.ai
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
