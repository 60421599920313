import { DefaultButton, Icon, TooltipHost } from "office-ui-fabric-react";
import React, { useContext, useEffect, useState } from "react";
import { pavStyle } from "../style/pav.style";
import NmDialog from "../components/NmDialog";
import { useDialogState } from "../hooks/use-dialog-state";
import Chart from "react-apexcharts";
import { getFileActivities, getFileActivitiesForChart } from "../requests/api-requests/files.request";
import NmInput from "../components/NmInput";
import { isYesterday, isToday, parseISO, subDays, subWeeks, subMonths, subYears } from "date-fns";
import NmActivityList from "../components/NmActivityList";
import { form } from "../style/nmForm.style";
import { useFormik } from "formik";
import { formatDate } from "../utils/formatDate";
import NmDate from "../components/NmDate";
import { global } from "../style/global.style";
import { LoadingContext } from "../context/LoadingContext";

const dateSchema = [{ text: "1D" }, { text: "1W" }, { text: "1M" }, { text: "3M" }, { text: "All" }, { icon: "Add" }];

const actionSchema = [
  { text: "All", action: "" },
  { text: "Edit", action: "update", icon: "Edit" },
  { text: "View", action: "view", icon: "View" },
  { text: "Download", action: "download", icon: "Download" },
  { text: "Print", action: "print", icon: "Print" },
  { text: "Share", action: "share", icon: "Share" },
];

export default function Activity({ doi }) {
  const [opened, dialogToggle] = useDialogState();
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [selectedFilterDateItem, setSelectedFilterDateItem] = useState("All");
  const [activityList, setActivityList] = useState({});
  const [chartData, setChartData] = useState([]);
  const [chartPayload, setChartPayload] = useState({
    min_date: subYears(new Date(), 10).toISOString(),
    max_date: new Date().toISOString(),
    doi,
  });

  const requestChart = async (data) => {
    await getFileActivitiesForChart(data).then((payload) => {
      setChartData(payload);
    });
  };

  const chart = {
    options: {
      chart: {
        id: "basic-bar",
        zoom: false,
        toolbar: { show: false },
      },
      xaxis: {
        categories: chartData ? chartData.map((item) => formatDate(item.key.timestamp)) : [],
      },
    },
    series: [
      {
        name: "series-1",
        data: chartData ? chartData.map((item) => item.doc_count) : [],
      },
    ],
  };

  const activityForm = useFormik({
    initialValues: {
      action: "",
      user_name: "",
      doi: doi,
    },
    onSubmit: async (data) => {
      showLoading();
      await requestActivities(data);
      hideLoading();
    },
  });

  useEffect(async () => {
    if (!doi) return;
    showLoading();
    await requestActivities(activityForm.values);
    await requestChart(chartPayload);
    hideLoading();
  }, [doi]);

  const requestActivities = async (data) => {
    await getFileActivities(data).then((response) => {
      renderActivityList(response);
    });
  };

  const renderActivityList = (response) => {
    let output = {};
    let today = [];
    let tomorrow = [];
    let older = [];

    if (response) {
      for (let index = 0; index < response.length; index++) {
        const element = response[index];
        const _isToday = isToday(parseISO(element.date));
        const _isYesterday = isYesterday(parseISO(element.date));

        if (_isToday) {
          today.push(element);
          continue;
        }
        if (_isYesterday) {
          tomorrow.push(element);
          continue;
        }
        older.push(element);
      }
    }
    output = {
      today,
      tomorrow,
      older,
    };
    setActivityList(output);
  };

  const handleSelectFilterDateItem = async (item) => {
    setSelectedFilterDateItem(item.text);
    let value = "";
    switch (item.text) {
      case "1D":
        value = subDays(new Date(), 1).toISOString();
        break;
      case "1W":
        value = subWeeks(new Date(), 1).toISOString();
        break;
      case "1M":
        value = subMonths(new Date(), 1).toISOString();
        break;
      case "3M":
        value = subMonths(new Date(), 3).toISOString();
        break;
      case "All":
        value = subYears(new Date(), 10).toISOString();
        break;
      default:
        dialogToggle();
        break;
    }
    if (!item.text) return;
    showLoading();
    setChartPayload({ ...chartPayload, min_date: value });
    await requestChart({ ...chartPayload, min_date: value });
    hideLoading();
  };

  const handleChangeDateRange = (value, name) => {
    showLoading();
    if (name === "min_date") {
      setChartPayload({ ...chartPayload, min_date: new Date(value).toISOString() });
    } else {
      setChartPayload({ ...chartPayload, max_date: new Date(value).toISOString() });
    }
  };

  const handleSelectFilterActionItem = async (item) => {
    await activityForm.setFieldValue("action", item.action);
    activityForm.handleSubmit();
  };

  const handleSubmitForSearch = (e) => {
    if (e.key !== "Enter") return;
    activityForm.handleSubmit();
  };

  const handleSubmitDateRange = async () => {
    showLoading();
    await requestChart(chartPayload);
    dialogToggle();
    hideLoading();
  };

  return (
    <div className="p-3">
      <div className={pavStyle.dateContainer}>
        {dateSchema.map((item, index) => (
          <div
            onClick={() => handleSelectFilterDateItem(item)}
            className={`${pavStyle.dateItem} ${item.text === selectedFilterDateItem ? "active" : " "}`}
            key={index}
          >
            {item.text ? <>{item.text}</> : <Icon iconName={item.icon} />}
          </div>
        ))}
      </div>
      <div>
        <Chart options={chart.options} series={chart.series} type="area" zoom="false" width="100%" />
      </div>
      <div className={pavStyle.actionContainer}>
        {actionSchema.map((item, index) => (
          <TooltipHost key={index} content={item.text} id={item.text} calloutProps={{ gapSpace: 0 }}>
            <div
              onClick={() => handleSelectFilterActionItem(item)}
              className={`${pavStyle.dateItem} ${item.action === activityForm.values.action ? "active" : " "}`}
            >
              {!item.icon ? (
                <div aria-describedby={item.text}>{item.text}</div>
              ) : (
                <Icon aria-describedby={item.text} iconName={item.icon} />
              )}
            </div>
          </TooltipHost>
        ))}
      </div>
      <div className={form.underline} />
      <NmInput
        classNames="mt-3"
        iconProps={{ iconName: "Filter" }}
        name="user_name"
        onChange={activityForm.handleChange}
        onKeyPress={handleSubmitForSearch}
      />
      <div>
        {activityList.today?.length > 0 && <NmActivityList title="Today" data={activityList.today} />}
        {activityList.tomorrow?.length > 0 && <NmActivityList title="Tomorrow" data={activityList.tomorrow} />}
        {activityList.older?.length > 0 && <NmActivityList title="Older" data={activityList.older} />}
      </div>
      <NmDialog opened={opened} dialogToggle={dialogToggle}>
        <div className="p-4">
          <NmDate
            label="Min Date"
            value={new Date(chartPayload.min_date)}
            onChange={(e, value) => handleChangeDateRange(value, "min_date")}
            minDate=""
          />
          <NmDate
            label="Max Date"
            value={new Date(chartPayload.max_date)}
            onChange={(e, value) => handleChangeDateRange(value, "max_date")}
            minDate={new Date(chartPayload.min_date)}
          />
          <DefaultButton className={global.primaryButton} onClick={() => handleSubmitDateRange()}>
            Filter
          </DefaultButton>
        </div>
      </NmDialog>
    </div>
  );
}
