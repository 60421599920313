export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export const guid = () => {
  let result = "";
  const hexcodes = "0123456789abcdef".split("");

  for (let index = 0; index < 32; index++) {
    let value = Math.floor(Math.random() * 16);

    switch (index) {
      case 8:
        result += "-";
        break;
      case 12:
        value = 4;
        result += "-";
        break;
      case 16:
        value = (value & 3) | 8;
        result += "-";
        break;
      case 20:
        result += "-";
        break;
    }
    result += hexcodes[value];
  }
  return result;
};
