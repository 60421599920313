import React from "react";
import { images } from "../constants";


export default function InternetConnection() {
  
  return (
    <div style={{textAlign: 'center', paddingTop:'12%'}}>
      <div>
      <img width={"120"} height={"120"} src={images.NO_INTERNET_CONNECTION} />
      </div>
      <h3>Please Check Internet Connection</h3>
    </div>
  );
}
