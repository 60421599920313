import mainLogo from "/assets/logo.png";
import noInternetConnectionLogo from "/assets/no-internet-connection.png";
import alertLogo from "/assets/alert.png";
import userIcon from "/assets/user-icon.png";

export const statuses = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  NOT_ACCEPTABLE: 406,
  INTERNAL_SERVER_ERROR: 500,
};

export const ENDPOINTS = {
  TESTS: "tests",
  SERVICES: "services",
  DOCUMENTS: "documents",
  RECENT_DOCUMENTS: "documents/resent-documents",
  CLIENTS: "clients",
  RECENT_CLIENTS: "clients/recent-clients",
  MATTERS: "metters",
  RECENT_MATTERS: "metters/recent-metters",
  FILE: "files/get-file",
  FILE_DOC_TYPES: "files/file-doc-types",
  FILE_FROM_TO: "files/file-from-to",
  FILE_SAVE_AS_NEW_FILE: "files/save-as-new-file",
  FILE_SAVE_SYNC_FILE: "files/save-sync-file",
  FILE_SAVE_AS_NEW_VERSION: "files/save-as-new-version",
  FILE_SAVE_AS_NEW_FILE_PDF: "files/save-as-new-pdf",
  FILE_SAVE_AS_NEW_VERSION_PDF: "files/save-new-version-pdf",
  FILE_PROPERTIES: "files/file-properties",
  FILE_VERSIONS: "files/file-versions",
  FILE_ACTIVITIES: "files/file-activities",
  FILE_SHARE: "files/file-share",
  FILE_SAVE_PROPERTIES: "files/save-properties",
  FILE_CHART: "files/cart-data",
  FILES: "files",
  MUNDERECAT: "munderecats",
  RECENT_MUNDERECAT: "munderecats/resent-munderecats",
  CONTRACTS: "contracts",
  RECENT_CONTRACTS: "/contracts/recent-contracts",
  FAVORITE_CONTRACTS: "/contracts/favorite-contracts",
  CASES: "cases",
  RECENT_CASES: "cases/recent-cases",
  FAVORITE_CASES: "cases/favorite-cases",
  MUAMELE: "muameles",
  RECENT_MUAMELE: "/muameles/recent-muameles",
  SEARCH: "search",
  COMPARE: "combine-compare/compare",
  COMBINE: "combine-compare/combine",
  LOGIN: "login",
  LOGOUT: "login/logout",
  FOLDER: "folder",
  STAGES: "stages",
  RECENT_STAGES: "stages/recent-stages",
  CO_AUTHOR: "co-authoring",
  CHECK_AUTH: "check-out",
};

export const monthArr = [
  {
    Key: "1",
    label: "Ocak",
  },
  {
    Key: "2",
    label: "Şubat",
  },
  {
    Key: "3",
    label: "Mart",
  },
  {
    Key: "4",
    label: "Nisan",
  },
  {
    Key: "5",
    label: "Mayıs",
  },
  {
    Key: "6",
    label: "Haziran",
  },
  {
    Key: "7",
    label: "Temmuz",
  },
  {
    Key: "8",
    label: "Ağustos",
  },
  {
    Key: "9",
    label: "Eylül",
  },
  {
    Key: "10",
    label: "Ekim",
  },
  {
    Key: "11",
    label: "Kasım",
  },
  {
    Key: "12",
    label: "Aralık",
  },
];

export const themeColors = [
  { primary: "#004448", secondary: "#26D5BE" },
  { primary: "#2D4393", secondary: "#778BF5" },
  { primary: "#043458", secondary: "#007FF5" },
  { primary: "#0945A3", secondary: "#2CC1F6" },
];

export const MWAList = [
  { key: 1, text: "Mukavele", image: "./../../../assets/mukavele.svg" },
  { key: 2, text: "Muhakemat", image: "./../../../assets/muhakemat.svg" },
  { key: 3, text: "Mahfuz", image: "./../../../assets/mahfuz.svg" },
  { key: 4, text: "Muamelat", image: "./../../../assets/muamelat.svg" },
];

export const sortList = [
  { key: "date", text: "Date" },
  { key: "name", text: "Name" },
];

const dialogSize = {
  sm: {
    height: 30,
    width: 50,
    displayInIframe: false,
  },
  md: {
    height: 60,
    width: 60,
    displayInIframe: false,
  },
};

export const dialogProperties = {
  openFile: dialogSize.md,
  saveAs: dialogSize.md,
  default: dialogSize.md,
  saveAndSync: dialogSize.sm,
  saveAsPdf: dialogSize.md,
};

export const sideBarList = {
  clients: "clients",
  matters: "matters",
  muamele: "muamele",
  documents: "documents",
  munderecat: "munderecat",
  cases: "cases",
  contracts: "contracts",
  folders: "folders",
  stages: "stages",
};

export const filterTabList = {
  myFavorites: "My Favorites",
  recent: "Recent",
  all: "All",
  checkedOut: "Checked Out",
};

export const unshowLoaderPages = ["actions", "properties", "versions"];

export const images = {
  MAIN_LOGO: mainLogo,
  NO_INTERNET_CONNECTION: noInternetConnectionLogo,
  ALERT_LOGO: alertLogo,
  USER_ICON: userIcon,
};

export const APP_CONFIG = {
  KC_CLIENT: "IGSI",
};

export const disabledRibbonButtonList = [
  {
    buttonId: "Mecellem.SaveSync",
    parentGroupId: "Mecellem.Tab1.Group2",
    parentTabId: "Mecellem.Tab1",
    buttonName: "saveAndSync",
  },
  {
    buttonId: "Mecellem.SavePDF",
    parentGroupId: "Mecellem.Tab1.Group2",
    parentTabId: "Mecellem.Tab1",
    buttonName: "saveAsPdf",
  },

  {
    buttonId: "Mecellem.SendLink",
    parentGroupId: "Mecellem.Tab1.Group3",
    parentTabId: "Mecellem.Tab1",
    buttonName: "sendLink",
  },

  {
    buttonId: "Mecellem.CopyLink",
    parentGroupId: "Mecellem.Tab1.Group3",
    parentTabId: "Mecellem.Tab1",
    buttonName: "copyLink",
  },

  {
    buttonId: "Mecellem.Properties",
    parentGroupId: "Mecellem.Tab1.Group4",
    parentTabId: "Mecellem.Tab1",
    buttonName: "properties",
  },

  {
    buttonId: "Mecellem.Activities",
    parentGroupId: "Mecellem.Tab1.Group4",
    parentTabId: "Mecellem.Tab1",
    buttonName: "activities",
  },

  {
    buttonId: "Mecellem.Versions",
    parentGroupId: "Mecellem.Tab1.Group4",
    parentTabId: "Mecellem.Tab1",
    buttonName: "versions",
  },
  {
    buttonId: "Mecellem.Footer",
    parentGroupId: "Mecellem.Tab1.Group5",
    parentTabId: "Mecellem.Tab1",
    buttonName: "footer",
  },
  {
    buttonId: "Mecellem.CoAuthorMode",
    parentGroupId: "Mecellem.Tab1.Group7",
    parentTabId: "Mecellem.Tab1",
    buttonName: "coAuthor",
  },
  {
    buttonId: "Mecellem.SaveNewVersion",
    parentGroupId: "Mecellem.Tab1.Group2",
    parentTabId: "Mecellem.Tab1",
    buttonName: "SaveNewVersion",
  },
];

export const buttonPermissionList = [
  {
    page: "saveAndSync",
  },
  {
    page: "saveAsPdf",
  },
  {
    page: "sendLink",
  },
  {
    page: "copyLink",
  },
  {
    page: "Mecellem.pav",
  },
  {
    page: "footer",
  },
  {
    page: "coAuthor",
  },
  {
    page: "pav",
  },
];

export const getPageTitle = (popupName) => {
  if (popupName == "openFile") {
    return "Open File";
  } else if (popupName == "saveAsNewDocument") {
    return "Save As New Document";
  } else if (popupName == "saveAndSync") {
    return "Save And Sync";
  } else if (popupName == "saveAsNewVersion") {
    return "Save As New Version";
  } else if (popupName == "compare") {
    return "Compare Documents";
  } else if (popupName == "combine") {
    return "Combine Documents";
  } else if (popupName == "saveAsPdf") {
    return "Save As Pdf";
  } else if (popupName == "copyLink") {
    return "Copy Document Link";
  } else if (popupName == "sendLink") {
    return "Send Document Link";
  } else if (popupName == "footer") {
    return "Add Document Id In The Footer";
  } else {
    return "";
  }
};

export const footerCheckboxSchema = [
  {
    label: "Document ID",
    name: "doi",
  },
  {
    label: "version",
    name: "version",
  },
  {
    label: "File Path",
    name: "filePath",
  },
  {
    label: "Document Name",
    name: "docName",
  },
];
