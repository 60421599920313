import { SpinButton, Stack } from "office-ui-fabric-react";
import React from "react";
import { spinButton, versionInput } from "../style/nmVersionInput.style";

export default function ({ label, value, onChange, name, disabled }) {
  const values = value.split(".");

  const handleChange = (isFirst, value) => {
    let newValue = "";

    if (isFirst) {
      newValue = `${value}.${values[1]}`;
    } else {
      newValue = `${values[0]}.${value}`;
    }

    onChange(name, newValue);
  };

  return (
    <div className="d-flex flex-column gap-1">
      <label className={versionInput.versionInputLabel}>{label}</label>
      <Stack horizontal horizontalAlign="center" verticalAlign="center" gap={"16px"}>
        <SpinButton
          defaultValue="0"
          min={0}
          max={100}
          step={1}
          incrementButtonAriaLabel="Increase value by 1"
          decrementButtonAriaLabel="Decrease value by 1"
          value={values[0]}
          onChange={(e, value) => handleChange(true, value)}
          name={name}
          styles={spinButton}
          disabled={disabled}
        />
        <div className={versionInput.dot} />
        <SpinButton
          defaultValue="0"
          min={0}
          max={100}
          step={1}
          incrementButtonAriaLabel="Increase value by 1"
          decrementButtonAriaLabel="Decrease value by 1"
          styles={spinButton}
          value={values[1]}
          onChange={(e, value) => handleChange(false, value)}
          name={name}
          disabled={disabled}
        />
      </Stack>
    </div>
  );
}
