import { mergeStyleSets } from "office-ui-fabric-react";

export const pavPivotStyle = {
  root: {
    display: "flex",
  },
  itemContainer: {
    height: "calc(100vh - 44px )",
    overflow: "scroll",
  },
  link: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    marginRight: 0,
    color: "#586677",
    fontSize: 13,
    "&:hover, &:active": {
      color: "none",
    },
    "&:before": {
      left: 0,
      right: 0,
      height: 6,
    },
  },
  linkIsSelected: {
    fontWeight: "bold",
    color: "#33363C",
    "&:before": {
      left: 0,
      right: 0,
      backgroundColor: "#33363C",
    },
  },
};

export const pavStyle = mergeStyleSets({
  propertiesDocNameContainer: {
    backgroundColor: "#F4F8FA",
    display: "flex",
    flexDirection: "column",
    padding: "10px 12px",
    gap: 6,
  },
  propertiesDocImage: {
    width: "22px",
    height: "26px",
  },
  propertiesLabel: { color: "#53646C", fontSize: 12, fontWeight: 600 },
  propertiesDocNameTitle: {
    color: "#06090F",
  },
  line: {
    minHeight: 27,
    minWidth: 2,
    backgroundColor: "#DFE5F0",
  },
  propertiesBox: {
    borderRadius: 4,
    fontSize: 13,
    fontWeight: "bold",
    color: "#06090F",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F4F8FA",
    width: "100%",
    padding: "6px 0",
  },
  dateContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F4F8FA",
    borderRadius: 5,
    height: 30,
  },
  dateItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 11,
    height: 30,
    width: 30,
    borderRadius: 6,
    cursor: "pointer",
    color: "#06090F",
    "&.active": {
      backgroundColor: "#33363C",
      fontSize: 11,
      fontWeight: "bold",
      color: "#fff",
    },
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 5,
    height: 30,
  },
  activityListContainer: {
    paddingTop: 15,
  },
  activityItemContainer: {
    height: 60,
    display: "flex",
    alignItems: "center",
  },
  activityListTitle: {
    backgroundColor: "#F4F8FA",
    color: "#06090F",
    fontSize: 13,
    fontWeight: "bold",
    padding: "7px 12px",
  },
  activityListDateName: { color: "#06090F", fontSize: 13, fontWeight: "bold" },
  activityListClockComment: { color: "#AEB8C1", fontSize: 12 },
  activityListUserCircle: {
    width: 32,
    height: 32,
    borderRadius: 50,
    border: "2px solid #4EABFF",
    color: "#33363C",
    fontSize: 13,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  activityListLine: { backgroundColor: "#DFE5F0", height: 30, width: 1, margin: "0px 8px" },
  itemCircle: {
    backgroundColor: "#DFE5F0",
    width: 8,
    minHeight: 8,
    borderRadius: 50,
    border: "5px solid white",
  },
  itemLineTop: {
    backgroundColor: "#DFE5F0",
    width: 2,
    minHeight: 22,
    "&.hidden": {
      backgroundColor: "transparent",
    },
  },
  itemLineBottom: {
    backgroundColor: "#DFE5F0",
    width: 2,
    minHeight: 22,
    "&.hidden": {
      backgroundColor: "transparent",
    },
  },
  emptyArea: {
    width: 22,
    height: 1,
  },
});

export const tabButtons = mergeStyleSets({
  customTabButtons: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    marginRight: 0,
    color: "#586677",
    fontSize: 13,
    cursor:"pointer",
    "&:hover, &:active": {
      color: "none",
    },
    "&:before": {
      left: 0,
      right: 0,
      height: 6,
    },
  },
  customTabButtonsSelected: {
    fontWeight: "bold",
    color: "#33363C",
    borderBottom:"2px solid black"
  },
});
