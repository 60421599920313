import * as React from "react";
import { Icon } from "office-ui-fabric-react";
import { classNames } from "../style/nmList.style";
import Loader from "./Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { sideBarList } from "../constants";

export default function NmList({ data, handleSelect, selectedItem, selectedLeftPanelItem, setPayload }) {
  const handleChangePayload = () => {
    setPayload((prevState) => ({
      ...prevState,
      page: 1,
      limit: prevState.limit + 10,
    }));
  };

  const checkIsLock = (lockStatus, owner, extension) => {
    if (extension && extension.toLowerCase() != "docx") {
      return true;
    }
    const user = localStorage.getItem("user") ? localStorage.getItem("user") : "";
    if (lockStatus === "true" && owner.toLowerCase() == user.toLowerCase()) {
      return false;
    } else {
      return lockStatus === "true" ? true : false;
    }
  };

  return (
    <div>
      {data?.data?.length > 0 ? (
        <div id="scrollableDiv" className={classNames.listWrapper}>
          <InfiniteScroll
            dataLength={data.data.length}
            next={handleChangePayload}
            hasMore={data.count > data.data.length}
            scrollableTarget="scrollableDiv"
            className={classNames.infiniteScroll}
          >
            {data.data?.map((item) => {
              {
                switch (true) {
                  case selectedLeftPanelItem?.includes(sideBarList.documents):
                    return (
                      <div
                        className={`${classNames.itemCell} ${item.id === selectedItem.id ? classNames.selected : ""} `}
                        onClick={() => {
                          handleSelect(item);
                        }}
                        key={item.id}
                      >
                        <Icon className={classNames.itemImage} iconName={"WordLogo"} />

                        <div className={classNames.itemContent}>
                          <div className={classNames.itemName}>
                            {item.title} {item.date && `| ${item.date}`}
                          </div>
                          <div className={classNames.description}>
                            {item.version && `${item.version} |`} {item.owner && `${item.owner} |`}{" "}
                            {item.date && `${item.date} |`} {item.client && `${item.client} |`}{" "}
                            {item.matter && `${item.matter} |`} {item.contract && `${item.contract} |`}
                            {item.case && `${item.case} |`} {item.service && `${item.service}`}
                          </div>
                        </div>
                        {
                          <div className={classNames.privateWrapper}>
                            <Icon
                              className={classNames.chevron}
                              iconName={checkIsLock(item.check_status, item.checked_user, item.extension) ? "Lock" : "Unlock"}
                            />
                            {item.private}
                          </div>
                        }
                      </div>
                    );
                  case selectedLeftPanelItem?.includes(sideBarList.clients):
                    return (
                      <div
                        className={classNames.itemCell}
                        onClick={() => {
                          handleSelect(item);
                        }}
                        key={item.id}
                      >
                        <Icon className={classNames.itemImage} iconName={"People"} />
                        <div className={classNames.itemContent}>
                          <div className={classNames.itemName}>{item.name}</div>
                          <div className={classNames.description}>x Matter</div>
                        </div>
                        {item.private && (
                          <div className={classNames.privateWrapper}>
                            <Icon className={classNames.chevron} iconName={"Lock"} />
                            {item.private}
                          </div>
                        )}
                      </div>
                    );
                  case selectedLeftPanelItem?.includes(sideBarList.matters):
                    return (
                      <div
                        className={classNames.itemCell}
                        onClick={() => {
                          handleSelect(item);
                        }}
                        key={item.id}
                      >
                        <Icon className={classNames.itemImage} iconName={"WebAppBuilderFragment"} />
                        <div className={classNames.itemContent}>
                          <div className={classNames.itemName}>{item.name}</div>
                          <div className={classNames.description}>{item.matter_name}</div>
                        </div>
                        {item.private && (
                          <div className={classNames.privateWrapper}>
                            <Icon className={classNames.chevron} iconName={"Lock"} />
                            {item.private}
                          </div>
                        )}
                      </div>
                    );
                  case selectedLeftPanelItem?.includes(sideBarList.munderecat):
                    return (
                      <div
                        className={classNames.itemCell}
                        onClick={() => {
                          handleSelect(item);
                        }}
                        key={item.id}
                      >
                        <Icon className={classNames.itemImage} iconName={"FabricFolder"} />
                        <div className={classNames.itemContent}>
                          <div className={classNames.itemName}>{item.title}</div>
                          <div className={classNames.description}>some properties come here</div>
                        </div>
                        {item.private && (
                          <div className={classNames.privateWrapper}>
                            <Icon className={classNames.chevron} iconName={"Lock"} />
                            {item.private}
                          </div>
                        )}
                      </div>
                    );
                  case selectedLeftPanelItem?.includes(sideBarList.contracts):
                    return (
                      <div
                        className={classNames.itemCell}
                        onClick={() => {
                          handleSelect(item);
                        }}
                        key={item.id}
                      >
                        <Icon className={classNames.itemImage} iconName={"EditNote"} />
                        <div className={classNames.itemContent}>
                          <div className={classNames.itemName}>{item.title}</div>
                        </div>
                      </div>
                    );
                  case selectedLeftPanelItem?.includes(sideBarList.cases):
                    return (
                      <div
                        className={classNames.itemCell}
                        onClick={() => {
                          handleSelect(item);
                        }}
                        key={item.id}
                      >
                        <Icon className={classNames.itemImage} iconName={"EditNote"} />
                        <div className={classNames.itemContent}>
                          <div className={classNames.itemName}>{item.title}</div>
                        </div>
                      </div>
                    );
                  case selectedLeftPanelItem?.includes(sideBarList.muamele):
                    return (
                      <div
                        className={classNames.itemCell}
                        onClick={() => {
                          handleSelect(item);
                        }}
                        a
                        key={item.id}
                      >
                        <Icon className={classNames.itemImage} iconName={""} />
                        <div className={classNames.itemContent}>
                          <div className={classNames.itemName}>{item.name}</div>
                        </div>
                      </div>
                    );
                  case selectedLeftPanelItem?.includes(sideBarList.folders):
                    return (
                      <div
                        className={classNames.itemCell}
                        onClick={() => {
                          handleSelect(item);
                        }}
                        key={item.id}
                      >
                        <Icon className={classNames.itemImage} iconName={""} />
                        <div className={classNames.itemContent}>
                          <div className={classNames.itemName}>{item.title}</div>
                        </div>
                      </div>
                    );
                  case selectedLeftPanelItem?.includes(sideBarList.stages):
                    return (
                      <div
                        className={classNames.itemCell}
                        onClick={() => {
                          handleSelect(item);
                        }}
                        key={item.id}
                      >
                        <Icon className={classNames.itemImage} iconName={""} />
                        <div className={classNames.itemContent}>
                          <div className={classNames.itemName}>{item.name}</div>
                        </div>
                      </div>
                    );
                  default:
                    return <div>deneme</div>;
                }
              }
            })}
          </InfiniteScroll>
        </div>
      ) : (
        <div className={`${classNames.emptyContainer} text-center`}>
          <Icon className={classNames.emptyIcon} iconName={"FabricOpenFolderHorizontal"} />
          <div className={classNames.emptyTitle}>Data not found.</div>
          <div className={classNames.emptySubTitle}>Please a select service</div>
        </div>
      )}
    </div>
  );
}
