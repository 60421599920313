import { mergeStyleSets } from "office-ui-fabric-react";

export const CloseIcon = { iconName: "Cancel" };

export const DocumentSelect = mergeStyleSets({
  iconWrapper: {
    height: "auto",
    width: "auto",
    backgroundColor: "#F0F5FA",
    padding: "10px",
    borderRadius: 5,
    i: {
      margin: 0,
      fontSize: 18,
      color: "#000",
    },
  },
  changesIcon: {
    height: "auto",
    width: "auto",
    backgroundColor: "transparent",
    padding: "10px",
    i: {
      margin: 0,
      fontSize: 18,
      color: "#000",
    },
  },
});
