import { FontSizes, mergeStyleSets } from "office-ui-fabric-react";
import { themeColors } from "../constants";

export const classNamesWithTheme = (theme) => {
  return mergeStyleSets({
    root: {
      fontSize: "12px",
      background: "none",
      border: 0,
      borderBottom: "solid 3px transparent",
      color: "#8A97A3",
      "&:hover,&:active": {
        background: "none",
        color: "none",
      },
      fontWeight: "bold",
      cursor:"pointer",
    },
    selected: {
      borderBottomColor: theme ? themeColors[theme].secondary : "rgb(119, 139, 245)",
      color: "black",
    },
    refreshBtn: {
      display:"unset !important",  
      cursor:"pointer"  
    }
  });
};

export const classNames = mergeStyleSets({
  filterTabContainer: {
    padding: "0px 16px",
    gap: 8,
    borderBottom: "1px solid rgba(0,0,0,0.20)",
  },
});
