import { mergeStyleSets } from "office-ui-fabric-react";

export const versionInput = mergeStyleSets({
  versionInputLabel: {
    fontWeight: 600,
    fontSize: 12,
    color: "#53646C",
  },
  dot: {
    height: 5,
    minWidth: 5,
    backgroundColor: "black",
    borderRadius: "50%",
  },
});

export const spinButton = {
  label: {
    fontWeight: 600,
    fontSize: 12,
    color: "#53646C",
  },
  spinButtonWrapper: {
    "&::after": {
      border: "1px solid #CDD4D9",
      borderRadius: 5,
    },
  },
};
