import { DefaultButton, Icon, Label, PrimaryButton } from "office-ui-fabric-react";
import React, { useContext, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import NmForm from "../components/NmForm";
import { copyLink } from "../style/copyLink.style";
import { global } from "../style/global.style";
import { getFileShare } from "../requests/api-requests/files.request";
import { LoadingContext } from "../context/LoadingContext";
import { TaskpaneHeader } from "../components/TaskpaneHeader";
import { controlPagePermissions } from "../utils/controls";

const menuProps = {
  items: [
    {
      key: "readOnly",
      text: "Read Only",
    },
    {
      key: "edit",
      text: "Edit",
    },
    {
      key: "other",
      text: "Other",
    },
  ],
};

const sendLinkSchema = [
  {
    type: "date",
    isWidthFull: true,
    icon: "Calendar",
    name: "max_date",
    placeholder: "Set expiration date",
    minDate: new Date(),
  },
  {
    type: "input",
    placeholder: "Set Password",
    isWidthFull: true,
    icon: "Lock",
    name: "password",
  },
  {
    type: "toggle",
    label: "Block Download",
    isWidthFull: true,
    icon: "Blocked",
    name: "download",
  },
  {
    type: "input",
    placeholder: "To: E-Mail or user name",
    isWidthFull: true,
    name: "share_with",
  },
  {
    type: "textarea",
    placeholder: "Message",
    isWidthFull: true,
  },
];

export default function SendLink({ docParams = null, cancelEvent }) {
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [disableSelectButtonStatus, setDisableSelectButtonStatus] = useState(false);
  const [linkCopiedText, setLinkCopiedText] = useState("");
  const [sendStatus, setSendStatus] = useState(false);

  useEffect(async () => {
    await controlPagePermissions();
  }, []);

  let params = localStorage.getItem("selectedDocumentFile")
    ? JSON.parse(localStorage.getItem("selectedDocumentFile"))
    : {};
  let file_path = docParams ? docParams.path :  params.path;
  let service_name = docParams ? docParams.service_name : params.service_name;
  let client_name = docParams ? docParams.client : params.client;

  const validationSchema = Yup.object({
    share_with: Yup.string().required("Required Field"),
  });

  const sendLinkForm = useFormik({
    initialValues: {
      min_date: new Date(),
      max_date: "",
      share_with: "",
      password: "",
      download: false,
    },
    validationSchema,
    onSubmit: async (data) => {
      let payload = {
        file_path,
        service_name,
        client_name,
        ...data,
      };
      showLoading();
      getFileShare(payload).then((data) => {
        hideLoading();
        //setLinkCopiedText("The link was sent successfully.");
        setSendStatus(true);
      });
    },
  });

  return (
    <div>
      {docParams === null && <TaskpaneHeader title={"Send Link"} showBackButton={false} headerColor={"#8a97a3"} />}
      {sendStatus ? (
        <div className="text-center mt-5">
          <p>The link was sent successfully</p>
          <DefaultButton
            text={`Back to the ${docParams ? "Versions" : "Send Link"}`}
            className={global.primaryButton}
            onClick={() => (docParams !== null ? cancelEvent() : setSendStatus(false))}
          />
        </div>
      ) : (
        <div className={`px-4 relative ${global.container}`}>
          <div className="row">
            <div className="col">
              <div className={`d-flex flex-column justify-content-between pt-4 ${global.pb75}`}>
                <div className="main d-flex flex-column gap-4">
                  <div className="d-flex align-items-center">
                    <Icon iconName={"Share"} className={copyLink.shareIcon} />
                    <span className={`${copyLink.linkBoldText} ps-2`}>Anyone with the link can view-only</span>
                  </div>
                  <div className={`${copyLink.formContainer} d-flex flex-column`}>
                    <Label>Settings</Label>
                    <NmForm formSchema={sendLinkSchema} formik={sendLinkForm} classNames="p-0" />
                  </div>
                  <div style={{ color: "red", textAlign: "center" }}>{linkCopiedText}</div>
                </div>
                <div className={`d-flex align-items-center justify-content-end gap-3 px-3 ${global.footerWithFixed}`}>
                  {docParams !== null && (
                    <DefaultButton className={global.secondaryButton} onClick={() => cancelEvent()} text="Cancel" />
                  )}
                  <PrimaryButton
                    className={global.primaryButton}
                    onClick={sendLinkForm.handleSubmit}
                    text="Send"
                    disabled={disableSelectButtonStatus}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
