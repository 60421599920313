import React, { createContext, useCallback, useMemo, useState, useRef } from "react";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';

export const MessageContext = createContext(null);

export function MessageContextProvider({ children }) {
  const openMessage = useCallback((type, message) => {
    if (type === "error") {
      alertify.error(message); 
      return;
    }
    if (type === "success") {
      alertify.success(message); 
      return;
    }   
  }, []);

  const context = useMemo(() => ({ openMessage }), [openMessage]);

  return (
    <>
      <MessageContext.Provider value={context}>{children}</MessageContext.Provider>
      {/* <ToastContainer position="bottom-right" limit={5} pauseOnHover={false} hideProgressBar /> */}
    </>
  );
}