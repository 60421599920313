import { FontWeights, IconButton, Label } from "office-ui-fabric-react";
import React from "react";
import { DocumentSelect } from "../style/nmDocumentSelect.style";
import NmInput from "./NmInput";
import { global } from "../style/global.style";

export default function NmDocumentSelect({ value, owner, label, onChangeForOwner, name, onClickButton, disabled=false }) {
  return (
    <div className="mt-2">
      <div className="d-flex justify-content-start">
        <div style={{ width: "90%" }}>
          <Label>{label}</Label>
          <NmInput value={value} />
        </div>
        <div className="ps-2" style={{ paddingTop: "28px" }}>
          <IconButton
            iconProps={{ iconName: "Folder" }}
            className={DocumentSelect.iconWrapper}
            disabled={disabled}
            onClick={onClickButton}
          />
        </div>
      </div>
      <div className="mt-2">
        <Label className={global.documentSelectLabel} style={{fontWeight: "normal"}}>Label Changes with:</Label>
        <NmInput value={owner} name={name} onChange={onChangeForOwner} classNames="w-100" />
      </div>
    </div>
  );
}
