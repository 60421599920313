import React from "react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import { global } from "../style/global.style";

export default function Loader({ isLoaderShow }) {
  return (
    isLoaderShow && (
      <div className={global.spinnerOverlay}>
        <Spinner size={SpinnerSize.large} style={{ zIndex: 9999 }} />
      </div>
    )
  );
}
