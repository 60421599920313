import { mergeStyleSets } from "office-ui-fabric-react";

export const saveAsNewVersion = mergeStyleSets({
  icon: {
    fontSize: 26,
    color: "blue",
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  blank: {
    width: 44,
  },
  link: {
    color: "#0082FA",
    textDecoration: "underline",
    fontSize: 13,
    fontWeight: 600,
    cursor: "pointer",
  },
  commentIcon: {
    fontSize: 20,
    marginTop: "2rem",
    color: "blue",
  },
  footer: {
    backgroundColor: "#D3D8DD",
    padding: "16px 0",
    position: "fixed",
    bottom: 0,
    right: 0,
    width:"100%"
  },
  container: {
    height: "calc(100vh - 16px)",
  },
  formWrapper:{
    padding: "0 16px 75px"
  }
});
