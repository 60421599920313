import { postCoAuthor } from "../requests/api-requests/coAuthor.request";
import {
  postSaveAndSync,
  postSaveAsNewDocument,
  postSaveAsNewDocumentPdf,
  postSaveAsNewVersion,
  postSaveAsNewVersionPdf,
} from "../requests/api-requests/files.request";

export async function saveChunks(dataBody, chunks, type, typeForSaveAsPdf, extraHeaders) {
  return new Promise(async (resolve, reject) => {
    for (let index = 0; index < chunks.length; index++) {
      const element = chunks[index];
      const _chunk = [];
      _chunk.push(element);
      dataBody.index = index;
      dataBody.chunks = _chunk;
  
      if (type === "saveAsNewDocument") {
        await postSaveAsNewDocument(dataBody, extraHeaders);
      }
      if (type === "saveAndSync") {
        await postSaveAndSync(dataBody, extraHeaders);
      }
      if (type === "saveAsNewVersion") {
        await postSaveAsNewVersion(dataBody, extraHeaders);
      }
      if (type === "coAuthor") {
        await postCoAuthor(dataBody).then((response) => {
          if (response) {
            window.open("ms-word:ofe|u|" + response.data.url);
          }
        });
      }
      if (type === "saveAsPdf") {
        if (typeForSaveAsPdf === "saveAsNewVersion") {
          await postSaveAsNewVersionPdf(dataBody);
        } else {
          await postSaveAsNewDocumentPdf(dataBody);
        }
      }
    }
    resolve(true);
  });

}
