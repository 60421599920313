import { Checkbox } from "office-ui-fabric-react";
import React from "react";
import { global } from "../style/global.style";

export default function NmCheckBoxGroup({ schema, form, handleChange, colCount }) {
  const _handleChange = (e, value, name) => {
    handleChange(name, value);
  };
  return (
    <div className={`gap-3 mt-2`} style={{ gridTemplateColumns: colCount && colCount }}>
      {schema.map((item) => (
        <div className="mb-2">
          <Checkbox
            label={item.label}
            name={item.name}
            checked={form[item.name]}
            disabled={item.disable}
            onChange={(e, value) => _handleChange(e, value, item.name)}
          />
        </div>
      ))}
    </div>
  );
}
