import React, { createContext, useEffect, useState } from "react";

export const GlobalContext = createContext();

export function GlobalContextProvider({ children }) {
  const [user, setUser] = useState({ name: "testUser", role: "superUser" });
  const [theme, setTheme] = useState("");
  const [listItems, setListItems] = useState([
    {
      icon: "Ribbon",
      primaryText: "Achieve more with Office integration",
    },
    {
      icon: "Unlock",
      primaryText: "Unlock features and functionality",
    },
    {
      icon: "Design",
      primaryText: "Create and visualize like a pro",
    },
  ]);
  let params = new URL(document.location).searchParams;
  let currentPage = params.get("page");
  let currentPopup = params.get("popup");
  const token = localStorage.getItem("token");

  const [page, setPage] = useState(currentPage);
  const [popup, setPopup] = useState(currentPopup);
  const [selectedDocumentFromContext, setSelectedDocumentFromContext] = useState({});
  const [isOriginal, setIsOriginal] = useState(false);
  const [compareFormFromContext, setCompareFormFromContext] = useState({});
  const [combineFormFromContext, setCombineFormFromContext] = useState({});
  const [isFirst, setIsFirst] = useState(true);


  if (!token && currentPage !== "login") { 
    window.location.search = `?page=login&oldPage=${currentPopup ? currentPopup : currentPage}`;
  }

  useEffect(() => {
    setPage(currentPage);
    setPopup(currentPopup);
  }, [currentPage, currentPopup]);

  const data = {
    user,
    setUser,
    listItems,
    page,
    theme,
    setTheme,
    popup,
    setPage,
    selectedDocumentFromContext,
    setSelectedDocumentFromContext,
    isOriginal,
    setIsOriginal,
    compareFormFromContext,
    setCompareFormFromContext,
    isFirst,
    setIsFirst,
    combineFormFromContext,
    setCombineFormFromContext,
  };
  return <GlobalContext.Provider value={data}>{children}</GlobalContext.Provider>;
}
