import { DefaultButton, PrimaryButton, Stack, StackItem } from "office-ui-fabric-react";
import React, { useContext, useEffect, useState } from "react";
import { useDialogState } from "../hooks/use-dialog-state";
import { advancedFilter } from "../style/nmAdvancedFilter.style";
import NmDialog from "./NmDialog";
import NmForm from "./NmForm";
import NmSavedFilter from "./NmSavedFilter";
import { global } from "../style/global.style";
import { openWithMWA } from "../style/open.style";
import { getClients } from "../requests/api-requests/clients.request";
import { getMatters } from "../requests/api-requests/matters.request";
import { getCases } from "../requests/api-requests/cases.request";
import { getMuameles } from "../requests/api-requests/muameles.request";
import { getContracts } from "../requests/api-requests/contracts.request";
import { LoadingContext } from "../context/LoadingContext";

const savedFilterList = [
  {
    title: "A meselesi",
    formItemList: [
      "Client: SASA",
      "Matter: A matter",
      "Contract ID: SASA0007",
      "Activities: Version",
      "Date: 11.04.2022",
    ],
  },
  {
    title: "A meselesi",
    formItemList: [
      "Client: SASA",
      "Matter: A matter",
      "Contract ID: SASA0007",
      "Activities: Version",
      "Date: 11.04.2022",
    ],
  },
  {
    title: "A meselesi",
    formItemList: [
      "Client: SASA",
      "Matter: A matter",
      "Contract ID: SASA0007",
      "Activities: Version",
      "Date: 11.04.2022",
    ],
  },
  {
    title: "A meselesi",
    formItemList: [
      "Client: SASA",
      "Matter: A matter",
      "Contract ID: SASA0007",
      "Activities: Version",
      "Date: 11.04.2022",
    ],
  },
];
export default function NmAdvancedFilter({ opened, formik, dialogToggle, theme, MWA, handleSubmit }) {
  const [savedFiltersOpened, savedFiltersToggle] = useDialogState();
  const { showLoading, hideLoading } = useContext(LoadingContext);
  
  const [clientList, setClientList] = useState([]);
  const [matterList, setMatterList] = useState([]);
  const [fccList, setFccList] = useState([]);

  const handleFilter = () => {
    handleSubmit();
    dialogToggle();
  };

  const handleCloseDialog = () => {
    formik.handleReset();
    dialogToggle();
  };

  useEffect(async () => {
    if (!opened) return;
    showLoading();
    await getClients().then((data) => {
      let output = [];
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        output.push({ key: element.name, text: element.name });
      }
      setClientList(output);
    });
    await getMatters({ service_name: MWA.text, page: 1, limit: 9999 }).then((data) => {
      let output = [];
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        output.push({ ...element, key: element.name, text: element.name });
      }
      setMatterList(output);
    });

    switch (MWA.text) {
      case "Muhakemat":
        await getCases({ page: 1, limit: 9999 }).then((data) => {
          let output = [];
          for (let index = 0; index < data.data.length; index++) {
            const element = data.data[index];
            output.push({ ...element, key: element.title, text: element.title });
          }
          setFccList(output);
        });
        break;
      case "Mahfuz":
        break;
      case "Muamelat":
        await getMuameles({ page: 1, limit: 9999 }).then((data) => {
          let output = [];
          for (let index = 0; index < data.data.length; index++) {
            const element = data.data[index];
            output.push({ ...element, key: element.name, text: element.name });
          }
          setFccList(output);
        });
        break;
      case "Mukavele":
        await getContracts({ page: 1, limit: 9999 }).then((data) => {
          let output = [];
          for (let index = 0; index < data.data.length; index++) {
            const element = data.data[index];
            output.push({ ...element, key: element.title, text: element.title });
          }
          setFccList(output);
        });
        break;
      default:
        break;
    }
    hideLoading();
  }, [MWA, opened]);

  const handleChangeFcc = (e) => {
    const list = [
      { name: "client_name", value: "client" },
      { name: "matter_name", value: "matter" },
      { name: "fcc_name", value: "key" },
    ];
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      formik.setFieldValue(element.name, e[element.value]);
    }
  };

  const handleChangeMatter = (e) => {
    const list = [
      { name: "client_name", value: "client" },
      { name: "matter_name", value: "text" },
    ];
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      formik.setFieldValue(element.name, e[element.value]);
    }
  };

  const advancedFilterSchema = [
    {
      type: "dropdown",
      label: "Clients",
      name: "client_name",
      isWithFull: true,
      placeholder: "Choose",
      data: clientList,
    },
    {
      type: "dropdown",
      label: "Matter",
      name: "matter_name",
      isWidthFull: true,
      placeholder: "Choose",
      data: matterList,
      handleChange: handleChangeMatter,
    },
    {
      type: "dropdown",
      label: "Folder/Contract/Case Name",
      name: "fcc_name",
      isWidthFull: true,
      placeholder: "Choose",
      data: fccList,
      handleChange: handleChangeFcc,
    },
  ];

  return (
    <>
      <NmDialog opened={opened} dialogToggle={dialogToggle}>
        <Stack
          className={advancedFilter.advancedFilterWrapper}
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <StackItem className={advancedFilter.advancedFilterHeaderTitle}>Filters</StackItem>
          {/* <StackItem className={advancedFilter.advancedFilterSaveButton} onClick={() => savedFiltersToggle()}>
            Saved Filters
          </StackItem> */}
        </Stack>
        <div className={advancedFilter.formWrapper}>
          <NmForm formSchema={advancedFilterSchema} formik={formik} />
        </div>
        <Stack className={advancedFilter.advancedFilterWrapper} horizontal verticalAlign="center" horizontalAlign="end">
          {/* <StackItem
            className={advancedFilter.advancedFilterSaveButton}
            onClick={() => console.log("save filters work")}
          >
            Save Filters
          </StackItem> */}
          <Stack horizontal gap={16}>
            <DefaultButton className={global.secondaryButton} onClick={handleCloseDialog}>
              Cancel
            </DefaultButton>
            <PrimaryButton className={openWithMWA(theme, MWA).buttonStyles} onClick={() => handleFilter()}>
              Filter
            </PrimaryButton>
          </Stack>
        </Stack>
      </NmDialog>
      <NmSavedFilter data={savedFilterList} opened={savedFiltersOpened} dialogToggle={savedFiltersToggle} />
    </>
  );
}
