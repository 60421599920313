import { mergeStyleSets } from "office-ui-fabric-react";

export const CloseIcon = { iconName: "Cancel" };

export const advancedFilter = mergeStyleSets({
  advancedFilterWrapper: {
    padding: 12,
  },
  advancedFilterHeaderTitle: {
    fontWeight: 600,
    fontSize: 16,
    color: "#11141C",
  },
  advancedFilterSaveButton: {
    fontWeight: 600,
    fontSize: 12,
    color: "#0082FA",
    cursor: "pointer",
  },
  savedFilterHeader: {
    padding: 12,
    fontSize: 12,
    fontWeight: "bold",
    color: "#007FF5",
    backgroundColor: "#F4F8FA",
  },
  filterItemWrapper: {
    borderTop: "solid 1px rgb(0, 0, 0, 0.1);",
    padding: "12px 10px",
  },
  filterFormItemWrapper: {
    flexFlow: "row wrap",
    gap: 5,
  },
  formItem: {
    backgroundColor: "#F4F8FA",
    padding: 3,
  },
  formItemInner: {
    fontSize: 11,
    fontWeight: 600,
    color: "#06090F",
  },
  formItemIcon: {
    height: "auto",
    width: "auto",
    i: {
      margin: 0,
      fontSize: 10,
      color: "#000",
    },
  },
  savedFilterItemHeaderTitle: {
    fontWeight: "bold",
    fontSize: 11,
    color: "#06090F",
  },
  savedFilterItemHeaderButton: {
    fontWeight: 600,
    fontSize: 10,
    cursor: "pointer",
    color: "#007FF5",
  },
  savedFilterContent: {
    height: "250px",
    overflow: "scroll",
  },
  formWrapper: {
    backgroundColor: "#F4F8FA",
    padding: "8px 0",
  },
});
