import { ENDPOINTS } from "../../constants";
import { post } from "../instance/axios.instance";

export const userLogin = async (param) => {
  return new Promise((resolve, reject) => {
    post(ENDPOINTS.LOGIN, param)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const userLogout = async (param) => {
  return new Promise((resolve, reject) => {
    post(ENDPOINTS.LOGOUT, param)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
