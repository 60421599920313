import React, { useContext, useState } from "react";
import { pivotStyle, propertiesDetails } from "../style/pivot.style";
import { GlobalContext } from "../context/GlobalContext";
import NmForm from "../components/NmForm";
import { versions } from "../style/versions.style";
import NmVersionCard from "../components/NmVersionCard";
import { getFileVersions } from "../requests/api-requests/files.request";
import { useFetch } from "../hooks/use-fetch";
import NmCurrentVersion from "../components/NmCurrentVersion";

const dummySchema = [
  {
    type: "version",
    label: "Version",
    isWidthFull: true,
    name: "version",
  },
  {
    type: "targetProperties",
    label: "Target Properties",
    isWidthFull: true,
    data: {
      title: "Örnek Title.docx",
      id: 545454,
      fileSize: 23,
      version: "v5",
      modifiedBy: "Mkanorwala",
      dataModified: new Date(),
      createBy: "Mfirat",
      createdDate: new Date(),
    },
  },
  {
    type: "input",
    label: "Doc Name",
    readOnly: true,
    isWidthFull: true,
    name: "docName",
  },
  {
    label: "Doc Date",
    type: "date",
    value: new Date(),
    name: "docDate",
    isWidthFull: true,
  },
  {
    type: "dropdown",
    label: "Doc Type",
    isWidthFull: true,
    placeholder: "Select",
    data: [
      { key: 1, text: "Doc Type 1" },
      { key: 2, text: "Doc Type 2" },
    ],
  },
  {
    type: "textarea",
    label: "Doc Subject",
    readOnly: true,
    value: "deneme textarea",
    isWidthFull: true,
  },
  {
    type: "dropdown",
    label: "From",
    isWidthFull: true,
    value: 1,
    placeholder: "Select",
    data: [
      { key: 1, text: "From 1" },
      { key: 2, text: "From 2" },
    ],
  },
  {
    type: "dropdown",
    label: "To",
    isWidthFull: true,
    value: 2,
    placeholder: "Select",
    data: [
      { key: 1, text: "To 1" },
      { key: 2, text: "To 2" },
    ],
  },
  {
    type: "input",
    label: "Client",
    readOnly: true,
    value: "SASA",
    isWidthFull: true,
  },
  {
    type: "input",
    label: "Matter",
    readOnly: true,
    value: "SASA Polyester",
    isWidthFull: true,
  },
  {
    type: "input",
    label: "Location",
    readOnly: true,
    value: "Mukavele > Folders > Versiyonlar",
    isWidthFull: true,
  },
];

export default function DocumentDetail({ isOpenDocument, form, schema, selectedListItem, selectedVersiyon }) {
  const { theme } = useContext(GlobalContext);

  const [versionList] = useFetch(
    () => (selectedListItem.doi ? getFileVersions({ doi: selectedListItem.doi }) : () => {}),
    [],
    { reloadDeps: [selectedListItem], deps: [selectedListItem] }
  );

  const [selectedVer, setSelectedVer] = useState("");

  const selectVersiyon = (versiyon) => {
    selectedVersiyon(versiyon);
    setSelectedVer(versiyon);
    return versiyon;
  };

  const [menuName, setMenuName] = useState("Properties");

  return (
    <div style={{paddingBottom: isOpenDocument ? "" : "100px"}}>
      <div className={`${propertiesDetails.menuContainer} d-flex justify-content-start`}>
        <div
          onClick={() => setMenuName("Properties")}
          className={`${propertiesDetails.menuItem} ${
            menuName === "Properties" ? propertiesDetails.menuItemIsSelected : ""
          } ${isOpenDocument ? "w-50" : "w-100"}  text-center py-2`}
        >
          Properties
        </div>
        {isOpenDocument && (
        <div
          onClick={() => setMenuName("Versions")}
          className={`${propertiesDetails.menuItem} ${
            menuName === "Versions" ? propertiesDetails.menuItemIsSelected : ""
          } w-50 text-center py-2`}
        >
          Versions
        </div>)}
      </div>
      <div>
        {menuName == "Properties" && (
          <div className="py-3">
            <NmForm formSchema={schema || dummySchema} formik={form} />
          </div>
        )}
        {menuName == "Versions" && (
          <div>
            {isOpenDocument && (
            <div>
              {selectedListItem && <NmCurrentVersion selectedListItem={selectedListItem} />}
              {versionList &&
                versionList.map((item, index) => (
                  <NmVersionCard
                    item={item}
                    key={index}
                    selectVersiyon={selectVersiyon}
                    selectedVersiyon={selectedVer}
                  />
                ))}
            </div>)}
          </div>
        )}
      </div>
    </div>

  );
}
