import React, { useEffect, useState } from "react";
import { dropdownStyles } from "../style/nmForm.style";
import AsyncSelect from "react-select/async";

export default function NmSearchableDropdown({
  styles = dropdownStyles,
  data,
  dropdownWidth = 150,
  onChange,
  selectedKey,
  label,
  name,
  value,
  isForm,
  handleChange,
  errorMessage,
  disabled,
  updateSearch,
  selectedProp,
  handleGetData,
}) {
  const [defaulValue, setDefaultValue] = useState({ key: "", label: "" });

  const _handleChange = (e) => {
    if (isForm) {
      if (name === "fcc_name" || name === "matter_name") {
        handleChange(newValue);
      } else {
        onChange(name, e[selectedProp]);
      }
    } else {
      handleChange(e, newValue);
    }
  };
  const loadOptions = async (inputValue) => {
    return handleGetData({ search: inputValue }).then((response) => {
      var options = response.map((item) => ({
        key: item.id,
        label: item.name.trim(),
      }));
      return options;
    });
  };
  useEffect(async () => {
    if (value && value.length > 0) {
      const result = await loadOptions(value);    
      if (result.length > 0) {
        const findValue = result.find(x => x.label.toLowerCase() == value.toLowerCase());
        if (findValue) {
          setDefaultValue(findValue);
        } else {
          setDefaultValue({
            key: value,
            label: value,
          });
        }
      } else {
        setDefaultValue({
          key: value,
          label: value,
        });
      }
    }
  }, [value]);

  const isValidStyle = (errorMsg) => {
    if (errorMsg && errorMsg.length > 0) {
      return { borderColor: "rgb(164, 38, 44)" };
    }
    return {};
  };
  return (
    <div>     
      <div
        style={{
          color: "rgb(83, 100, 108)",
          fontWeight: 600,
          fontSize: "12px",
          marginBottom: "5px",
          paddingTop: "5px",
        }}
      >
        {label}
      </div>
      <AsyncSelect
        onChange={_handleChange}
        cacheOptions
        loadOptions={loadOptions}
        value={defaulValue}
        defaultInputValue=""
        isDisabled={disabled}
        styles={{
          control: (base) => ({
            ...base,
            isValidStyle,
            height: "32px",
            "min-height": "32px",
          }),
          container: (base) => ({
            ...base,
            height: "30px",
            "min-height": "30px",
          }),
          valueContainer: (base) => ({
            ...base,
            height: "30px",
            "min-height": "30px",
          }),
          indicatorsContainer: (base) => ({
            ...base,
            height: "30px",
            "min-height": "30px",
          }),
         
        }}
      />
      <span style={{ color: "rgb(164, 38, 44)" }}>{errorMessage}</span>
    </div>
  );
}
