import * as React from "react";
import { DatePicker, DayOfWeek } from "office-ui-fabric-react";
import { datePicker } from "../style/nmForm.style";

const DayPickerStrings = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],

  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

  goToToday: 'Go to today',
  prevMonthAriaLabel: 'Go to previous month',
  nextMonthAriaLabel: 'Go to next month',
  prevYearAriaLabel: 'Go to previous year',
  nextYearAriaLabel: 'Go to next year',
  closeButtonAriaLabel: 'Close date picker',
  monthPickerHeaderAriaLabel: '{0}, select to change the year',
  yearPickerHeaderAriaLabel: '{0}, select to change the month',
};

const onFormatDate = (date) => {
  return !date ? "" : date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
};

const NmDate = ({ label, value, placeholder, name, onChange, errorMessage, disabled, ...data }) => {
  const datePickerStyles = {
    textField: {
      label: {
        color: label == "emptyLabel" ? "transparent" : "#53646C",
        fontWeight: 600,
        fontSize: 12,
      },
    },
  };

  const handleChange = (e) => {
    onChange(name, e);
  };

  return (
    <DatePicker
      firstDayOfWeek={DayOfWeek.Monday}
      placeholder={placeholder}
      strings={DayPickerStrings}
      firstWeekOfYear={1}
      showMonthPickerAsOverlay={true}
      showGoToToday={false}
      label={label}
      styles={datePickerStyles}
      value={value}
      formatDate={onFormatDate}
      onSelectDate={handleChange}
      errorMessage={errorMessage}
      name={name}
      className={datePicker}
      minDate={data.minDate}
      disabled={disabled}
    />
  );
};

export default NmDate;
