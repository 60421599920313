import { FontSizes, mergeStyleSets } from "office-ui-fabric-react";
import { themeColors } from "../constants";

export const taskpaneHeaderWithTheme = (theme, backgroundColor, textColor) => {
  return mergeStyleSets({
    root: {},
    taskPaneHeader: {
      backgroundColor: theme !== "" ? themeColors[theme]?.primary : "#33363c",
      color: "white",
      padding: "10px 15px 10px 10px",
      fontSize: "16px",
    },
    taskPaneHeaderCustomColor: {
      backgroundColor: backgroundColor,
      color: "white",
      padding: "10px 15px 10px 10px",
      fontSize: "16px",
    },
    taskPaneHeaderBackIcon: {
      cursor: "pointer",
    },
    titleStyle: {
      fontWeight: 600,
      color: textColor ? textColor : "white"
    }
  });
};